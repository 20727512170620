/* Countdown */
.countdown-wrapper {
  position: relative;
}

.countdown__background-image-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.countdown__background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.countdown__mobile-image-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.countdown__mobile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.countdown-layout--banner {
  & .countdown__content {
    padding: 2rem;

    @media (--medium-up) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-around;
      gap: 1rem;
      padding: 1.6rem 2rem;
    }
  }

  &.countdown-blocks--2.page-width {
    & .countdown__content {
      justify-content: space-around;
    }
  }

  &.countdown-blocks--2 {
    & .countdown__content {
      justify-content: center;
    }
  }

  & .countdown__block {
    @media (--medium-up) {
      width: 33%;
    }
  }
}

.countdown__content {
  width: 100%;
  height: auto;
  max-height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 2rem;
  text-align: center;

  @nest .page-width & {
    position: relative;
  }

  @nest .countdown-layout--hero & {
    @media (--medium-up) {
      height: 100vh;
    }
  }
}

.countdown__block {
  width: 100%;
  margin: 1rem auto;
  z-index: 3;
}

.countdown__block--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .countdown__block--button {
    width: 100%;
    margin: 0; /* Nested button will get spacing from text above it */
  }
}

.countdown__block--timer,
.countdown__block--content {
  @nest .countdown-blocks--2.countdown-layout--banner & {
    @media (--medium-up) {
      width: 50%
    }
  }
}

.countdown__block--button.button-block-active {
  @nest .countdown-blocks--2.countdown-layout--banner & {
    @media (--medium-up) {
      width: 50%
    }
  }
}

.countdown__text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @nest .countdown-layout--hero & {
    @media (--medium-up) {
      width: 50%;
    }
  }
}

.countdown__text-wrapper--content-alignment-left {
  text-align: left;
}

.countdown__text-wrapper--content-alignment-right {
  text-align: right;
}

.countdown__display {
  display: flex;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.countdown__display--loaded {
  opacity: 1;
  visibility: visible;
  transition: opacity ease-in 0.3s;
}

.countdown__display--visible + .countdown__timer-message--visible {
  margin-top: 1.5rem;
}

.countdown__display--hidden {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  transition: opacity ease-out 3s;
}

.countdown__display-block {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  border-right: 1px solid;
  padding: 0 1rem;

  & h2 {
    margin-bottom: 16px;

    @media (--medium-up) {
      margin-bottom: 4px;
    }
  }

  & span {
    font-size: 0.6rem;
    letter-spacing: 1.7px;

    @media (--medium-up) {
      font-size: 0.75rem;
    }
  }

  &:last-child {
    border-right: none;
  }

  @nest [dir="rtl"] & {
    &:last-child {
      border-right: 1px solid;
    }

    &:first-child {
      border-right: none;
    }
  }
}

.countdown__timer-message {
  opacity: 0;
  visibility: hidden;
  margin: 0;
  height: 0;
}

.countdown__timer-message--visible {
  opacity: 1;
  visibility: visible;
  transition: opacity ease-in 1s;
  height: auto;
}

.countdown__block--hidden {
  opacity: 0;
  visibility: hidden;
  margin: 0;
  transition: opacity ease-out 1s;
  width: 0 !important;
}

.countdown__overlay {
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: var(--countdown-overlay-rgba);
  }
}
