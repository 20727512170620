.grid-product {
  margin-bottom: calc($gutter / 2);

  @media (--medium-up) {
    margin-bottom: $gutter;
  }

  @media (--small) {
    @nest .grid-overflow-wrapper & {
      margin-bottom: 0;
    }
  }
}

.grid-product__content {
  position: relative;
  text-align: left;

  @nest html[dir="rtl"] & {
    text-align: right;
  }
}

.grid__item-image-wrapper {
  position: relative;
}

.grid-product__title--heading {
  @mixin headingFontStack;
  font-size: calc(var(--typeBaseSize) + 2px);
  line-height: 1;
}

[data-type_product_capitalize="true"] {
  & .grid-product__title {
    text-transform: uppercase;
    font-size: calc(var(--typeBaseSize) - 2px);
    letter-spacing: 0.2em;

    @media (--small) {
      font-size: calc(var(--typeBaseSize) - 3px);
    }
  }
}

/* Prevent long words from breaking out of available space */
.grid-product__title {
  word-break: break-word;
  hyphens: auto;
}

.grid-product__link {
  display: block;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.grid-product__link--inline {
  display: flex;

  & .grid-product__image-mask {
    flex: 0 0 auto;
    margin-right: 10px;
  }

  & .grid__image-ratio {
    width: 80px;
  }

  & .grid-product__meta {
    text-align: left;
  }
}

.grid-product__image-mask {
  position: relative;
  overflow: hidden;
}

.grid-product__image {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-product__secondary-image {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  opacity: 0;
  background-color: $colorBody;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @nest .grid-product__content:hover & {
    animation: fade-in 0.2s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  }

  @media (--small) {
    @nest .supports-touch & {
      display: none;
    }
  }
}

.grid-product__color-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.4s ease;
  background-color: $colorBody;

  &.is-active {
    animation: fade-in 0.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  }
}

.grid-product__meta {
  @mixin baseTextCenter;
  position: relative;
  padding: 10px 0 6px 0;
  line-height: var(--typeBaselineHeightMinus01);

  @media (--small) {
    @nest .small--grid--flush & {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.grid-product__vendor {
  @mixin accentFontStack;
  @mixin accentFontSmallSize;
  margin-top: 5px;
  opacity: 0.65;
}

.grid-product__price {
  @mixin baseSmallFontStack;
  color: $colorPrice;
  margin-top: 5px;
}

.grid-product__price--original {
  text-decoration: line-through;
  margin-right: 5px;
}

.grid-product__price--savings {
  color: $colorTextSavings;
  margin-left: 5px;
  white-space: nowrap;
}

.grid-product__tag {
  font-size: calc(var(--typeBaseSize) * 0.65);
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
  padding: 6px 5px 6px 7px;
  background-color: $colorBtnPrimary;
  color: $colorBtnPrimaryText;
  z-index: 2;
  transition: opacity 0.4s ease;

  &.grid-product__tag--sold-out {
    background-color: $colorBody;
    color: $colorTextBody;
  }

  @media (--medium-up) {
    font-size: calc(var(--typeBaseSize) * 0.85);
    padding: 7px 7px 7px 9px;
  }
}

.grid-product__tag--sale {
  background-color: $colorSaleTag;
  color: $colorSaleTagText;
}

.quick-product__btn {
  @mixin baseSmallFontStack;
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  z-index: 2;
  background-color: $colorBtnPrimary;
  color: $colorBtnPrimaryText;
  overflow: hidden;
  padding: 8px 5px;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  transform: translateY(5px);
  transition: opacity 0.25s ease, transform 0.25s ease-out, background 0.4s ease;
  width: calc(100% - 20px);

  @nest .no-js & {
    display: none;
  }

  @nest .grid-product__content:hover & {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.25s ease, transform 0.25s ease-out;
  }

  @nest .supports-touch & {
    display: none;
  }

  @nest .grid-product:focus-within & {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.25s ease, transform 0.25s ease-out;
  }
}

.quick-product__btn--not-ready {
  pointer-events: none;
}

/*Mobile horizontal overflow grid*/
@media (--small) {
  .grid-overflow-wrapper {
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    & .grid {
      white-space: nowrap;
      display: flex;
    }

    & .grid__item {
      width: 39vw;
      flex: 0 0 39vw;
      display: inline-block;
      float: none;
      white-space: normal;

      &:first-child {
        margin-left: $page-width-gutter-small;
      }

      &:last-child:after {
        content: '';
        display: inline-block;
        width: 100%;
        margin-right: $page-width-gutter-small;
      }
    }

    & .grid__item--view-all {
      align-self: center;
    }

    & .grid__item,
    & .grid-product__price {
      font-size: 0.75rem;
    }
  }

  [data-aos="overflow__animation"] {
    transform: translateX(100vw);
    transition: all 0.8s cubic-bezier(.25, .46, .45, .94);

    &.aos-animate {
      transform: translateX(0);
    }

    @nest [data-disable-animations="true"] & {
      transform: none;
      transition: none;
    }
  }
}

/*See all (mobile overflow)*/
.grid-product__see-all {
  display: inline-block;
  padding: calc($gutter / 2);
  text-align: center;
  border: 1px solid;
  border-color: $colorBorder;
  margin-top: -60px; /*approx of what title+price is*/
}

.grid-product__colors {
  @mixin baseTextCenter;
  margin-top: 3px;
  display: flex;
  flex-wrap: wrap;
  line-height: $colorSwatchCollectionSize;

  @media (--medium-up) {
    line-height: $colorSwatchCollectionSizeLarge;
  }

  @nest [data-center-text="true"] & {
    align-items: center;
    justify-content: center;
  }

  & + .grid-product__colors {
    margin-top: 4px;
  }
}
