.currency-flag {
  transform: scale(0.55);
  margin: -0.55rem;
}

/*
  Center popup on mobile so long country/currency names
  do not cause horizontal scroll
 */
@media (--small) {
  [data-disclosure-currency] .disclosure-list {
    left: 50%;
    transform: translateX(-50%);
    max-width: 95vw;
  }
}
