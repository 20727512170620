.collection-content {
  padding-top: calc($gutter * 0.75); /*same as page-content*/

  @media (--medium-up) {
    padding-top: calc($gutter * 1.5);
  }
}

.collection-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (--small) {
    flex-wrap: wrap;
    justify-content: space-between;
    position: sticky;
    top: $grid-gutter-small;
    z-index: 5;

    @nest .sticky-header & {
      top: 86px; /*updated with JS based on sticky header height*/
    }
  }

  & select,
  & .btn {
    height: 44px; /*magic number, just in filter menu*/
    font-size: 16px;
  }

  & select {
    display: block;
    width: 100%;
  }

  & .btn {
    padding: 0 20px; /*because it has a fixed height*/
    border: 1px solid;
    border-color: $colorBorder;

    @media (--small) {
      width: 100%;
      text-align: left;
    }

    & .icon {
      position: relative;
      top: -2px;
      margin-right: 10px;
    }
  }
}

.collection-filter__item {
  flex: 1 1 auto;
  margin-bottom: calc($gutter / 2);
}

.collection-filter .btn:not(.btn--tertiary-active),
.collection-filter__sort-container {
  background: $colorBody;
}

.collection-filter__item--drawer {
  flex: 0 1 48%;
}

.collection-filter__item--count {
  flex: 1 1 170%;
  text-align: center;
}

.collection-filter__item--sort {
  flex: 0 1 48%;
}

@media (--small) {
  .collection-filter__item--count {
    order: 3;
    flex: 1 1 100%;
  }
}

.rte.collection__description {
  margin-bottom: calc($gutter * 1.5);
}

$collectionHeroLarge: 420px;
$collectionHeroSmall: 250px;
.collection-hero {
  position: relative;
  width: 100%;
  height: $collectionHeroSmall;
  overflow: hidden;
  background: $colorLargeImageBg;
  box-sizing: initial;

  @media (--medium-up) {
    height: $collectionHeroLarge;
  }
}

.collection-hero__content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: calc($gutter / 2) 0;
  z-index: 1;

  @media (--medium-up) {
    padding: $gutter 0;
  }

  @nest [data-type_headers_align_text="true"] & {
    align-items: center;
  }

  /*Account for overlayed header/toolbar*/
  @nest .overlaid-header & {
    padding-top: 70px;

    @media (--medium-up) {
      padding-top: 100px;
    }
  }

  & .page-width {
    width: 100%;
  }

  &:before {
    @mixin overlay;
    @mixin heroScrim;
    display: block;
  }

  & .section-header {
    opacity: 0;
    animation: fade-in 0.5s forwards 1s;

    @nest [data-disable-animations="true"] & {
      opacity: 1;
      animation: none;
    }
  }
}

/*Apply animations*/
.collection-hero__image {
  animation: zoom-fade 2.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  transition: none; /*fixes safari animation conflict*/

  @nest [data-disable-animations="true"] & {
    animation: none;
    opacity: 1
  }
}

/*Unload ajax styles*/
.collection-grid__wrapper.unload {
  min-height: 180px;
  position: relative;
  opacity: 0.2;
  transition: opacity 0.3s;

  & [data-section-type="collection-grid"] {
    animation: grid-product__loading 1.5s ease infinite 1.5s;
  }
}

.collection-sidebar {
  padding-right: 10px;
  margin-bottom: 20px;
}

@media (--medium-up) {
  .grid__item--sidebar {
    position: sticky;
    top: 10px; /*updated with sticky header JS*/
    max-height: 90vh;
    overflow-y: auto;
  }
}

.collection-sidebar__group {
  border-top: 1px solid;
  border-top-color: $colorDrawerBorder;
  padding-top: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }

  & .collapsible-content__inner {
    padding-bottom: 5px;
  }
}

/*Remove top border from first filter group*/
.filter-form div:first-of-type .collection-sidebar__group {
  border-top: 0;
  padding-top: 0;
}
