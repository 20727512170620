:root {
  --colorAnnouncement: {{ settings.color_announcement | default: "#1c1d1d" }};
  --colorAnnouncementText: {{ settings.color_announcement_text | default: "#fff" }};

  --colorBody: {{ settings.color_body_bg | default: "#fff" }};
  --colorBodyAlpha05: {{ settings.color_body_bg | default: "#fff" | color_modify: 'alpha', 0.05 }};
  --colorBodyDim: {{ settings.color_body_bg | default: "#1c1d1d" | color_darken: 5 }};
  --colorBodyLightDim: {{ settings.color_body_bg | default: '#fff' | color_darken: 2 }};
  --colorBodyMediumDim: {{ settings.color_body_bg | default: '#fff' | color_darken: 4 }};


  --colorBorder: {{ settings.color_borders | default: "#1c1d1d" }};

  --colorBtnPrimary: {{ settings.color_button | default: "#000" }};
  --colorBtnPrimaryLight: {{ settings.color_button | default: "#000" | color_lighten: 10 }};
  --colorBtnPrimaryDim: {{ settings.color_button | default: "#000" | color_darken: 5 }};
  --colorBtnPrimaryText: {{ settings.color_button_text | default: "#fff" }};

  --colorCartDot: {{ settings.color_cart_dot | default: "#ff4f33" }};

  --colorDrawers: {{ settings.color_drawer_background | default: "#1c1d1d" }};
  --colorDrawersDim: {{ settings.color_drawer_background | default: "#1c1d1d" | color_darken: 5 }};
  --colorDrawerBorder: {{ settings.color_drawer_border | default: "#343535" }};
  --colorDrawerText: {{ settings.color_drawer_text | default: "#fff" }};
  --colorDrawerTextDark: {{ settings.color_drawer_text | default: "#fff" | color_darken: 15 }};
  --colorDrawerButton: {{ settings.color_drawer_button | default: "#a26b25" }};
  --colorDrawerButtonText: {{ settings.color_drawer_button_text | default: "#fff" }};

  --colorFooter: {{ settings.color_footer | default: "#111" }};
  --colorFooterText: {{ settings.color_footer_text | default: "#fff" }};
  --colorFooterTextAlpha01: {{ settings.color_footer_text | default: "#fff" }};

  --colorGridOverlay: {{ settings.collection_grid_tint | default: "#000" }};
  --colorGridOverlayOpacity: 0.1;

  --colorHeaderTextAlpha01: {{ settings.color_header_text | default: '#000' | color_modify: 'alpha', 0.1 }};

  --colorHeroText: {{ settings.color_image_text | default: "#fff" }};

  --colorSmallImageBg: {{ settings.color_small_image_bg | default: "#eee" }};
  --colorLargeImageBg: {{ settings.color_large_image_bg | default: "#1c1d1d" }};

  --colorImageOverlay: {{ settings.color_image_overlay | default: "#000" }};
  --colorImageOverlayOpacity: {{ settings.color_image_overlay_opacity | divided_by: 100.0 }};
  --colorImageOverlayTextShadow: {{ settings.color_image_overlay_text_shadow | divided_by: 100.0 }};

  --colorLink: {{ settings.color_body_text | default: "#1c1d1d" }};

  --colorModalBg: {{ settings.color_modal_overlays | default: "#000" | color_modify: 'alpha', 0.6 }};

  --colorNav: {{ settings.color_header | default: "#fff" }};
  --colorNavText: {{ settings.color_header_text | default: "#000" }};

  --colorPrice: {{ settings.color_price | default: "#1c1d1d" }};

  --colorSaleTag: {{ settings.color_sale_tag | default: "#1c1d1d" }};
  --colorSaleTagText: {{ settings.color_sale_tag_text | default: "#ffffff" }};

  --colorTextBody: {{ settings.color_body_text | default: "#1c1d1d" }};
  --colorTextBodyAlpha015: {{ settings.color_body_text | default: '#1c1d1d' | color_modify: 'alpha', 0.15 }};
  --colorTextBodyAlpha005: {{ settings.color_body_text | default: "#1c1d1d"  | color_modify: 'alpha', 0.05 }};
  --colorTextBodyAlpha008: {{ settings.color_body_text | default: '#1c1d1d' | color_modify: 'alpha', 0.08 }};
  --colorTextSavings: {{ settings.color_savings_text | default: "#1c1d1d" }};

  --urlIcoSelect: url({{ 'ico-select.svg' | asset_url | split: '?' | first }});
  --urlIcoSelectFooter: url({{ 'ico-select-footer.svg' | asset_url | split: '?' | first }});
  --urlIcoSelectWhite: url({{ 'ico-select-white.svg' | asset_url | split: '?' | first }});

  --grid-gutter: 17px;
  --drawer-gutter: 20px;

  --sizeChartMargin: 25px 0;
  --sizeChartIconMargin: 5px;

  --newsletterReminderPadding: 40px;

  /*Shop Pay Installments*/
  --color-body-text: {{ settings.color_body_text | default: "#1c1d1d" }};
  --color-body: {{ settings.color_body_bg | default: "#fff" }};
  --color-bg: {{ settings.color_body_bg | default: "#fff" }};
}

@media (--medium-up) {
  :root {
    --grid-gutter: 22px;
    --drawer-gutter: 30px;
  }
}
