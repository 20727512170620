.template-blog .article {
  margin-bottom: calc($gutter * 1.5);

  @media (--medium-up) {
    margin-bottom: calc($gutter * 3);
  }
}

.template-blog .article:last-child {
  margin-bottom: 0;

  @media (--medium-up) {
    margin-bottom: calc($gutter * 1.5);
  }
}

.article__body {
  margin-bottom: calc($gutter / 2);

  @media (--medium-up) {
    margin-bottom: $gutter;
  }
}

.article__comment {
  margin-bottom: $gutter;

  &:last-child {
    margin-bottom: 0;
  }
}
