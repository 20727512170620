.pswp {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1500;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: none;
}

.pswp img {
  max-width: none;
}

/* style is added when JS option showHideOpacity is set to true */
.pswp--animate_opacity {
  /* 0.001, because opacity:0 doesn't trigger Paint action, which causes lag at start of transition */
  opacity: 0.001;
  will-change: opacity;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1); }

.pswp--open {
  display: block; }

.pswp--zoom-allowed .pswp__img {
  cursor: zoom-in; }

.pswp--zoomed-in .pswp__img {
  cursor: grab; }

.pswp--dragging .pswp__img {
  cursor: grabbing; }

/*
  Background is added as a separate element.
  As animating opacity is much faster than animating rgba() background-color.
*/
.pswp__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $colorBody;
  opacity: 0;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  will-change: opacity; }

.pswp__scroll-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.pswp__container,
.pswp__zoom-wrap {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

/* Prevent selection and tap highlights */
.pswp__container,
.pswp__img {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.pswp__zoom-wrap {
  position: absolute;
  width: 100%;
  transform-origin: left top;
  /* for open/close transition */
  transition: transform 333ms cubic-bezier(0.4, 0, 0.22, 1); }

.pswp__bg {
  will-change: opacity;
  /* for open/close transition */
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1); }

.pswp--animated-in .pswp__bg,
.pswp--animated-in .pswp__zoom-wrap {
  transition: none; }

.pswp__container,
.pswp__zoom-wrap {
  -webkit-backface-visibility: hidden; }

.pswp__item {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden; }

.pswp__img {
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  left: 0; }

/*
  stretched thumbnail or div placeholder element (see below)
  style is added to avoid flickering in webkit/blink when layers overlap
*/
.pswp__img--placeholder {
  -webkit-backface-visibility: hidden; }

.pswp--ie .pswp__img {
  width: 100% !important;
  height: auto !important;
  left: 0;
  top: 0; }

/*
  Error message appears when image is not loaded
  (JS option errorMsg controls markup)
*/
.pswp__error-msg {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  line-height: 16px;
  margin-top: -8px;
  color: #CCC; }

.pswp__error-msg a {
  color: #CCC;
  text-decoration: underline; }

.pswp__button {
  position: relative;

  /*Because JS listens to click on button element itself*/
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  /*Ignore clicks on svgs inside button*/
  & svg {
    pointer-events: none;
  }
}

.pswp__button--arrow--left .icon,
.pswp__button--arrow--right .icon {
  width: 13px;
  height: 13px;
  margin: 8px;
}

.pswp__button[disabled] {
  opacity: 0;
  pointer-events: none;
}

.pswp__ui {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: $gutter;
  left: 0;
  right: 0;
  transform: translateY(0);
  transition: transform 0.25s 0.6s;

  & .btn {
    margin: 15px;
  }
}

.pswp__ui--hidden {
  transform: translateY(150%);
  transition: transform 0.25s;
}

/* Fixes for iOS15 */
/* See https://gist.github.com/dimsemenov/0b8c255c0d87f2989e8ab876073534ea */

html.pswp-open-in-ios,
html.pswp-open-in-ios body {
  background: #444;
  height: var(--window-inner-height);
  overflow: hidden;
  box-sizing: border-box;
}

/* Hide page content via CSS when PhotoSwipe opens */
.pswp-open-in-ios body > * {
  display: none; 
}
.pswp-open-in-ios body .pswp.pswp--open {
  display: block; 
} 
