/*
  Shared styling for all images in our themes

  It's a work in progress and we're trying to move away from having to add specific styling for images in themes

  All images now have a base fade-in animation when they're loaded

  Motion & Streamline have settings to enable/disable animations for images
*/

image-element {
  .svg-mask &,
  .image-wrap:not(.image-wrap__thumbnail) &,
  .grid__image-ratio &,
  .countdown__background-image-wrapper &,
  .social-section__image-wrapper &,
  .fading-images__item-wrapper & {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

img {
  border: 0 none;
  display: block;
  max-width: 100%;
  height: auto;
}

.image-element {
  opacity: 0;

  &[loading="eager"] {
    opacity: 1;
  }

  @nest .template-giftcard & {
    opacity: 1;
  }

  @nest [data-animate_images="false"] & {
    opacity: 1;
  }

  @nest [data-media-gallery-layout="stacked"] & {
    opacity: 1;
  }

  @nest [data-aos="image-fade-in"].aos-animate & {
    animation: fade-in 0.5s cubic-bezier(0.29, 0.65, 0.58, 1) forwards;

    &.loader-logo__img {
      animation: pulse-fade 0.4s infinite linear;
    }

    // This should ensure that images aren't animated in Motion & Streamline
    // When the user has disabled animations
    @nest [data-animate_images="false"] & {
      animation: none;
    }

    // Gem's new stacked images will have the fade-in animation disabled
    @nest [data-media-gallery-layout="stacked"] & {
      animation: none;
    }

    // Remove animation from color scheme images
    &.scheme-image {
      animation: none;
    }

    &.hero__image {
      animation: none;
    }
  }

  // This should ensure that images show up if JS is disabled
  @nest .no-js & {
    opacity: 1;
  }
}

.image-wrap {
  position: relative;
}

.image-wrap img {
  width: 100%;
  object-fit: cover;
  height: auto;
}

.rte p[style*="text-align: center"],
.rte div[style*="text-align: center"] {
  & img {
    margin-left: auto;
    margin-right: auto;
  }
}
