.quote-icon {
  display: block;
  margin: 0 auto 20px;
}

.testimonial-stars {
  display: block;
  font-size: 16px;
  letter-spacing: 0.2em;
  margin-bottom: 10px;

  @media (--medium-up) {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.testimonials-slide {
  display: none;
  opacity: 0;
  padding: 40px 0 55px;
  width: 33%;

  /*Hide all but first slide to prevent page reflows prior to init*/
  &:first-child {
    display: block;
  }

  @nest .flickity-slider & {
    display: block;
    opacity: 1;
  }

  & .testimonials-slider__text {
    transform: scale(0.95);
    transition: transform 0.5s ease, box-shadow 0.5s ease;
  }

  &.is-selected .testimonials-slider__text {
    transform: scale(1.1);
    box-shadow: 0 10px 25px rgba(0,0,0,0.1);
    position: relative;
    z-index: 10;
  }

  @media (--small) {
    & {
      width: 100%;
    }

    & .testimonials-slider__text {
      transform: scale(0.86);
    }

    &.is-selected .testimonials-slider__text {
      transform: scale(1);
    }
  }
}

.testimonials-slider__text {
  margin: 0 30px;
  padding: 30px calc($gutter / 2);
  background: var(--colorBody);
  color: var(--colorTextBody);
  margin-bottom: calc($gutter / 2);

  @media (--medium-up) {
    margin: 0;
    padding: 30px;
    margin-bottom: 0;
  }

  & cite {
    font-style: normal;
    font-weight: bold;

    @media (--medium-up) {
      font-size: calc(var(--typeBaseSize) + 1px);
    }
  }

  & p {
    margin-bottom: calc($gutter / 4);

    & + cite {
      margin-top: 0;
    }
  }
}

.testimonials__info {
  font-size: calc(var(--typeBaseSize) - 1px);
}

$testimonialImageSize: 142px;
$testimonialImageSizeRound: 65px;

.testimonial-image {
  max-width: $testimonialImageSize;
  background-color: $colorBody;

  @nest .text-center & {
    margin-left: auto;
    margin-right: auto;
  }

  & .image-wrap {
    background: none;
    width: 100%;
    height: 100%;
  }
}

.testimonial-image--round {
  width: $testimonialImageSizeRound;
  height: $testimonialImageSizeRound;
  max-width: none;
  border-radius: $testimonialImageSizeRound;

  /*fix animation bug in Safari*/
  & img {
    overflow: hidden;
    border-radius: $testimonialImageSizeRound;
    height: 100%;
  }
}

.testimonials-section .flickity-page-dots {
  bottom: 0;
}
