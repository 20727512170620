.product-block {
  margin-bottom: 25px;

  & hr {
    margin: 0;
  }
}

.product-block--tab + .product-block--tab {
  margin-top: -25px;
}

.product-block--sales-point + .product-block--sales-point {
  margin-top: -20px;
}

.product-block--header + .product-block--price {
  margin-top: -20px;
}

.theme-block {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.size-chart__standalone .tool-tip-trigger__title {
  letter-spacing: 0.3em;
  font-size: 0.8em;
}
