.toolbar {
  background: $colorNav;
  color: $colorNavText;
  @mixin baseSmallFontStack;

  & a {
    color: $colorNavText;
  }

  /*Do not show in sticky header*/
  @nest .site-header--stuck & {
    display: none;
  }
}

.toolbar--transparent {
  background-color: transparent;
  color: $colorStickyNavLinks;
  border-bottom: none;

  & a {
    color: $colorStickyNavLinks;
  }

  & .toolbar__content {
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
}

.toolbar__content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.toolbar__item {
  flex: 0 1 auto;
  padding: 0 5px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  & select,
  & .faux-select {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
  }
}

.toolbar__item--menu {
  flex: 1 1 auto;
}

.toolbar__menu {
  margin-left: calc(-$gutter / 3); /*equivalent of a padding*/

  & a {
    display: block;
    padding: 5px calc($gutter / 3);
  }
}

.toolbar__social {
  text-align: right;

  & a {
    display: block;
    padding: 5px;
  }

  & .icon {
    position: relative;
    top: -2px;
    width: 16px;
    height: 16px;
  }
}
