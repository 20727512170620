.collection-item {
  position: relative;
  display: block;
  margin-bottom: $grid-gutter-small;
  overflow: hidden;

  @media (--medium-up) {
    margin-bottom: $grid-gutter;
  }

  @nest .grid--no-gutters & {
    margin-bottom: 0;
  }

  &:hover .collection-image {
    transform: scale(1.03,1.03);
    transition: all 0.8s ease;

    @nest [data-disable-animations="true"] & {
      transform: none;
      transition: none;
    }
  }
}

.collection-item:not(.collection-item--below) {
  &:after {
    @mixin overlay;
    background-color: $colorGridOverlay;
    opacity: var(--colorGridOverlayOpacity);
    transition: opacity 0.8s ease;
  }

  &:hover:after {
    opacity: calc(var(--colorGridOverlayOpacity) + 0.15);
  }
}

.collection-image {
  position: relative;
  transition: transform 0.3s ease;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.collection-item--below img {
  object-fit: contain;
}

.collection-image--placeholder {
  opacity: 1;

  & svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.collection-image--square {
  padding-bottom: 100%;
}

.collection-image--landscape {
  padding-top: 75%;
}

.collection-image--portrait {
  padding-top: 150%;
}

.collection-item__title {
  display: block;
  z-index: 2;
  font-size: calc(var(--typeCollectionTitle) * 0.8);
  line-height: 1em;

  @media (--medium-up) {
    font-size: var(--typeCollectionTitle);

    /*Slightly smaller base when 5-across on desktop*/
    @nest .medium-up--one-fifth & {
      font-size: 16px;
    }
  }

  @nest [data-type_product_capitalize="true"] & {
    & span {
      text-transform: uppercase;
      font-size: 0.8em;
      letter-spacing: 0.2em;
    }
  }

  @nest .collection-item--below & {
    margin-top: 5px;
  }
}

.collection-item__title--heading {
  @mixin headingFontStack;
}

.collection-item__title--overlaid,
.collection-item__title--overlaid-box {
  display: block;
  position: absolute;
  left: 10px;
  right: 10px;
}

.collection-item__title--overlaid {
  color: #fff;
  text-shadow: 0 0 50px rgba(0,0,0,1);
}

.collection-item__title--overlaid-box {
  & > span {
    display: inline-block;
    background-color: $colorBody;
    box-decoration-break: clone;
    padding: 8px 15px;
    color: $colorTextBody;
  }
}

/*Title alignment*/
.collection-item__title--top-center,
.collection-item__title--center,
.collection-item__title--bottom-center {
  text-align: center;
}

.collection-item__title--top-right
.collection-item__title--right,
.collection-item__title--bottom-right {
  text-align: right;
}

.collection-item__title--left,
.collection-item__title--center,
.collection-item__title--right {
  &[class*="collection-item__title--overlaid"] {
    top: 50%;
    transform: translateY(-50%);
  }
}

/*Top align some titles*/
.collection-item__title--top-left,
.collection-item__title--top-center,
.collection-item__title--top-right {
  &[class*="collection-item__title--overlaid"] {
    top: 10px;

    @media (--medium-up) {
      top: 18px;
    }
  }
}

/*Bottom align some titles*/
.collection-item__title--bottom-left,
.collection-item__title--bottom-center,
.collection-item__title--bottom-right {
  &[class*="collection-item__title--overlaid"] {
    bottom: 10px;

    @media (--medium-up) {
      bottom: 18px;
    }
  }
}
