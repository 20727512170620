.scrolling-text {
  overflow: hidden;
}

.scrolling-text__inner {
  visibility: visible;
  white-space: nowrap;
  display: inline-flex;
  margin: 0.15em 0;
  animation: scrolling-text var(--move-speed) linear infinite;
  animation-play-state: running;
}

.scrolling-text__inner--right {
  transform: translateX(-50%);
  animation: scrolling-text-right var(--move-speed) linear infinite;
}

.scrolling-text span {
  white-space: nowrap;
}

.scrolling-text:hover .scrolling-text__inner,
.scrolling-text:focus .scrolling-text__inner {
  animation-play-state: paused;
}

@keyframes scrolling-text {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scrolling-text-right {
  0% {
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
