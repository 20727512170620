.sales-points {
  list-style: none;
  padding: 0;
  margin: 25px 0;

  @nest .quick-add-modal & {
    display: none;
  }
}

.sales-point {
  display: block;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  & .icon {
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 10px;

    @nest [dir="rtl"] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}

@media (--small) {
  .sales-point .icon-and-text {
    justify-content: center;
  }
}
