product-recommendations[data-intent="complementary"] {
  display: block;
  overflow: hidden;

  & .product-recommendations__title {
    margin-top: 40px;
    width: 100%;
    max-width: 72%;
    margin-bottom: 20px;
    text-align: left;
  }

  & .grid {
    margin-left: 0;
  }

  & .grid__item,
  & .grid-item {
    margin-bottom: 1rem;
    padding-left: 0;
    padding-bottom: 8px;

    @media (--small) {
      margin-left: 0 !important;
      flex: 0 0 100%;
    }
  }

  & .grid-product__image-wrap {
    min-width: 90px;
  }

  & .grid__image-ratio {
    background-color: transparent;
  }

  & img.image-style--circle {
    border-radius: 50%;
    object-fit: cover;
    width: 85%;
    height: 85%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }

  & .grid__item-image-wrapper .grid-product__link {
    width: 100%;
    display: block;
  }

  & .grid__item-image-wrapper,
  & .grid-product__link,
  & .grid-item__link {
    display: flex;
    align-items: center;

    & .grid-product__image-mask,
    & .grid-product__image-wrap,
    & .image-wrap {
      width: 112px;
      margin: 0;
    }

    & .grid-product__meta {
      margin-left: 48px;
      width: calc(100% - 112px);
      text-align: left;
    }
  }

  & .grid-product__actions {
    top: 0;
    right: 0;
  }

  & .grid-product__tag {
    top: initial;
    right: initial;
    left: 0;
    bottom: 0;
  }

  & .flickity-viewport {
    width: 100%;
  }

  & .flickity-previous {
    left: auto;
    top: -32px;
    right: 50px;

    @media (--small) {
      right: 45px;
    }
  }

  & .flickity-next {
    right: 0;
    top: -32px;
  }

  & .flickity-page-dots {
    top: -41px;
    right: 0;
    width: auto;
    bottom: auto;
  }

  & .flickity-button:disabled {
    display: block;
    opacity: 0.35;
  }
}

.product-recommendations__slide {
  display: block;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;

  @media (--small) {
    display: flex;
    flex-wrap: wrap;
  }

  & .grid__item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.product-recommendations--title-missing {
  margin-top: 80px;
}
