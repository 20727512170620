.section-header {
  margin-bottom: $sectionHeaderBottomSmall;

  @media (--medium-up) {
    margin-bottom: $sectionHeaderBottom;
  }

  @nest [data-type_headers_align_text="true"] & {
    text-align: center;
  }

  & select {
    display: inline-block;
    vertical-align: middle;
  }
}

.section-header--flush {
  margin-bottom: 0;
}

.section-header--with-link {
  display: flex;
  align-items: center;

  & select {
    flex: 0 1 auto;
  }
}

.section-header--hero {
  position: relative;
  flex: 1 1 100%;
  color: $colorHeroText;
  margin-bottom: 0;

  & a {
    color: $colorHeroText;
  }
}

.section-header__shadow {
  position: relative;
  display: inline-block;

  &:before {
    @mixin overlay;
    @mixin heroRadial;
    z-index: -1;
  }

  & .breadcrumb,
  & .section-header__title {
    position: relative;
  }
}

.section-header__title {
  margin-bottom: 0;

  @nest .section-header--with-link & {
    flex: 1 1 auto;
  }

  @media (--medium-up) {
    @nest .section-header--hero & {
      font-size: calc(var(--typeHeaderSize) * 1.45);
    }
  }
}

.section-header__link {
  flex: 0 1 auto;
  margin-top: calc($gutter / 2);

  @nest .section-header--with-link & {
    margin-top: 0;
  }
}

.section-header--404 {
  padding-top: 80px;
}

.section-header select {
  margin: 10px 0;
}

.section-header p {
  margin: 10px 0;
}
