.footer-promotions {
  @mixin baseTextCenter;

  @media (--small) {
    & .grid__item {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.site-footer {
  padding-bottom: $gutter;
  background-color: $colorFooter;
  color: $colorFooterText;

  @media (--medium-up) {
    padding-top: calc($gutter * 2);
    padding-bottom: calc($gutter * 2);
  }

  & .footer__collapsible {
    @mixin baseSmallFontStack;
  }

  @media (--medium-up) {
    & textarea,
    & select,
    & input {
      @mixin baseSmallFontStack;
    }
  }

  @media (--small) {
    @mixin baseTextCenter;
    padding-bottom: 0;

    & .grid__item {
      &:after {
        content: '';
        border-bottom: 1px solid;
        border-bottom-color: $colorFooterText;
        opacity: 0.12;
        display: block;
      }

      &:last-child:after {
        display: none;
      }
    }

    & button.footer__title {
      margin: 0;
    }
  }

  & a {
    color: $colorFooterText;
  }
}

.footer__small-text {
  @mixin baseExtraSmallFontStack;
  padding: calc($gutter / 4) 0;
  margin: 0;
  text-align: center;

  @nest ul + & {
    padding-top: calc($gutter / 2);
  }
}

.footer__clear {
  clear: both;
  height: $gutter;
}

.footer__section {
  margin-bottom: calc($gutter / 2);

  @media (--medium-up) {
    margin-top: $gutter;
    margin-bottom: 0;
  }
}

@media (--medium-up) {
  .footer__item-padding {
    padding-right: calc($gutter * 2);
  }
}

.footer__title {
  color: $colorFooterText;

  @media (--medium-up) {
    margin-bottom: 20px;
  }

  @media (--small) {
    @mixin baseTextCenter;
  }
}

.site-footer__linklist {
  margin: 0;

  & a {
    color: currentColor;
    display: inline-block;
    padding: 4px 0;
  }
}

.footer__newsletter {
  position: relative;
  display: inline-block;
  max-width: 300px;
  width: 100%;
}

.footer__newsletter-input {
  padding: 10px 45px 10px 0;
  background-color: $colorFooter;
  color: $colorFooterText;
  max-width: 300px;
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid;
  border-bottom-color: $colorFooterText;

  &:focus {
    border: 0;
    border-bottom: 2px solid;
    border-bottom-color: $colorFooterText;
  }

  &::-webkit-input-placeholder {
    color: $colorFooterText;
    opacity: 1;
  }

  &:-moz-placeholder {
    color: $colorFooterText;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $colorFooterText;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $colorFooterText;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: $colorFooterText;
    opacity: 1;
  }
}

.footer__newsletter-input--active {
  padding-right: 80px;
}

.footer__newsletter-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: $colorFooterText;
  padding: 0;

  & .icon {
    width: 26px;
    height: 24px;
  }

  @nest .footer__newsletter-input--active + & {
    & .icon {
      display: none;
    }

    & .footer__newsletter-btn-label {
      display: block;
    }
  }
}

.footer__newsletter-btn-label {
  display: none;
  @mixin baseSmallFontStack;
}

.footer__logo {
  margin: calc($gutter / 2) 0;

  @media (--medium-up) {
    margin: 0 0 calc($gutter / 1.5);
  }

  & a {
    display: block;
  }

  & img {
    display: inline-block;
    transform: translateZ(0);
    max-height: 100%;
  }
}

.footer__social {
  margin: 0;

  @nest form + & {
    margin-top: $gutter;
  }

  & li {
    display: inline-block;
    margin: 0 15px 15px 0;
  }

  & a {
    display: block;
  }

  & .icon {
    width: 22px;
    height: 22px;

    @media (--medium-up) {
      width: 24px;
      height: 24px;
    }

    &.icon--wide {
      width: 40px;
    }
  }
}

/*Collapsible buttons*/
.footer__collapsible {
  @media (--small) {
    padding: 0 0 calc($gutter / 2) 0;
  }
}

.footer_collapsible--disabled {
  @media (--small) {
    padding-top: calc($gutter / 2);
  }
}

.collapsible-content__inner p {
  & a:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    border-bottom: 2px solid;
    border-bottom-color: $colorFooterText;
    transition: width 0.5s ease;
  }

  & a {
    position: relative;
    text-decoration: none;
    border-bottom: 2px solid;
    border-color: var(--colorFooterTextAlpha01);

    &:hover:after,
    &:focus:after {
      width: 100%;
    }
  }
}

.site-footer {
  & select {
    background-image: var(--urlIcoSelectFooter);
  }
}

.payment-icons {
  @mixin baseTextCenter;
  user-select: none;
  cursor: default;

  & li {
    cursor: default;
    margin: 0 4px 0;
  }
}

.footer__follow-on-shop {
  @media (--small) {
    margin: 15px 0;
  }
}
