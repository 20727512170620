.flex-grid {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;

  @nest [data-center-text="true"] & {
    justify-content: center;
  }
}

.flex-grid--center {
  align-items: center;
}

.flex-grid--gutters {
  margin-top: calc(-$gutter / 2);
  margin-left: calc(-$gutter / 2);
}

.flex-grid__item {
  flex: 0 1 100%;
  display: flex;
  align-items: stretch;

  @nest .flex-grid--gutters & {
    padding-top: calc($gutter / 2);
    padding-left: calc($gutter / 2);
  }

  & > * {
    flex: 1 1 100%;
  }
}

.flex-grid__item--stretch {
  flex: 1 1 100%;

  &:first-child {
    min-width: 250px;
  }
}

/*Flex grid sizing from theme settings*/
@media (--medium-up) {
  .flex-grid__item--33 {
    flex-basis: 33.33%;
  }

  .flex-grid__item--50 {
    flex-basis: 50%;
  }
}

/*Reverse order or elements*/
@media (--small) {
  .flex-grid__item--mobile-second {
    order: 2;
  }
}
