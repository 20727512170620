button {
  overflow: visible;
  color: currentColor;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

.btn,
.rte .btn,
.shopify-payment-button .shopify-payment-button__button--unbranded {
  line-height: 1.42;
  text-decoration: none;
  text-align: center;
  white-space: normal;

  font-size: calc(var(--typeBaseSize) - 4px);
  font-size: max(calc(var(--typeBaseSize) - 4px), 13px);
  font-weight: bold;
  @mixin accentFontStack;

  display: inline-block;
  padding: $btnPrimaryPadding;
  margin: 0;
  width: auto;
  min-width: 90px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: var(--buttonRadius);
  color: $colorBtnPrimaryText;
  background: $colorBtnPrimary;

  @media (--small) {
    padding: $btnPrimaryPaddingSmall;
    font-size: calc(var(--typeBaseSize) - 5px);
    font-size: max(calc(var(--typeBaseSize) - 5px), 11px);
  }

  &:hover {
    color: $colorBtnPrimaryText;
    background-color: $colorBtnPrimary;
  }

  &[disabled],
  &.disabled {
    cursor: default;
    color: $disabledGreyText;
    background-color: $disabledGrey;

    &:hover {
      color: $disabledGreyText;
      background-color: $disabledGrey;
    }

    @nest [data-button_style='angled'] & {
      &:before,
      &:after {
        background-color: $disabledGrey;
        border-top: 1px solid;
        border-top-color: $disabledBorder;
        border-bottom: 1px solid;
        border-bottom-color: $disabledBorder;
      }
    }
  }

  /*Custom setting-based styles*/
  @nest [data-button_style='angled'] & {
    position: relative;
    border: 0;
    margin-left: 10px;
    margin-right: 10px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      width: 20px;
      transform:skewX(-$buttonSkewDeg);
      background-color: inherit;
    }

    &:before {
      left: -6px;
    }

    &:after {
      right: -6px;
    }

    &.btn--small {
      &:before {
        left: -5px;
      }
      &:after {
        right: -5px;
      }
    }
  }

  /*Hover effect on non-angled buttons. Must only have .btn class*/
  @nest [data-button_style='square'] &,
  [data-button_style^='round'] & {
    &:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse):not(.btn--body):not(.btn--static) {
      position: relative;
      overflow: hidden;
      transition: background 0.2s ease 0s;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 150%;
        width: 200%;
        height: 100%;
        transform: skewX(-20deg);
        background-image: linear-gradient(to right,transparent, rgba(255,255,255,.25),transparent);
      }

      &:hover:after {
        animation: shine 0.75s cubic-bezier(0.01, 0.56, 1, 1);
      }

      &:hover {
        background: $colorBtnPrimaryLight;
        transition-delay: 0.25s;
      }

      &:active {
        background: $colorBtnPrimary;
        transition-delay: 0s;
      }
    }
  }
}

/*Mimic the .btn hover style for Shopify Payment Button*/
.shopify-payment-button .shopify-payment-button__button--unbranded:hover:not([disabled]) {
  color: $colorBtnPrimaryText;
  background-color: $colorBtnPrimary;
}

/*More payment options button color*/
.shopify-payment-button__more-options {
  color: inherit;
}

.btn--secondary,
.rte .btn--secondary {
  color: $colorTextBody;
  border: 1px solid;
  border-color: $colorBorder;
  background-color: transparent;

  @nest [data-button_style='angled'] & {
    border-left: 0;
    border-right: 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-top-color: $colorBorder;
    border-bottom-color: $colorBorder;

    &:before,
    &:after {
      background-color: transparent;
      top: -1px;
      bottom: -1px;
    }

    &:before {
      border-left: 1px solid;
      border-left-color: $colorBorder;
      border-bottom: 1px solid;
      border-bottom-color: $colorBorder;
    }

    &:after {
      border-top: 1px solid;
      border-top-color: $colorBorder;
      border-right: 1px solid;
      border-right-color: $colorBorder;
    }
  }

  &:hover {
    color: $colorTextBody;
    border-color: $colorTextBody;
    background-color: transparent;
    transition: border 0.3s ease;

    &:before {
      border-color: $colorTextBody;
      transition: border 0.3s ease;
    }

    &:after {
      border-color: $colorTextBody;
      transition: border 0.3s ease;
    }
  }
}

.btn--tertiary,
.rte .btn--tertiary {
  /*Undo .btn styles*/
  font-weight: normal;
  text-transform: none;
  letter-spacing: initial;

  /*New styles*/
  background-color: transparent;
  border: 1px solid;
  border-color: $colorBorder;
  color: $colorTextBody;
  padding: $formElementPadding;
  white-space: nowrap;

  &:hover {
    background-color: transparent;
    color: $colorTextBody;
  }

  &[disabled],
  &.disabled {
    cursor: default;
    color: $disabledGreyText;
    background-color: $disabledGrey;
    border-color: $disabledBorder;
  }

  @nest [data-button_style='angled'] & {
    margin-left: 0;
    margin-right: 0;

    &:before,
    &:after {
      content: none;
    }
  }
}

/*Force same colors as .btn*/
.btn--tertiary-active {
  color: $colorBtnPrimaryText;
  background: $colorBtnPrimary;
  border-color: $colorBtnPrimary;

  &:hover {
    color: $colorBtnPrimaryText;
    background: $colorBtnPrimary;
  }
}

/*Button that matches body background*/
.btn--body {
  border: 1px solid;
  border-color: $colorBorder;
  background-color: $colorBody;
  color: $colorTextBody;

  &:hover,
  &:active {
    border: 1px solid;
    border-color: $colorBorder;
    background-color: $colorBody;
    color: $colorTextBody;
  }
}

.btn--circle {
  padding: 10px;
  border-radius: 50%;
  min-width: 0;
  line-height: 1;

  & .icon {
    width: 20px;
    height: 20px;
  }

  &:before,
  &:after {
    content: none;
    background: none;
    width: auto; /*for photoswipe close button*/
  }

  &.btn--large .icon {
    width: 30px;
    height: 30px;
  }

  &.btn--large {
    padding: 15px;
  }

  /*Undo angled button styles on circles, but keep border*/
  @nest [data-button_style='angled'] & {
    border: 1px solid;
    border-color: $colorBorder;

    &:before,
    &:after {
      display: none;
    }
  }
}

.btn--small {
  padding: 8px 14px;
  background-position: 150% 45%;
  min-width: 90px;
  font-size: calc(var(--typeBaseSize) - 6px);
  font-size: max(calc(var(--typeBaseSize) - 6px), 12px);

  @nest [data-button_style='angled'] & {
    margin-left: 10px;
    margin-left: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.btn--secondary.btn--small {
  font-weight: normal;
}

.btn--large {
  padding: 15px 20px;
}

.btn--full {
  width: 100%;
  padding: $btnPrimaryPadding;
  transition: none;
  padding: 13px 20px;

  @nest [data-button_style='angled'] & {
    max-width: 94%;
  }
}

[data-button_style='angled'] .shopify-payment-button .shopify-payment-button__button--unbranded {
    max-width: 94%;
}

.btn--inverse {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  @nest [data-button_style='angled'] & {
    border-left: 0;
    border-right: 0;
    border-top: 2px solid;
    border-bottom: 2px solid;

    &:before,
    &:after {
      background-color: transparent;
      border-color: #fff;
      top: -2px;
      bottom: -2px;
    }

    &:before {
      border-left: 2px solid;
      border-bottom: 2px solid;
    }

    &:after {
      border-top: 2px solid;
      border-right: 2px solid;
    }
  }

  /*Specific color when in a hero*/
  @nest .hero__link & {
    color: $colorHeroText;
    border-color: $colorHeroText;

    @nest [data-button_style='angled'] & {
      &:before {
        border-color: $colorHeroText;
      }

      &:after {
        border-color: $colorHeroText;
      }
    }
  }
}

.btn--loading {
  position: relative;
  text-indent: -9999px;
  background-color: $colorBtnPrimaryDim;
  color: $colorBtnPrimaryDim;

  &:hover,
  &:active {
    background-color: $colorBtnPrimaryDim;
    color: $colorBtnPrimaryDim;
  }

  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
    border-radius: 50%;
    border: 3px solid;
    border-color: $colorBtnPrimaryText;
    border-top-color: transparent;
    animation: spin 1s infinite linear;
  }

  @nest [data-button_style='angled'] & {
    &:before {
      left: 50%;
      top: 50%;
      width: 24px;
      height: 24px;
      transform: none;
      border: 3px solid;
      border-color: $colorBtnPrimaryText !important;
      border-top-color: transparent !important;
    }

    &:after {
      background-color: $colorBtnPrimaryDim;
    }

    &.btn--secondary:after {
      bottom: 1px;
    }
  }
}

/*Alt loading state*/
.btn--loading.btn--secondary {
  color: $colorTextBody;
  background: transparent;

  &:before {
    border-color: $colorTextBody;
    border-top-color: transparent;
  }
}

.return-link {
  text-align: center;
  padding: 15px 25px;
  margin-top: 50px;

  @media (--small) {
    padding: 22px 17px;
    width: 100%;

    @nest [data-button_style='angled'] & {
      width: 90%;
    }
  }

  & .icon {
    width: 20px;
    margin-right: 8px;
  }
}

.collapsible-trigger-btn {
  text-align: left;
  @mixin baseTextCenter;
  @mixin accentFontStack;
  @mixin accentFontSmallSize;
  display: block;
  width: 100%;
  padding: 15px 10px 15px 0;

  @nest .collection-sidebar__group & {
    text-align: left;
  }
}

.collapsible-trigger-btn--borders {
  border: 1px solid;
  border-color: $colorBorder;
  border-bottom: 0;
  padding: 12px;

  & .collapsible-trigger__icon {
    right: 12px;
  }

  @media (--medium-up) {
    padding: 15px;

    & .collapsible-trigger__icon {
      right: 15px;
    }
  }

  @nest .collapsible-content + & {
    margin-top: -1px;
  }

  & + .collapsible-content .collapsible-content__inner {
    border: 1px solid;
    border-color: $colorBorder;
    border-top: 0;
    padding: 0 20px 20px;
    font-size: calc(var(--typeBaseSize)*0.92);
  }

  & + .collapsible-content--expanded {
    margin-bottom: $gutter;

    &:last-child {
      margin-bottom: -1px;
    }
  }
}

.collapsible-trigger-btn--borders-top {
  border-top: 1px solid;
  border-top-color: $colorBorder;
}

/*============================================================================
  Button styles when additional quick checkout buttons
  are enabled on product page
==============================================================================*/
.shopify-payment-button {
  margin-top: 10px;
}

.shopify-payment-button .shopify-payment-button__button--unbranded {
  display: block;
  width: 100%;
  transition: none;
}

.payment-buttons {
  & .add-to-cart,
  & .shopify-payment-button,
  & .shopify-payment-button__button--unbranded {
    min-height: $btnMinHeightWhenQuickCheckout;
  }
}

.add-to-cart.btn--secondary {
  border: 1px solid;
  border-color: $colorTextBody;

  @nest [data-button_style='angled'] & {
    border-left: 0;
    border-right: 0;

    &:before,
    &:after {
      border-color: $colorTextBody;
    }
  }

  &.disabled,
  &[disabled] {
    border-color: $disabledBorder;

    @nest [data-button_style='angled'] & {
      &:before,
      &:after {
        border-color: $disabledBorder;
      }
    }
  }
}

.shopify-payment-button__button--hidden {
  display: none !important;
}
