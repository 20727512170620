svg:not(:root) {
  overflow: hidden;
}

iframe {
  max-width: 100%;
}

.video-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  height: auto;

  & iframe,
  & video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-wrapper--modal {
  width: 1000px;
}

.grid__image-ratio {
  position: relative;
  background-color: $colorSmallImageBg;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.grid__image-contain {
      object-fit: contain;
    }
  }

  &:before {
    content: '';
    display: block;
    height: 0;
    width: 100%;
  }

  & .placeholder-svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.grid__image-ratio--object {
  opacity: 1;
}

.grid__image-ratio--wide:before {
  padding-bottom: 56.25%;
}

.grid__image-ratio--landscape:before {
  padding-bottom: 75%;
}

.grid__image-ratio--square:before {
  padding-bottom: 100%;
}

.grid__image-ratio--portrait:before {
  padding-bottom: 150%;
}

.image-fit {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  z-index: 1;
}

.parallax-container {
  position: absolute;
  top: -30%;
  left: 0;
  height: 160%;
  width: 100%;
}

.parallax-image {
  position: absolute;
  top: -5%;
  left: 0;
  width: 100%;
  height: 110%;

  & img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
