.note,
.errors {
  border-radius: $input-radius;
  padding: 6px 12px;
  margin-bottom: calc($gutter / 2);
  border: 1px solid transparent;
  text-align: left;

  & ul,
  & ol {
    margin-top: 0;
    margin-bottom: 0;
  }

  & li:last-child {
    margin-bottom: 0;
  }

  & p {
    margin-bottom: 0;
  }
}

.note {
  border-color: $colorBorder;
}

.errors {
  & ul {
    list-style: disc outside;
    margin-left: 20px;
  }
}

.note--success {
  color: $successGreen;
  background-color: $successGreenBg;
  border-color: $successGreen;

  & a {
    color: $successGreen;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.form-error,
.errors {
  color: $errorRed;
  background-color: $errorRedBg;
  border-color: $errorRed;

  & a {
    color: $errorRed;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
