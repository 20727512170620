.newsletter-container {
  margin: 0 auto !important;
  padding: 0 !important;
}

.newsletter-section {
  display: flex;
  gap: 40px;
  align-items: center;
  padding: 60px 0;

  @media (--small) {
    flex-direction: column;
    padding: 30px 0;
  }
}

.newsletter-section--image-left {
  flex-direction: row-reverse;

  @media (--small) {
    flex-direction: column-reverse;
  }
}

.newsletter-section__content {
  flex: 100%;
  max-width: 800px;
  padding-left: 20px;
  margin: 0 auto;

  @nest .newsletter-section--no-image & {
    flex: 100%;
  }

  & form {
    display: flex;
    width: 100%;

    @nest .text-left & {
      justify-content: flex-start;
    }

    @nest .text-center & {
      justify-content: center;
    }

    @nest .text-right & {
      justify-content: flex-end;
    }
  }

  & .newsletter__input-group {
    margin: 0;
  }
}

.newsletter-section__image {
  width: 100%;

  & img {
    display: block;
    margin: 0 auto;
    object-fit: cover;
    height: auto;
  }
}

.newsletter-section__image--33 {
  flex: 33.333% 0 0;
}

.newsletter-section__image--50 {
  flex: 50% 0 0;

  @media (--small) {
    max-width: 100%;
  }
}

.newsletter-section__image--66 {
  flex: 66.666% 0 0;

  @media (--small) {
    max-width: 100%;
  }
}

.newsletter-form-group {
  @nest .text-left & {
    margin: 0;
  }

  @nest .text-right & {
    margin: 0;
  }
}
