table {
  width: 100%;
  border-spacing: 1px;
  position: relative;
  border: 0 none;
  background: $colorBorder;
}

.table-wrapper {
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

td,
th {
  border: 0 none;
  text-align: left;
  padding: 10px 15px;
  background: $colorBody;

  @nest html[dir="rtl"] & {
    text-align: right;
  }
}

th {
  font-weight: bold;
}

th,
.table__title {
  font-weight: bold;
}
