.hotspots-section .index-section {
  @media (--small) {
    margin-bottom: 10px;
  }
}

.hotspots-section .hotspots-wrapper {
  display: flex;
  flex-wrap: wrap;

  &.is-reverse {
    flex-direction: row-reverse;
  }
}

.hotspots__title {
  width: 100%;
  padding-top: 1.5rem;
}

.hotspots {
  position: relative;
  width: 70%;

  @media (--small) {
    width: 100%;
  }

  & .grid__image-ratio img {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @nest [data-animate_images="true"] & {
      opacity: 1;
    }
  }
}

.hotspots__buttons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: transparent;
}

.hotspot__button {
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  line-height: 0;
  transform: translate(-50%, -50%);

  @media (--small) {
    padding: 6px;
  }


  &:hover .hotspot__button-content {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.hotspot__button-content {
  color: var(--colorTextBody);
  background-color: var(--colorBody);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  padding: 1rem;
  position: absolute;
  top: calc(100% + 1rem);
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease-in-out;
  min-width: 5rem;
  border-radius: 5px;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.2);

  & p,
  & span {
    white-space: nowrap;
    margin-bottom: 0
  }

  &:before {
    position: absolute;
    top: -10px;
    left: 50%;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    transform: translateX(-50%);
    border-color: transparent transparent var(--colorBody) transparent;
  }

  & .content__prices {
    display: flex;
    flex-wrap: nowrap;
  }
}

.hotspots__content {
  width: 30%;
  padding: 24px;
  display: flex;
  align-items: center;

  @nest .page-width & {
    padding-right: 0;
    padding-left: 40px;

    @media (--small) {
      padding-left: 0;
    }
  }

  @nest .page-width.is-reverse & {
    padding-left: 0;
    padding-right: 40px;

    @media (--small) {
      padding-right: 20px;
    }
  }

  @media (--small) {
    width: 100%;
    padding: 1rem 20px 0;
  }
}

.hotspot-content__block {
  display: none;
  max-height: 0;
  width: 100%;
  animation: fade-in .5s ease 0s forwards;
  position: sticky;
  top: 0;

  /* animation and position:sticky causes issues in themes where quickshop modal is nested */
  @nest .modal-open &,
  .modal-closing & {
    animation: none;
    position: static;
  }

  @media (--small) {
    align-items: center;
    position: relative;
    padding-top: 0;
  }

  &.is-active {
    display: block;
    max-height: initial;
  }

  & .grid-product__image-wrap {
    margin: 0;
  }

  & .grid__item {
    display: block;
    float: none;
    padding: 0;
  }

  & .grid-product__tags {
    margin-left: 0;
  }

  & .grid-product__tag {
    @media (--small) {
      right: auto;
      left: 0;
    }
  }

  & .grid-item__meta,
  & .grid-product__meta {
    padding-top: 10px;

    @media (--small) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      padding-left: 10px;
      padding-top: 0;
      text-align: left;

      & .grid-item__meta-main,
      & .grid-item__meta-secondary {
        width: 100%;
        flex: none;
      }
    }
  }

  @media (--small) {

    & .grid-product {
      padding-right: 0;
      padding-left: 0;
    }

    & .quick-add-btn {
      display: none;
    }

    /* Class is unique to Impulse */
    & .grid__item-image-wrapper {
      display: flex;

      & .grid-product__image-mask {
        flex: 0 0 30%;
      }
    }

    & .grid-item__link,
    & .grid-product__link {
      display: flex;
      flex-wrap: nowrap;
    }

    /* TODO: Adjust global stylings in grid.css so we don't have to add exceptions like this */
    & .grid-item__link {
      @nest [data-grid-style="grey-round"] & {
        border-radius: 10px 0 0 10px;
      }

      @nest [data-grid-style="white-round"] & {
        border-radius: 10px 0 0 10px;
      }
    }

    & .grid-product__image-mask,
    & .grid-product__image-wrap,
    & .product-slider {
      width: 30%;
    }

    & .grid-product__actions {
      right: initial;
      left: 10px;
      top: 10px;
    }
  }

}

.hotspot-content__block .modal .grid__item {
  float: left;
}
