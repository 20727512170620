body,
input,
textarea,
button,
select,
.faux-select {
  @mixin baseFontStack;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeSpeed;
}

body {
  font-weight: var(--typeBaseWeight);
}

p {
  margin: 0 0 calc($gutter / 2) 0;

  & img {
    margin: 0;
  }
}

em {
  font-style: italic;
}

b, strong {
  font-weight: bold;
}

small,
p[data-spam-detection-disclaimer] {
  font-size: 0.85em;
}

sup, sub {
  position: relative;
  font-size: 60%;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.5em;
}

blockquote,
.rte blockquote {
  margin: 0;
  padding: calc($gutter / 2) $gutter 40px;

  & p {
    margin-bottom: 0;

    & + cite {
      margin-top: calc($gutter / 2);
    }
  }

  & cite {
    display: block;
  }
}

code, pre {
  background-color: #faf7f5;
  font-family: Consolas, monospace;
  font-size: 1em;
  border: 0 none;
  padding: 0 2px;
  color: #51ab62;
}

pre {
  overflow: auto;
  padding: calc($gutter / 2);
  margin: 0 0 $gutter;
}

label:not(.variant__button-label):not(.text-label),
.label {
  @mixin accentFontStack;
  @mixin accentFontSmallSize;
}

label {
  display: block;
  margin-bottom: 10px;
}

.label-info {
  display: block;
  margin-bottom: 10px;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  display: block;
  margin: 0 0 10px;

  @media (--medium-up) {
    margin: 0 0 calc($gutter / 2);
  }

  & a {
    text-decoration: none;
    font-weight: inherit;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  @mixin headingFontStack;
}

/* Mobile sizes */
h1, .h1 {
  font-size: calc(var(--typeHeaderSize) * 0.85);
}

h2, .h2 {
  font-size: calc(var(--typeHeaderSize) * 0.73);
}

h3, .h3 {
  font-size: calc(var(--typeHeaderSize) * 0.62);
}

/* Desktop sizes */
@media (--medium-up) {
  h1, .h1 {
    font-size: var(--typeHeaderSize);
  }

  h2, .h2 {
    font-size: calc(var(--typeHeaderSize) * 0.85);
  }

  h3, .h3 {
    font-size: calc(var(--typeHeaderSize) * 0.65);
  }
}

h4, .h4 {
  @mixin accentFontStack;
  @mixin accentFontSmallSize;
}

h5, .h5,
h6, .h6 {
  @mixin accentFontStack;
  @mixin accentFontSmallSize;
  margin-bottom: 10px;

  @media (--small) {
    margin-bottom: 5px;
  }
}

.subheading {
  @mixin accentFontStack;
}

/*Standardize text spacing sometimes*/
.text-spacing,
.text-spacing.rte:last-child {
  margin-bottom: calc($gutter / 2);
}

.rte {
  & table {
    @media (--small) {

      & td,
      & th {
        padding: 6px 8px;
      }
    }

    @nest .collapsible-content & {
      & td,
      & th {
        padding: 6px 8px;
      }
    }
  }
}

.comment-author {
  margin-bottom: 0;
}

.comment-date {
  @mixin baseSmallFontStack;
  display: block;
  margin-top: 3px;

  @media (--small) {
    margin-bottom: calc($gutter / 2);
  }
}

.ajaxcart__subtotal {
  @mixin accentFontStack;
  @mixin accentFontSmallSize;
}

.rte {
  & .enlarge-text {
    margin: 0;
    font-size: 1.3em;
  }

  & .enlarge-text--offset {
    & p {
      @media (--medium-up) {
        padding-right: 15%;

        @nest .text-center & {
          padding: 0 5%;
        }
      }
    }
  }
}

@media (--medium-up) {
  .table--small-text {
    @mixin baseSmallFontStack;
  }
}

.index-section--footer {
  & h3 {
    font-size: 1.5em;
  }
}

/*================ RTL overrides ================*/
html[dir="rtl"] {
  & .text-left {
    text-align: right !important;
  }

  & .text-right {
    text-align: left !important;
  }
}

/*Icon + text element that prevents awkward line wrapping*/
.icon-and-text {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  & .icon {
    flex: 0 0 auto;
  }
}
