.grid-search {
  margin-bottom: $gutter;
}

.grid-search__product {
  position: relative;
  text-align: center;
}

.grid-search__page-link {
  display: block;
  background-color: var(--colorBodyLightDim);
  padding: 20px;
  color: $colorTextBody;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    background-color: var(--colorBodyMediumDim);
  }
}

.grid-search__page-content {
  display: block;
  height: 100%;
  overflow: hidden;
}

.grid-search__page-content img {
  display: block;
  margin-bottom: 10px;
}

.grid-search__image {
  display: block;
  padding: 20px;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;

  @media (--medium-up) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
