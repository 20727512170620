.predictive-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: $colorBody;
  color: $colorTextBody;
  max-height: 70vh;
  max-height: calc(90vh - 100%);
  overflow: auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);

  @media (--medium-up) {
    max-height: calc(100vh - 100% - 33px); /*search bar + toolbar*/
  }
}

.predictive__label {
  border-bottom: 1px solid;
  border-bottom-color: $colorBorder;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

/*Layout for article/images*/
$predictiveGap: 10px;
.predictive-result__layout {
  display: flex;
  flex-wrap: wrap;
  padding: $predictiveGap;
  margin-left: -$predictiveGap;
  margin-right: -$predictiveGap;

  & > div {
    margin: 0 $predictiveGap $gutter;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & [data-type-products] {
    flex: 1 1 60%;
    margin-bottom: 0;

    @media (--small) {
      margin-left: -$grid-gutter-small;

      @nest html[dir="rtl"] & {
        margin-left: 0;
        margin-right: -$grid-gutter-small;
      }
    }

    & .grid-product__image {
      opacity: 1;
      height: auto;
    }
  }

  & [data-type-collections],
  & [data-type-pages] {
    flex: 1 1 200px;
  }

  & [data-type-articles] {
    flex: 1 1 60%;

    & .grid-product__link--inline {
      gap: 10px;
    }
  }
}

.predictive__image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & img {
    object-position: 50% 0;
  }
}

.predictive-results__footer {
  padding: 0 0 30px;
}
