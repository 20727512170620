.article__grid-image {
  display: block;
  text-align: center;
  margin-bottom: 17px;

  @media (--medium-up) {
    margin-bottom: 20px;
  }

  & img {
    display: block;
  }
}

.article__title {
  font-size: calc(var(--typeBaseSize) + 2px);
}

.article__date {
  @mixin baseExtraSmallFontStack;
  margin-bottom: 3px;

  @nest .section-header & {
    margin-bottom: calc($gutter / 2);
  }
}

.article__author {
  margin-top: 2px;
  @mixin baseExtraSmallFontStack;
}

.article__grid-meta {
  margin-bottom: $gutter;

  @media (--medium-up) {
    @mixin baseTextCenter;
  }
}

.article__excerpt {
  margin-top: 10px;
}
