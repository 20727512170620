/* Newsletter reminder */

newsletter-reminder {
  position: fixed;
  left: 20px;
  bottom: 20px;
  transition: ease-in-out 0.3s opacity;
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
  max-width: 240px;
  z-index: 10;
  @media (--small) {
    max-width: calc(100% - 40px);
  }

  &[data-enabled="false"] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &[class*="color-scheme-"]:not(.color-scheme-none) {
    position: fixed;
  }

  & .color-scheme-none {
    color: var(--colorBtnPrimaryText);
    background-color: var(--colorBtnPrimary);
  }
}

.newsletter-reminder__message.h3 {
  cursor: pointer;
  padding: var(--newsletterReminderPadding);
  margin: 0;
}
