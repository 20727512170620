/*collapsibles-wrapper is an optional wrapper*/
.collapsibles-wrapper--border-bottom {
  border-bottom: 1px solid;
  border-bottom-color: $colorBorder;
}

.collapsibles-wrapper--border-bottom + .collapsibles-wrapper--border-bottom .collapsible-trigger-btn--borders {
  border-top: 0;
}

.product-block--tab + .product-block--tab .collapsible-trigger-btn--borders {
  border-top: 0;
}

.collapsible-trigger {
  color: inherit;
  position: relative;
}

.collapsible-trigger__icon {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: $collapsible-trigger-icon-width-small;
  height: $collapsible-trigger-icon-width-small;
  transform: translateY(-50%);

  @media (--medium-up) {
    width: $collapsible-trigger-icon-width;
    height: $collapsible-trigger-icon-width;
  }

  @nest .mobile-nav__has-sublist & {
    right: 25px;
  }

  & .icon {
    display: block;
    width: $collapsible-trigger-icon-width-small;
    height: $collapsible-trigger-icon-width-small;
    transition: all 0.1s ease-in;

    @media (--medium-up) {
      width: $collapsible-trigger-icon-width;
      height: $collapsible-trigger-icon-width;
    }
  }
}

.collapsible-trigger--inline {
  padding: 11px 0 11px 40px;

  & .collapsible-trigger__icon {
    right: auto;
    left: 0;
  }
}

.collapsible-trigger__icon--circle {
  border: 1px solid;
  border-color: $colorBorder;
  width: $collapsible-trigger-circle-width;
  height: $collapsible-trigger-circle-width;
  border-radius: $collapsible-trigger-circle-width;
  text-align: center;

  & .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.collapsible-trigger.is-open .collapsible-trigger__icon > .icon-chevron-down {
  transform: scaleY(-1);
}

/*Slightly different for circle buttons*/
.collapsible-trigger.is-open .collapsible-trigger__icon--circle > .icon-chevron-down {
  transform: translate(-50%, -50%) scaleY(-1);
}

.collapsible-content {
  transition: $collapsible-close-transition;

  &.is-open {
    visibility: visible;
    opacity: 1;
    transition: $collapsible-open-transition;
  }
}

.collapsible-content--all {
  visibility: hidden;
  overflow: hidden;
  backface-visibility: hidden;
  opacity: 0;
  height: 0;

  & .collapsible-content__inner {
    transform: translateY(40px);
  }
}

@media (--small) {
  .collapsible-content--small {
    visibility: hidden;
    overflow: hidden;
    backface-visibility: hidden;
    opacity: 0;
    height: 0;

    & .collapsible-content__inner {
      transform: translateY(40px);
    }
  }
}

.collapsible-content__inner {
  transition: $collapsible-content-close-transition;

  @nest .is-open & {
    transform: translateY(0);
    transition: $collapsible-content-open-transition;
  }
}

.rte.collapsible-content__inner--faq {
  padding-bottom: $gutter;
}

.collapsible-label__closed {
  @nest .collapsible-trigger[aria-expanded="true"] & {
    display: none;
  }
}

.collapsible-label__open {
  display: none;

  @nest .collapsible-trigger[aria-expanded="true"] & {
    display: inline-block;
  }
}

/*Custom functionality for collection sidebar*/
.collapsible-content--sidebar {
  visibility: hidden;
  overflow: hidden;
  backface-visibility: hidden;
  opacity: 0;
  height: 0;

  @media (--medium-up) {
    &.is-open {
      overflow: initial;
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
}
