$z-index-promo-tint: 2;
$z-index-promo-color-overlay: 3;
$z-index-promo-frame: 3;
$z-index-promo-text: 3;
$z-index-promo-content: 4;
$z-index-promo-link: 5;

.promo-grid--space-top {
  padding-top: $indexSectionMarginSmall;
  @media (--medium-up) {
    padding-top: $indexSectionMarginLarge;
  }
}

.promo-grid--space-bottom {
  padding-bottom: $indexSectionMarginSmall;
  @media (--medium-up) {
    padding-bottom: $indexSectionMarginLarge;
  }
}

.promo-grid__container {
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;

  & .btn {
    margin-right: 10px;

    @media (--small) {
      margin-right: 7px;
    }
  }

  &.horizontal-center {
    justify-content: center;
    text-align: center;

    & .btn {
      margin: 2px 5px;
    }
  }

  &.horizontal-right {
    justify-content: flex-end;
    text-align: right;
  }
}

.promo-grid__container--boxed {
  @media (--small) {
    & .promo-grid__bg {
      height: 60%;
    }

    & .promo-grid__content {
      width: 100%;
      margin-top: 55%;
      box-shadow: 0 0 50px rgba(0,0,0,0.1);
    }
  }
}

/*Framed but not boxed*/
.promo-grid__container--framed:not(.promo-grid__container--boxed):after {
  @mixin overlay;
  border: $textFrameMarginSmall solid transparent;
  box-shadow: inset 0 0 0 2px $colorHeroText;
  z-index: $z-index-promo-frame;

  @media (--medium-up) {
    border-width: $textFrameMarginLarge;
  }

  /*When text is not overlaying image, border should be text color*/
  @nest .type-sale_collection &,
  .type-product &,
  .type-banner & {
    box-shadow: inset 0 0 0 2px $colorTextBody;
  }
}

.video-interactable.promo-grid__container--framed:not(.promo-grid__container--boxed):after {
  pointer-events: none;
}

.promo-grid__container--tint:before {
  @mixin overlay;
  opacity: 1;
  z-index: $z-index-promo-tint;
  pointer-events: none;
}

.promo-grid__slide-link {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $z-index-promo-link;

  &:hover {
    & ~ .promo-grid__content .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse) {
      background: $colorBtnPrimaryLight;
      transition-delay: 0.25s;

      @nest [data-button_style='square'] &,
      [data-button_style^='round'] & {
        &:after {
          animation: shine 0.75s cubic-bezier(0.01, 0.56, 1, 1);
        }
      }
    }
  }
}

.promo-grid__content {
  flex: 0 1 auto;
  padding: 2em 2.5em; /*Relative to text-size*/
  position: relative;
  min-width: 200px;
  z-index: $z-index-promo-content;

  @nest .promo-grid__container--framed:not(.promo-grid__container--boxed) & {
    margin: 1.5em;
  }

  & p:last-child {
    margin-bottom: 0;
  }

  @nest .vertical-top & {
    align-self: flex-start;
  }

  @nest .vertical-center & {
    align-self: center;
  }

  @nest .vertical-bottom & {
    align-self: flex-end;
  }
}

.video-interactable .promo-grid__content {
  pointer-events: none;
}

.promo-grid__content--boxed {
  background: $colorBody;
  color: $colorTextBody;
  margin: calc($gutter / 2);

  @media (--small) {
    margin: 10px;
  }
}

.promo-grid__content--framed.promo-grid__content--boxed {
  border: $textFrameMarginSmall solid;
  border-color: $colorBody;
  box-shadow: inset 0 0 0 2px $colorTextBody;

  @media (--medium-up) {
    border-width: $textFrameMarginLarge;
  }
}

/*Radial gradient on only some types*/
.type-advanced,
.type-simple {
  & .promo-grid__content:not(.promo-grid__content--boxed):not(.promo-grid__content--sale) {
    color: $colorHeroText;

    & a {
      color: $colorHeroText;
      border-bottom: 2px solid;
      border-bottom-color: $colorHeroText;

      @nest [data-button_style='square'] &,
      [data-button_style^='round'] & {
        &:not(.btn--inverse) {
          border-bottom: 0;
        }
      }
    }

    & .btn--inverse {
      border-color: $colorHeroText;
    }

    &:after {
      @mixin overlay;
      @mixin heroRadial;
      z-index: $z-index-promo-tint;
    }
  }
}

.type-advanced,
.type-product,
.type-sale_collection {
  & .rte--strong {
    @mixin headingFontStack;
    line-height: 1.1;
  }

  & .rte--em {
    @mixin accentFontStack;
    font-size: 0.9375em;
    line-height: 1.2;
  }
}

.type-advanced,
.type-product {
  & .rte--strong {
    font-size: 1.6em;
    line-height: 1.1;
  }
}

@media (--medium-up) {
  .type-advanced,
  .type-product {
    & .rte--strong {
      font-size: 2.125em;
    }
  }

  .type-product {
    &.flex-grid__item--50,
    &.flex-grid__item--33 {
      & .rte--strong {
        font-size: 1.6em;
      }
    }
  }
}

.promo-grid__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;

  /*Add color overlay if not boxed version*/
  @nest .promo-grid__container:not(.promo-grid__container--boxed) &:before {
    @mixin overlay $z-index-promo-color-overlay;
    @mixin heroScrim;
  }

  & .placeholder-svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    width: auto;
    padding: 0;
  }
}

.video-interactable .promo-grid__bg:before {
  pointer-events: none;
}

.promo-grid__bg-image {
  z-index: $z-index-hero-image;

  @nest [data-aos="image-fade-in"].aos-animate & {
    animation: fade-in 1s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
    transition: none; /*fixes safari animation conflict*/
  }
}

.promo-grid__text {
  position: relative;
  z-index: $z-index-promo-frame;

  & .btn {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

/* ======================= Type: Advanced ===================== */
.type-advanced {
  & .promo-grid__content {
    padding: 2em;

    @media (--small) {
      padding: 1.5em;
    }
  }

  & .btn {
    margin-bottom: 10px;

    @media (--small) {
      margin-bottom: 7px;
    }
  }
}

/* ======================= Type: Sale Collection ===================== */
.type-sale_collection {
  flex-grow: 1;
  max-height: 600px;

  & .promo-grid__container {
    background: $colorSmallImageBg;
    align-items: center;
  }

  & .promo-grid__content {
    padding: 2em;
    flex: 0 1 auto;
    min-width: 0;

    @media (--small) {
      padding: 1em;
      font-size: 0.9em;
    }

    @media (--medium-up) {
      &:not(.promo-grid__content--small-text) {
        font-size: 1.5em;
      }
    }
  }

  /*Custom typography styles*/
  & .rte--block {
    margin-bottom: 7.5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & .rte--strong {
    position: relative;
    display: block;
    font-size: 3.375em;
    line-height: 1;
    white-space: nowrap;
  }

  /*Custom type layout for "off" when following "%"*/
  & small {
    display: inline;
    font-size: 0.25em;
    margin-left: -2.9em;
    letter-spacing: 0;
  }
}

.type-sale-images {
  flex: 1 1 50%;

  & svg {
    display: block;
    width: 50%;
    float: left;
  }
}

.type-sale-images__crop {
  overflow: hidden;
  width: 100%;
}

.type-sale-images__image {
  width: 50%;
  float: left;
}

/* ======================= Type: Simple ===================== */
.type-simple {
  & .promo-grid__content {
    padding: calc($gutter / 1);
    @media (--small) {
      padding: calc($gutter / 2);
    }
  }
}

.promo-grid__title:last-child {
  margin-bottom: 0;
}

/* ======================= Type: Image ===================== */
.type-image {
  & .promo-grid__container {
    background: none;
  }

  & img,
  & a,
  & .image-wrap {
    width: 100%;
  }
}

/*================ Type: Banner ================*/
.type-banner {
  flex: 1 0 100%;

  & .promo-grid__container {
    background: none;
  }

  & .promo-grid__container--framed:not(.promo-grid__container--boxed) {
    padding: 1em;
  }

  & p {
    margin: 5px 10px;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
  }
}

.type-banner__link {
  display: block;
  flex: 1 1 100%;
}

.type-banner__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.type-banner__text {
  position: relative;
  flex: 0 1 auto;
  z-index: $z-index-promo-text;
  padding: 10px 20px;
}

.type-banner__image {
  flex: 0 0 45%;

  @media (--medium-up) {
    flex: 0 0 200px;
  }
}

/*================ Type: Product ================*/
.type-product__wrapper {
  flex: 1 1 100%;
  align-self: center;
  position: relative;
  padding: 2em 0;
  z-index: $z-index-promo-content;

  &.promo-grid__container--tint {
    padding: 2em;
  }

  @nest .promo-grid__container--framed & {
    padding: 2em;
  }
}

.type-product__content {
  @media (--small) {
    margin-top: calc($gutter / 2);
  }
}

.type-product__images {
  position: relative;
  width: 100%;
  margin: 10px 0 10px 10px;
}

.type-product__image {
  position: relative;

  &:first-child {
    width: 100%;
    max-width: 75%;
  }

  &:nth-child(2) {
    position: absolute;
    bottom: 40px;
    right: 0;
    width: 100%;
    max-width: 30%;
  }
}

.type-product__labels {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: $z-index-promo-text;
  text-align: right;

  @media (--medium-up) {
    & .flex-grid__item--50,
    & .flex-grid__item--33 {
      font-size: 0.9em;
    }
  }
}

.type-product__label {
  padding: 4px 12px;
  background-color: $colorBtnPrimary;
  color: $colorBtnPrimaryText;
  float: right;
  clear: both;
}

.type-product__label--secondary {
  background-color: $colorBody;
  color: $colorTextBody;
}
