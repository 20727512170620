$label-bottom-margin: 12px;

.variant-input-wrap {
  border: 0;
  padding: 0;
  margin: 0 0 -$label-bottom-margin;
  position: relative;

  & input {
    @mixin visuallyHidden;
  }

  & label {
    @mixin baseFontStack;
    position: relative;
    display: inline-block;
    font-weight: normal;
    padding: 7px 15px 7px;
    margin: 0 8px $label-bottom-margin 0;
    background-color: $colorBody;
    box-shadow: 0 0 0 1px $colorBorder;
    overflow: hidden;

    @nest [data-center-text="true"] & {
      margin: 0 4px $label-bottom-margin;
    }

    &.disabled {
      color: $colorBorder;
    }

    &.disabled:after {
      position: absolute;
      content: "";
      left: 50%;
      top: 0;
      bottom: 0;
      border-left: 1px solid;
      border-color: $colorBorder;
      transform: rotate(45deg);
    }
  }

  & input[type='radio']:focus + label {
    border-color: var(--colorTextBodyAlpha005);
    box-shadow: 0 0 0 1px $colorTextBody;
  }

  & input[type='radio']:checked + label {
    box-shadow: 0 0 0 2px $colorTextBody;
  }
}

.variant-input {
  display: inline-block;

  /*Firefox bug fix*/
  @nest select & {
    display: block;
  }
}

.variant-wrapper + .variant-wrapper {
  margin-top: 25px;
}

.no-js .variant-wrapper {
  display: none;
}

.variant-wrapper--dropdown {
  display: inline-block;
  max-width: 100%;
  margin-right: calc($gutter / 2);
  margin-bottom: 0;
}

.variant__label[for] {
  display: block;
  margin-bottom: 10px;
  cursor: default;
}

.variant__label-info {
  /*undo accent stack styles*/
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
}
