/*Inventory indicator*/
@keyframes inventory-pulse {
  0% {
    opacity: 0.5;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.icon--inventory {
  &:before,
  &:after {
    width: 9px;
    height: 9px;
    background: #54c63a;
    border-radius: 9px;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    margin: 8px;
  }

  &:before {
    animation: inventory-pulse 2s linear infinite;
  }

  @nest .inventory--low & {
    &:before,
    &:after {
      background: #F4AF29;
    }
  }
}
