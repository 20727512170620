a,
.text-link {
  color: $colorTextBody;
  text-decoration: none;
  background: transparent;

  &:hover {
    color: $colorTextBody;
  }
}

/*Force an input/button to look like a text link*/
.text-link {
  display: inline;
  border: 0 none;
  background: none;
  padding: 0;
  margin: 0;
}

/*Links in RTE*/
.rte a,
.shopify-policy__container a,
.shopify-email-marketing-confirmation__container a {
  color: $colorLink;
}
