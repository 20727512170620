::-webkit-input-placeholder {
   color: inherit;
   opacity: 0.5;
}

:-moz-placeholder {
   color: inherit;
   opacity: 0.5;
}

:-ms-input-placeholder {
   color: inherit;
   opacity: 0.5;
}

::-ms-input-placeholder {
   color: inherit;
   opacity: 1;
}

input,
textarea,
select {
  background-color: inherit;
  color: inherit;

  &[disabled],
  &.disabled {
    background-color: $disabledGrey;
    border-color: transparent;
  }

  &:active,
  &:focus {
    border: 1px solid;
    border-color: $colorTextBody;
  }
}

input[type="image"] {
  background-color: transparent;
}
