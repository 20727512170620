/* Age verification popup */
.age-verification-popup {
  & .rte {
    margin-top: 20px;
    margin-bottom: 20px;

    @media (--medium-up) {
      margin-bottom: 30px;
    }
  }

  & .age-verification-popup__btns-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}

.age-verification-popup__background-image-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
}

.age-verification-popup__background-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.age-verification-popup__content--active {
  opacity: 1;
  transition: opacity 1.5s ease-in;
}

.age-verification-popup__content--inactive,
.age-verification-popup__content--inactive *,
.age-verification-popup__content--inactive .btn {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
  padding: 0;
  border: 0;
  margin: 0;
  line-height: 0;
  font-size: 0;
}

.age-verification-popup__decline-content--inactive,
.age-verification-popup__decline-content--inactive *,
.age-verification-popup__decline-content--inactive .btn {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
  padding: 0;
  border: 0;
  margin: 0;
  line-height: 0;
  font-size: 0;
}

.age-verification-popup__decline-content--active {
  opacity: 1;
  transition: opacity 1.5s ease-in;
}

.age-verification-popup__content-wrapper {
  text-align: center;
  max-width: 520px;
  margin: 0 auto;
}
