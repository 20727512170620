.clearfix {
  @mixin clearfix;
}

html:not(.no-js):not(.tab-outline) *:focus {
  outline: none;
}

.is-transitioning {
  display: block !important;
  visibility: visible !important;
}

.display-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
  float: none;
}

@media (--medium-up) {
  .medium-up--display-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .medium-up--display-table-cell {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

.visually-hidden {
  @mixin visuallyHidden;
}

/*Keep dimensions but hide visually*/
.visually-invisible {
  opacity: 0 !important;
}

/*============================================================================
  Skip to content button
    - Overrides .visually-hidden when focused
==============================================================================*/
.skip-link:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  color: $colorTextBody;
  background-color: $colorBody;
  padding: 10px;
  opacity: 1;
  z-index: $zindexSkipToContent;
  transition: none;
}
