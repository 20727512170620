.background-media-text {
  position: absolute;
  width: 100%;
  overflow: hidden;
  background: $colorLargeImageBg;
  background-size: cover;
}

.background-media-text__video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 0;
}

.background-media-text__video {
  @media (--small) {
    width: 300%;
    left: -100%;
  }

  & iframe,
  & video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;

    @media (--medium-up) {
      height: 120%;
      max-width: none;
      left: -100%;
      height: 150%;
      width: 300%;
    }

    /*This min-width may need to change slightly depending on
    embedded video dimensions. Can do on a per-shop basis*/
    @media screen and (min-width: 1140px) {
      width: 100%;
      height: 300%;
      left: auto;
      top: -100%;
    }
  }
}

.video-interactable .background-media-text__video {
  & iframe,
  & video {
    pointer-events: auto;
  }
}

.background-media-text__inner {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-media-text__aligner {
  margin: calc($gutter * 2);
}

.background-media-text__text {
  text-align: left;
  font-size: 1.1em;
  background: $colorBody;
  padding: $gutter;
  width: 380px;

  @nest html[dir="rtl"] & {
    text-align: right;
  }

  @media (--small) {
    text-align: center;
  }
}

.background-media-text__text--framed {
  border: $textFrameMarginSmall solid;
  border-color: $colorBody;
  box-shadow: inset 0 0 0 2px $colorTextBody;

  @media (--medium-up) {
    border-width: $textFrameMarginLarge;
  }
}

.background-media-text__text .btn {
  margin-top: calc($gutter / 2);
}

@media (--medium-up) {
  .background-media-text--right .animation-cropper {
    float: right;
  }
}

/*Section height*/
.background-media-text__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (--small) {
  .background-media-text {
    position: relative;
  }
  .background-media-text__inner {
    position: relative;
  }
  .background-media-text__container,
  .background-media-text__video {
    position: relative;
    height: 240px;

    @nest .promo-video & {
      height: 550px;
    }
  }
  .background-media-text__aligner {
    margin: -6px 6px 6px;
  }
  .background-media-text__text {
    padding: calc($gutter * 0.75);
    width: auto;
  }
  .background-media-text.loading {
    &:before,
    &:after {
      top: 117px;
    }
  }
}

@media (--medium-up) {
  .background-media-text__spacer.background-media-text--450,
  .background-media-text--450,
  .background-media-text--450 .background-media-text__video {
    min-height: 450px;
  }
  .background-media-text__spacer.background-media-text--550,
  .background-media-text--550,
  .background-media-text--550 .background-media-text__video {
    min-height: 550px;
  }
  .background-media-text__spacer.background-media-text--650,
  .background-media-text--650,
  .background-media-text--650 .background-media-text__video {
    min-height: 650px;
  }
  .background-media-text__spacer.background-media-text--750,
  .background-media-text--750,
  .background-media-text--750 .background-media-text__video {
    min-height: 750px;
  }
}

[data-aos="background-media-text__animation"] .background-media-text__image,
[data-aos="background-media-text__animation"] .background-media-text__image svg,
[data-aos="background-media-text__animation"] .background-media-text__video {
  animation: zoom-fade 2.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  transition: none; /*fixes safari animation conflict*/

  @nest [data-disable-animations="true"] & {
    animation: none;
    opacity: 1;
  }
}

[data-aos="background-media-text__animation"].aos-animate .background-media-text__image,
[data-aos="background-media-text__animation"].aos-animate .background-media-text__image svg,
[data-aos="background-media-text__animation"].loaded.aos-animate .background-media-text__video {
  animation: zoom-fade 2.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  transition: none; /*fixes safari animation conflict*/

  @nest [data-disable-animations="true"] & {
    animation: none;
  }
}

@media (--medium-up) {
  [data-aos="background-media-text__animation"] .background-media-text__inner .animation-contents {
    opacity: 0;

    @nest .no-js & {
      animation: none;
      opacity: 1;
    }
  }

  [data-aos="background-media-text__animation"].loaded.aos-animate .background-media-text__inner .animation-contents {
    animation: rise-up 1s cubic-bezier(0.26, 0.54, 0.32, 1) 0.5s forwards;

    @nest [data-disable-animations="true"] & {
      animation: none;
      opacity: 1;
    }
  }
}
