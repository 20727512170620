$tagActiveIconSize: 16px;
$tagActiveIconSizeActive: 10px;

.tag-list__header {
  text-align: left;
}

.tag-list .tag-list {
  margin-left: calc($gutter / 2);
}

.tag-list a,
.tag-list label {
  cursor: pointer;
  display: block;
  line-height: 1.4;
}

.tag-list label:hover,
.tag:focus-within label {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.tag--active > a,
.tag--active > label {
  font-weight: 900;
}

.tag-list--active-tags:empty {
  display: none;
}

.tag-list--checkboxes {
  padding-bottom: 6px;

  & a {
    position: relative;
    padding-left: 25px;
    font-size: calc(var(--typeBaseSize) - 2px);
    font-size: max(calc(var(--typeBaseSize) - 2px), 14px);

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      border: 1px solid;
      border-color: $colorBorder;
      height: $tagActiveIconSize;
      width: $tagActiveIconSize;
    }
  }
}

.tag-list--checkboxes .tag--active a:after,
.tag--active .tag__checkbox:after,
input:checked ~ .tag__checkbox:after {
  background-color: $colorTextBody;
  border-color: $colorTextBody;
}

/*Remove tags (top of sidebar)*/
.tag--remove {
  position: relative;

  & a {
    text-align: left;
  }

  /*X icon sits over button, not in it*/
  & .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: $colorBtnPrimaryText;
  }
}

/*Color swatches in sidebar*/
.tag-list--swatches {
  margin-top: 2px;
  margin-left: -2px;

  @nest .drawer & {
    margin-left: -2px;
  }

  & li {
    display: inline-block;
  }
}

label.tag__checkbox-wrapper {
  display: flex;
}

.tag__checkbox {
  position: relative;
  padding-left: 25px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    border: 1px solid;
    border-color: var(--colorBorder);
    height: $tagActiveIconSize;
    width: $tagActiveIconSize;
  }

  &:after {
    height: $tagActiveIconSizeActive;
    width: $tagActiveIconSizeActive;
    left: 3px;
  }
}

.tag__input {
  position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
