@import '../global/variables-root.css.liquid';

/*Model viewer controls*/
.shopify-model-viewer-ui {
  & .shopify-model-viewer-ui__controls-area {
    opacity: 1; /*always show controls*/
    background: $colorBody;
    border-color: var(--colorTextBodyAlpha005);
    border-radius: 50px;
  }

  & .shopify-model-viewer-ui__button {
    color: $colorTextBody;
  }

  & .shopify-model-viewer-ui__button--control {
    &:hover {
      color: $colorTextBody;
    }

    &:active,
    &.focus-visible:focus {
      color: $colorTextBody;
      background-color: var(--colorTextBodyAlpha005);
    }

    &:not(:last-child):after {
      border-color: var(--colorTextBodyAlpha005);
    }
  }

  & .shopify-model-viewer-ui__button--poster {
    background-color: $colorTextBody;
    color: $colorBody;
    border-radius: 100%;
    border: 1px solid;
    border-color: var(--colorBodyAlpha05);

    &:hover,
    &:focus {
      color: $colorBody;
    }
  }
}

/*View in space button*/
.product-single__view-in-space {
  display: block;
  color: $colorTextBody;
  background-color: var(--colorTextBodyAlpha008);
  width: 80%;
  width: calc(80% - 4px); /*4px = right margin on product-image-main*/
  margin: 40px 10% 10px;
  padding: 5px 10px 10px;

  &[data-shopify-xr-hidden] {
    display: none;
  }
}

.product-single__view-in-space-text {
  @mixin baseSmallFontStack;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

/*Model viewer container*/
.shopify-model-viewer-ui,
.shopify-model-viewer-ui model-viewer {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shopify-model-viewer-ui__button[hidden] {
  display: none;
}

.product-single__close-media {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
