.color-swatch {
  position: relative;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  margin: 0 4px 4px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  box-shadow: 0 0 0 1px $colorBorder;
  transition: box-shadow 0.2s ease;

  @nest [data-swatch_style='round'] & {
    border-radius: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  &:before {
    border: 3px solid;
    border-color: $colorBody;

    /*Try and avoid over-pixelated borders on some screens*/
    @nest [data-swatch_style='round'] & {
      border-radius: 100%;
      border-width: 4px;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
    }
  }
}

/*Product grid direct variant links and
sidebar color swatches*/
a.color-swatch:hover,
.tag:not(.tag--active) label:hover .color-swatch:hover,
.tag:focus-within .color-swatch {
  box-shadow: 0 0 0 1px $colorTextBody;
}

.tag--active .color-swatch {
  box-shadow: 0 0 0 2px $colorTextBody;
}

.color-swatch--small {
  width: $colorSwatchCollectionSize;
  height: $colorSwatchCollectionSize;

  @media (--medium-up) {
    width: $colorSwatchCollectionSizeLarge;
    height: $colorSwatchCollectionSizeLarge;
  }

  &:before {
    border: 2px solid;
    border-color: $colorBody;

    @nest [data-swatch_style='round'] & {
      border-width: 3px;
    }
  }
}

.tag--swatch {
  display: inline-block;

  & > label {
    margin-bottom: 0;
  }
}

.tag--show-label {
  width: 100%;

  label {
    display: flex;
    align-items: center;
  }

  .tag__text {
    display: block !important;
    margin-left: 0.5rem;
  }
}

.color-swatch--filter {
  width: $colorSwatchSidebarSize;
  height: $colorSwatchSidebarSize;
}

.tag--active .color-swatch--filter:hover:after {
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  bottom: 0;
  border-left: 1px solid;
  border-color: $colorBorder;
  transform: rotate(45deg);
}
