.modal {
  display: none;
  bottom: 0;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $zindexModal;
  color: $colorModalText;
  align-items: center;
  justify-content: center;

  &.modal--quick-shop {
    align-items: flex-start;
  }

  & a,
  & a:hover {
    color: inherit;
  }

  & .btn:not([disabled]):not(.btn--secondary):not(.btn--body),
  & .btn:not([disabled]):not(.btn--secondary):not(.btn--body):hover {
    color: $colorBtnPrimaryText;
  }

  @nest .modal-open & {
    & .modal__inner {
      animation: modal-open 0.5s forwards;
    }

    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $colorModalBg;
      animation: overlay-on 0.5s forwards;
      cursor: pointer;
    }
  }

  @nest .modal-closing & {
    & .modal__inner {
      animation: modal-closing 0.5s forwards;
    }

    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $colorModalBg;
      animation: overlay-off 0.5s forwards;
    }
  }

  @nest .modal-open--solid &:before {
    background-color: $colorModalBg;
  }
}

.modal--solid {
  @nest .modal-open & {
    &:before {
      background-color: #000;
      animation: full-overlay-on 0.5s forwards;
    }
  }

  @nest .modal-closing & {
    &:before {
      background-color: #000;
      animation: full-overlay-off 0.5s forwards;
    }
  }
}

.modal--is-closing {
  display: flex !important; /*override .is-transitioning styles*/
  overflow: hidden;
}

.modal--is-active {
  display: flex !important; /*override .is-transitioning styles*/
  overflow: hidden;
}

/*Class on body element when modal open.
Only medium-up because iOS jumps to top otherwise*/
@media (--medium-up) {
  .modal-open {
    overflow: hidden;
  }
}

.modal__inner {
  transform-style: preserve-3d;
  flex: 0 1 auto;
  margin: calc($gutter / 2);
  max-width: calc(100% - calc($gutter / 2));
  display: flex;
  align-items: center;

  @media (--medium-up) {
    margin: 40px;
    max-width: calc(100% - 80px);
  }

  @nest .modal--square & {
    background-color: $colorBody;
    color: $colorTextBody;
    box-shadow: 0 15px 45px rgb(0, 0, 0, 0.1);
  }

  & img {
    display: block;
    max-height: 90vh;
  }
}

/*No max height on product images*/
.modal__inner {
  & .image-wrap img {
    max-height: none;
  }
}

.modal__centered {
  position: relative;
  flex: 0 1 auto;
  min-width: 1px; /*ie10 thing*/
  max-width: 100%;
}

.modal__centered-content {
  @nest .modal--square & {
    max-height: 80vh;
    padding: calc($gutter * .75);
    min-width: 200px;
    min-height: 200px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--medium-up) {
      padding: calc($gutter * 1.5);
      max-height: 90vh;
      max-width: 1200px;
    }
  }
}

.modal__close {
  border: 0;
  padding: calc($gutter / 2);
  position: fixed;
  top: 0;
  right: 0;
  color: $colorModalText;

  @media (--medium-up) {
    padding: $gutter;
  }

  & .icon {
    width: 28px;
    height: 28px;
  }

  &:hover,
  &:focus {
    color: $colorModalText;
  }

  @nest .modal--square & {
    position: absolute;
    color: $colorTextBody;
    padding: calc($gutter / 3);

    &:hover,
    &:focus {
      color: $colorTextBody;
    }
  }
}

/*Ajax page adjustments*/
.modal {
  & .page-content,
  & .page-width {
    padding: 0;
  }
}

/*Google-friendly non intrusive mobile modal*/
.popup-cta {
  margin-bottom: calc($gutter / 2);
}

@media (--small) {
  .modal--mobile-friendly {
    top: auto;
    bottom: 0;
    overflow: auto;

    &.modal--square .modal__centered-content {
      padding: 40px 20px;
    }

    &.modal--is-active {
      overflow: auto;
    }

    @nest .modal-open & {
      &:before {
        display: none;
      }
    }

    & .modal__inner {
      margin: 0;
    }

    & .h1 {
      padding-right: 25px;
    }

    & input {
      font-size: 16px !important;
    }

    & .text-close {
      display: none;
    }
  }
}
