.site-header {
  position: relative;
  padding: 7px 0;
  background: $colorNav;

  @media (--medium-up) {
    padding: 20px 0;

    @nest .toolbar + .header-sticky-wrapper & {
      border-top: 1px solid;
      border-top-color: var(--colorHeaderTextAlpha01);
    }
  }
}

.site-header--stuck {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transform: translate3d(0, -100%, 0);
  transition: none;
  z-index: $z-index-stuck-header;

  /*Rearrangement for when top search drawer is open so nav is above overlay*/
  @nest .js-drawer-open--search & {
    z-index: $z-index-header-search;
  }

  @media (--medium-up) {
    padding: 10px 0;
  }
}

/*Disable sticky header on short and wide screens*/
@media screen and (min-width: 700px) and (max-height: 550px) {
  .site-header--stuck {
    position: static;
  }
}

.site-header--opening {
  transform: translate3d(0, 0, 0);
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.site-header__logo {
  position: relative;
  margin: calc($gutter / 3) 0;
  display: block;
  font-size: 30px; /*when no image*/
  z-index: calc($zindexNavDropdowns + 1);

  @media (--medium-up) {
    @nest .text-center & {
      padding-right: 0;
      margin: calc($gutter / 3) auto;
    }
  }

  @nest .header-layout[data-logo-align="center"] & {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  & a {
    max-width: 100%;
  }

  & a,
  & a:hover {
    text-decoration: none;
  }

  & img {
    display: block;

    @nest .site-header & {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.site-header__logo-link {
  display: flex;
  position: relative;
  align-items: center;
  color: $colorNavText;

  &:hover {
    color: $colorNavText;
  }

  @media (--small) {
    margin: 0 auto;
  }
}

/*Sticky header on top of image/slideshow*/
.header-sticky-wrapper {
  position: relative;
  z-index: $z-index-header-search;

  /* Update when drawer is open */
  @nest .js-drawer-open:not(.js-drawer-open--search) & {
    z-index: $z-index-sticky-header;
  }
}

.header-wrapper--sticky {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-sticky-header;
  background: none;
  background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%);

  & .site-header:not(.site-header--stuck) {
    background: none;
  }

  /*Rearrangement for when top search drawer is open so nav is above overlay*/
  @nest .js-drawer-open--search & {
    z-index: $z-index-header-search;
  }
}
