.countdown__content {
  background-color: var(--countdown-background-color);
  color: var(--countdown-text-color);
}

.countdown__block--button {
  & .btn.btn--secondary {
    color: var(--accent-color);
    /*
      Impulse secondary btn is connected to
      the theme setting for border color
    */
  }
}
