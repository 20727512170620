.logo-bar {
  text-align: center;
  margin-bottom: -$gutter;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.logo-bar__item {
  flex: 0 1 110px;
  vertical-align: middle;
  margin: 0 calc($gutter / 2) calc($gutter / 1.5);

  @media (--medium-up) {
    flex: 0 1 160px;
    margin: 0 calc($gutter / 1.5) $gutter;
  }
}

.logo-bar__image {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo-bar__link {
  display: block;
}

[data-aos="logo__animation"] .logo-bar__item {
  opacity: 0;
}

[data-aos="logo__animation"].aos-animate .logo-bar__item {
  animation: fade-in 0.5s ease 0s forwards;
}
[data-aos="logo__animation"].aos-animate .logo-bar__item:nth-child(2) {
  animation-delay: 0.2s;
}
[data-aos="logo__animation"].aos-animate .logo-bar__item:nth-child(3) {
  animation-delay: 0.4s;
}
[data-aos="logo__animation"].aos-animate .logo-bar__item:nth-child(4) {
  animation-delay: 0.6s;
}
[data-aos="logo__animation"].aos-animate .logo-bar__item:nth-child(5) {
  animation-delay: 0.8s;
}
[data-aos="logo__animation"].aos-animate .logo-bar__item:nth-child(6) {
  animation-delay: 1.0s;
}
[data-aos="logo__animation"].aos-animate .logo-bar__item:nth-child(7) {
  animation-delay: 1.2s
}
[data-aos="logo__animation"].aos-animate .logo-bar__item:nth-child(8) {
  animation-delay: 1.4s;
}
[data-aos="logo__animation"].aos-animate .logo-bar__item:nth-child(9) {
  animation-delay: 1.6s;
}
[data-aos="logo__animation"].aos-animate .logo-bar__item:nth-child(10) {
  animation-delay: 1.8s;
}
