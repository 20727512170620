$shareButtonHeight: 18px;

.social-sharing {
  @mixin baseTextCenter;

  & .icon {
    height: $shareButtonHeight;
    width: $shareButtonHeight;
  }
}

.social-sharing__link {
  display: inline-block;
  color: $colorTextBody;
  border-radius: 2px;
  margin: 0 18px 0 0;
  text-decoration: none;
  font-weight: normal;

  &:last-child {
    margin-right: 0;
  }
}

.social-sharing__title {
  @mixin baseSmallFontStack;
  display: inline-block;
  vertical-align: middle;
  padding-right: 15px;
  padding-left: 3px;
}
