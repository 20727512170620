.header-layout {
  display: flex;
  justify-content: space-between;
}

.header-layout--center {
  align-items: center;
}

.header-item {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}

.header-item--logo {
  flex: 0 0 auto;
}

.header-item--icons {
  justify-content: flex-end;
  flex: 0 1 auto;
}

/*Same default logo and icon container size, logo block width overrides*/
.header-layout--left-center {
  & .header-item--logo,
  & .header-item--icons {
    flex: 0 0 200px;
    max-width: 50%;

    @media (--medium-up) {
      min-width: 130px; /*approx width of 2 icons*/
    }
  }
}

.header-layout[data-logo-align="center"] {
  & .header-item--logo {
    @media (--medium-up) {
      margin: 0 30px;
    }
  }

  & .header-item--navigation,
  & .header-item--icons {
    flex: 1 1 130px; /*aprox width of 2 icons*/
  }
}

.header-layout[data-logo-align="left"] {
  & .site-header__logo {
    margin-right: calc($gutter / 3);
  }
}

.header-item--logo-split {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;

  & .header-item:not(.header-item--logo) {
    text-align: center;
    flex: 1 1 20%;
  }
}

.header-item--split-left {
  justify-content: flex-end;
}

/*Icon alignment*/
.header-item--left {
  & .site-nav {
    margin-left: -$siteNavIconPadding;

    @media (--small) {
      margin-left: calc(-$siteNavItemPadding / 2);
    }
  }
}

.header-item--icons {
  & .site-nav {
    margin-right: -$siteNavIconPadding;

    @media (--small) {
      margin-right: calc(-$siteNavItemPadding / 2);
    }
  }
}
