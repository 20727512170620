@mixin animation-delay 20;
@mixin appear-delay 20;

.animation-cropper {
  overflow: hidden;
  display: inline-flex;
}

.image-wrap {
  background: $colorSmallImageBg;
  overflow: hidden;
  position: relative;
}

.image-wrap {
  & svg,
  & .animate-me {
    opacity: 0;

    @nest .no-js & {
      opacity: 1;
    }
  }
}

.aos-animate .image-wrap {
  & svg,
  & .animate-me {
    animation: fade-in 1s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  }
}

.image-wrap img {
  @nest [data-aos="row-of-3"].aos-animate:nth-child(3n+2) & {
    animation-delay: 150ms;
  }

  @nest [data-aos="row-of-3"].aos-animate:nth-child(3n+3) & {
    animation-delay: 300ms;
  }

  @nest [data-aos="row-of-4"].aos-animate:nth-child(4n+2) & {
    animation-delay: 120ms;
  }

  @nest [data-aos="row-of-4"].aos-animate:nth-child(4n+3) & {
    animation-delay: 240ms;
  }

  @nest [data-aos="row-of-4"].aos-animate:nth-child(4n+4) & {
    animation-delay: 360ms;
  }

  @nest [data-aos="row-of-5"].aos-animate:nth-child(5n+2) & {
    animation-delay: 75ms;
  }

  @nest [data-aos="row-of-5"].aos-animate:nth-child(5n+3) & {
    animation-delay: 150ms;
  }

  @nest [data-aos="row-of-5"].aos-animate:nth-child(5n+4) & {
    animation-delay: 225ms;
  }

  @nest [data-aos="row-of-5"].aos-animate:nth-child(5n+5) & {
    animation-delay: 300ms;
  }

  @nest [data-aos="row-of-6"].aos-animate:nth-child(6n+2) & {
    animation-delay: 50ms;
  }

  @nest [data-aos="row-of-6"].aos-animate:nth-child(6n+3) & {
    animation-delay: 100ms;
  }

  @nest [data-aos="row-of-6"].aos-animate:nth-child(6n+4) & {
    animation-delay: 150ms;
  }

  @nest [data-aos="row-of-6"].aos-animate:nth-child(6n+5) & {
    animation-delay: 200ms;
  }

  @nest [data-aos="row-of-6"].aos-animate:nth-child(6n+6) & {
    animation-delay: 250ms;
  }
}

.collection-image {
  @nest [data-aos="row-of-3"].aos-animate:nth-child(3n+2) & {
    animation-delay: 150ms;
  }

  @nest [data-aos="row-of-3"].aos-animate:nth-child(3n+3) & {
    animation-delay: 300ms;
  }

  @nest [data-aos="row-of-4"].aos-animate:nth-child(4n+2) & {
    animation-delay: 120ms;
  }

  @nest [data-aos="row-of-4"].aos-animate:nth-child(4n+3) & {
    animation-delay: 240ms;
  }
  @nest [data-aos="row-of-4"].aos-animate:nth-child(4n+4) & {
    animation-delay: 360ms;
  }

  @nest [data-aos="row-of-5"].aos-animate:nth-child(5n+2) & {
    animation-delay: 75ms;
  }

  @nest [data-aos="row-of-5"].aos-animate:nth-child(5n+3) & {
    animation-delay: 150ms;
  }

  @nest [data-aos="row-of-5"].aos-animate:nth-child(5n+4) & {
    animation-delay: 225ms;
  }

  @nest [data-aos="row-of-5"].aos-animate:nth-child(5n+5) & {
    animation-delay: 300ms;
  }

  @nest [data-aos="row-of-6"].aos-animate:nth-child(6n+2) & {
    animation-delay: 50ms;
  }

  @nest [data-aos="row-of-6"].aos-animate:nth-child(6n+3) & {
    animation-delay: 100ms;
  }

  @nest [data-aos="row-of-6"].aos-animate:nth-child(6n+4) & {
    animation-delay: 150ms;
  }

  @nest [data-aos="row-of-6"].aos-animate:nth-child(6n+5) & {
    animation-delay: 200ms;
  }

  @nest [data-aos="row-of-6"].aos-animate:nth-child(6n+6) & {
    animation-delay: 250ms;
  }
}

.loading:after,
.loading:before,
.unload:before,
.unload:after {
  content: '';
  position: absolute;
  width: 100px;
  height: 3px;
  background: $colorBody;
  left: 50%;
  top: 50%;
  margin: -1px 0 0 -50px;
  z-index: $z-index-loader;
  opacity: 0;

  @nest .no-js & {
    display: none;
  }
}

.loading:before,
.unload:before {
  background: $colorTextBody;
  opacity: 1;
}

.loading:after,
.unload:after {
  opacity: 0;
  animation: preloading 0.5s ease 0.3s infinite;
}

.loading--delayed:before {
  animation-delay: 0.8s !important;
  animation-duration: 1s !important;
}

.loading--delayed:after {
  animation-delay: 1.3s !important;
}

/*Delay animations, used in drawers*/
.appear-animation {
  opacity: 0;
  transform: translateY(60px);

  @nest [data-disable-animations="true"] & {
    opacity: 1;
    transform: none;
  }
}

.js-drawer-open .appear-animation {
  opacity: 1;
  transform: translateY(0px);
}

.js-drawer-closing .appear-animation {
  transition-duration: 0s;
  transition-delay: 0.5s;
}
