.announcement-bar {
  font-size: calc(var(--typeBaseSize) * 0.75);
  position: relative;
  text-align: center;
  background-color: $colorAnnouncement;
  color: $colorAnnouncementText;
  padding: 10px 0;
  border-bottom-color: $colorBorder; /*only when same bg as body*/
  @media (--medium-up) {
    font-size: calc(var(--typeBaseSize) * 0.85);
  }

  & a,
  & a:visited {
    color: $colorAnnouncementText;

    &:hover,
    &:active {
      color: $colorAnnouncementText;
    }
  }
}

.announcement-slider__slide {
  position: relative;
  overflow: hidden;
  padding: 0 5px;
  width: 100%;
}

.announcement-link {
  display: block;
  color: $colorAnnouncementText;

  &:hover,
  &:active {
    color: $colorAnnouncementText;
  }
}

.announcement-text {
  font-weight: bold;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 0.9em;
}

.announcement-link-text {
  display: block;

  @nest .announcement-link & {
    text-decoration: underline;
  }

  & a {
    color: inherit;
  }
}

@media (--medium-up) {
  /*1 slide at a time, inline text*/
  .announcement-slider[data-compact="true"] {
    & .announcement-slider__slide {
      display: none;

      &:first-child {
        display: block;
      }
    }
    & .announcement-text,
    & .announcement-link-text {
      display: inline;
    }

    & .announcement-text + .announcement-link-text {
      padding-left: 5px;
    }

    &.flickity-enabled .announcement-slider__slide {
      display: block;
    }
  }

  /*Up to 3 beside eachother*/
  .announcement-slider[data-compact="false"] {
    display: flex;

    & .announcement-slider__slide {
      flex: 1 1 33%;
    }
  }
}

@media (--small) {
  .announcement-slider {
    & .announcement-slider__slide {
      display: none;

      &:first-child {
        display: block;
      }
    }

    &.flickity-enabled .announcement-slider__slide {
      display: block;
    }
  }
}
