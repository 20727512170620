.map-section {
  position: relative;
  height: 650px;
  width: 100%;
  overflow: hidden;

  @media (--medium-up) {
    height: 500px;
  }

  & .page-width {
    height: 100%;
  }
}

.map-section--load-error {
  height: auto;
}

.map-onboarding {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 0;
}

.map-section__overlay-wrapper {
  position: relative;
  height: 100%;
}

.map-section__overlay {
  position: relative;
  display: inline-block;
  background-color: $colorBody;
  padding: $gutter;
  margin: $gutter;
  width: 100%;
  max-width: calc(100% - $gutter*2);
  z-index: 3;

  @media (--medium-up) {
    position: absolute;
    left: $gutter;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    width: 30%;
  }

  @nest .map-section--load-error & {
    position: static;
    transform: translateY(0);
  }
}

.map-section__link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

/*Optically center map in visible area with
extended height/widths and negative margins*/
.map-section__container {
  position: absolute !important; /*api will inline relative sometimes*/
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  margin-bottom: -50%;

  @media (--medium-up) {
    width: 130%;
    height: 100%;
    margin: 0 -30% 0 0;
  }

  @nest .display-map--false.bg-image--true &  {
    width: 100%;
    height: 100%;

    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

[data-aos="map-section__animation"] .map-section__container {
  animation: fade-out 0.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
  opacity: 0;
}

[data-aos="map-section__animation"].aos-animate .map-section__container {
  animation: zoom-fade 2.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
}
