.input-group {
  display: flex;

  & .input-group-field:first-child,
  & .input-group-btn:first-child .btn,
  & input[type="hidden"]:first-child + .input-group-field {
    border-radius: $input-radius 0 0 $input-radius;
  }

  & .input-group-field:last-child {
    border-radius: 0 $input-radius $input-radius 0;
  }

  & .input-group-btn:first-child .btn,
  & input[type="hidden"]:first-child + .input-group-btn .btn {
    border-radius: var(--buttonRadius) 0 0 var(--buttonRadius);
  }

  & .input-group-btn:last-child .btn {
    border-radius: 0 var(--buttonRadius) var(--buttonRadius) 0;
  }

  & input {
    /*Nasty Firefox hack for inputs http://davidwalsh.name/firefox-buttons*/
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin-top: -1px;
      margin-bottom: -1px;
    }
  }
}

.input-group-field {
  flex: 1 1 auto;
  margin: 0;
  min-width: 0;
}

.input-group-btn {
  flex: 0 1 auto;
  margin: 0;
  display: flex; /*forces .btn full height*/

  & .icon {
    vertical-align: initial;
  }

  @nest [data-button_style='angled'] & {
    position: relative;
    left: -8px;
  }
}
