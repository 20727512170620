@define-mixin visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@define-mixin overlay $zindex: auto {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex;
}

@define-mixin baseFontStack {
  font-family: var(--typeBasePrimary), var(--typeBaseFallback);
  font-size: calc(var(--typeBaseSize) * 0.92);
  letter-spacing: var(--typeBaseSpacing);
  line-height: var(--typeBaseLineHeight);

  @media (--medium-up) {
    font-size: var(--typeBaseSize);
  }
}

@define-mixin baseSmallFontStack {
  font-size: calc(var(--typeBaseSize) * 0.85);
}

@define-mixin baseExtraSmallFontStack {
  font-size: max(calc(var(--typeBaseSize) * 0.7), 12px);
}

@define-mixin headingTextCenter {
  @nest [data-type_headers_align_text="true"] & {
    text-align: center;
  }
}

@define-mixin baseTextCenter {
  @nest [data-center-text="true"] & {
    text-align: center;
  }
}

@define-mixin headingFontStack {
  font-family: var(--typeHeaderPrimary), var(--typeHeaderFallback);
  font-weight: var(--typeHeaderWeight);
  letter-spacing: var(--typeHeaderSpacing);
  line-height: var(--typeHeaderLineHeight);

  @nest [data-type_header_capitalize="true"] & {
    text-transform: uppercase;
  }
}

@define-mixin accentFontStack {
  text-transform: uppercase;
  letter-spacing: 0.3em;
}

@define-mixin accentFontSmallSize {
  font-size: 0.8em;
}


@define-mixin heroScrim {
  background-color: $colorImageOverlay;
  opacity: $colorImageOverlayOpacity;
}

@define-mixin heroRadial {
  background: radial-gradient(rgba(0, 0, 0, $colorImageOverlayTextShadow) 0%, rgba(0, 0, 0, 0) 60%);
  margin: -100px -200px -100px -200px;
}
