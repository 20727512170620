.store-availability {
  display: flex;
  justify-content: space-around;

  & .icon {
    margin: 6px 0 0;
    width: 12px;
    height: 12px;
  }

  & .icon-in-stock {
    fill: $successGreen;
  }

  & .icon-out-of-stock {
    fill: $errorRed;
  }

  & + .store-availability {
    margin-top: 20px;
  }
}

.store-availability__info {
  flex: 0 1 90%;
  text-align: left;
  margin-left: 10px;

  & > div {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
    text-decoration: underline;
  }
}

.store-availability__small {
  font-size: 0.8em;

  & a {
    display: block;
    margin-top: 10px;
  }
}

.store-availability__small--link {
  @nest .modal & {
    display: none;
  }
}
