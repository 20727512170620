.password-page__header__grid {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
}

.password-page__logo {
  & h1 {
    margin-bottom: 0;
  }

  & .logo {
    max-width: 100%;
  }
}

.password-login {
  display: block;
  margin: 0 auto;
  padding: calc($gutter / 4) 15px;
}

.password-form {
  margin-bottom: 1em;
}

.password__lock .icon {
  position: relative;
  top: -2px;
  margin-right: 4px;
  width: 24px;
  height: 24px;
}

.icon-shopify-logo {
  width: 60px;
  height: 20px;
}

#LoginModal .modal__close {
  @media (--small) {
    padding: 20px;
  }
}

#LoginModal .modal__inner {
  background: $colorBody;
  color: $colorTextBody;
  padding: 30px;
  @media (--small) {
    margin-bottom: 40vh;
  }
}

.password-admin-link {
  margin: 0;

  & a {
    border-bottom: 2px solid !important;
    border-bottom-color: $colorBorder !important;
  }
}
