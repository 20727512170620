$max-height-disclosure: 60vh;
$min-height-disclosure: 92px;

.disclosure {
  position: relative;
}

.disclosure__toggle {
  white-space: nowrap;
}

.disclosure-list {
  background-color: $colorBody;
  color: $colorTextBody;
  bottom: 100%;
  padding: 10px 0;
  margin: 0;
  position: absolute;
  display: none;
  min-height: $min-height-disclosure;
  max-height: $max-height-disclosure;
  overflow-y: auto;
  border-radius: $input-radius;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.09);

  & a {
    color: currentColor;
  }
}

.disclosure-list--down {
  bottom: auto;
  top: 100%;
  z-index: $z-index-disclosure-header;
}

.disclosure-list--left {
  right: 0;
}

.disclosure-list--visible {
  display: block;
}

.disclosure-list__item {
  white-space: nowrap;
  padding: 5px 15px 4px;
  text-align: left;
}

.disclosure-list__option {
  &:focus,
  &:hover {
    & .disclosure-list__label {
      text-decoration: underline;
    }
  }
}

.disclosure-list__item--current {
  & .disclosure-list__label {
    text-decoration: underline;
  }
}

.disclosure-list__label {
  display: inline-block;
  vertical-align: middle;
  text-underline-offset: 0.2rem;

  @nest [data-disclosure-currency] & {
    padding-left: 10px
  }

  & span {
    border-bottom: 2px solid transparent;

    @nest .is-active & {
      border-bottom: 2px solid currentColor;
    }
  }
}
