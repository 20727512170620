image-compare {
  position: relative;
  display: block;
  width: 100%;
  background: #222;
  overflow: hidden;
}

.comparison__draggable {
  width: 50%;
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  overflow: hidden;
  z-index: 2;
}

.comparison__image {
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
  display: block;
  user-select: none;
  opacity: 1;
  animation: none;
}

.comparison__image-wrapper {
  width: 100%;
  height: 100%;
}

.comparison__button {
  width: 64px;
  height: 64px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-32px, -50%);
  border: 0;
  cursor: pointer;
  z-index: 3;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: 3px solid #fff;
  padding: 0;

  @nest .comparison--style-minimal & {
    border: 0;
    background: transparent;
    width: auto;
    transform: translate(-56px, -50%);

    & svg {
      margin: 0 20px;

      @supports (-webkit-touch-callout: none) {
        position: absolute;

        &.icon-chevron-left {
          left: 2px;
          width: 9px;
        }

        &.icon-chevron-right {
          right: 2px;
          width: 9px;
        }
      }
    }
  }

  @nest .comparison--style-classic & {
    @media (--small) {
      width: 48px;
      height: 48px;
      transform: translate(-24px, -50%);
    }
  }


  & svg {
    width: 12px;
    height: auto;
    pointer-events: none;
    margin: 0 5px;
  }

  &::before {
    content: "";
    width: 2px;
    position: absolute;
    background-color: #fff !important;
  }

  &::after {
    content: '';
    width: 2px;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #fff !important;
  }
}
