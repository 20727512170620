predictive-search {
  & .search__results {
    @media (--small) {
      width: calc(100% + 30px);
      margin-left: -20px;
      margin-right: -10px;
    }
  }

  & form {
    @media (--small) {
      margin: 0 10px 0 20px;
    }
  }
}
