html {
  touch-action: manipulation;
}

html[dir="rtl"] {
  direction: rtl;
}

html,
body {
  background-color: $colorBody;
  color: $colorTextBody;
}

@media (--small) {
  .supports-touch.lock-scroll {
    overflow: hidden;

    /* iOS specific fix */
    @supports (-webkit-touch-callout: none) {
      overflow: initial;
    }
  }
}

.page-width {
  max-width: $page-width;
  margin: 0 auto;
}

.page-width,
.page-full {
  padding: 0 $page-width-gutter-small;

  @media (--medium-up) {
    padding: 0 $page-width-gutter-large;
  }
}

.page-width--narrow {
  max-width: $page-width-narrow;
}

.page-width--tiny {
  max-width: 450px;
}

@media (--small) {
  .page-width--flush-small {
    padding: 0;
  }
}

.page-content,
.shopify-policy__container,
.shopify-email-marketing-confirmation__container {
  padding-top: $indexSectionMarginSmall;
  padding-bottom: $indexSectionMarginSmall;

  @media (--medium-up) {
    padding-top: $indexSectionMarginLarge;
    padding-bottom: $indexSectionMarginLarge;
  }
}

.shopify-email-marketing-confirmation__container {
  text-align: center;
}

.page-content--top,
.page-content--with-blocks {
  padding-bottom: 0;
}

.page-content--bottom {
  padding-top: 0;
}

.main-content {
  display: block;
  min-height: 300px;
  @media (--medium-up) {
    min-height: 700px;
  }

  @nest .template-challange & {
    min-height: 0;
  }
}

hr,
.hr--small,
.hr--medium,
.hr--large {
  height: 1px;
  border: 0;
  border-top: 1px solid;
  border-top-color: $colorBorder;
}

.hr--small {
  margin: 15px auto;
}

.hr--medium {
  margin: 25px auto;

  @media (--medium-up) {
    margin: 35px auto;
  }
}

.hr--large {
  margin: $gutter auto;

  @media (--medium-up) {
    margin: calc($gutter * 1.5) auto;
  }

  @nest .page-blocks + &,
  .page-blocks + [data-section-type="recently-viewed"] & {
    margin-top: 0;
  }
}

.hr--clear {
  border: 0;
}

/*============================================================================
  Responsive tables, defined with .table--responsive on table element.
==============================================================================*/
.table--responsive {
  @media (--small) {
    & thead {
      display: none;
    }

    & tr {
      display: block;
    }

    & tr,
    & td {
      float: left;
      clear: both;
      width: 100%;
    }

    & th,
    & td {
      display: block;
      text-align: right;
      padding: 15px;
    }

    & td:before {
      content: attr(data-label);
      float: left;
      font-size: 12px;
      padding-right: 10px;
    }
  }
}

@media (--small) {
  .table--small-hide {
    display: none !important;
  }

  .table__section + .table__section {
    position: relative;
    margin-top: 10px;
    padding-top: 15px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      border-bottom: 1px solid;
      border-bottom-color: $colorBorder;
    }
  }
}

details summary::-webkit-details-marker {
  display:none;
}
