/* Cart page layout */
@media (--medium-up) {
  .cart__page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .cart__page-col:first-child {
    flex: 1 1 60%;
    padding-right: 100px;
  }

  .cart__page-col:last-child {
    position: sticky;
    top: 10%;
    flex: 0 1 35%;
    padding: 30px;

    &:after {
      @mixin overlay -1;
      background-color: var(--colorTextBody);
      opacity: 0.03;
    }
  }
}

.cart__item {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid;
  border-bottom-color: var(--colorBorder);

  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.cart__image {
  flex: 0 0 150px;
  margin-right: var(--grid-gutter);
  position: relative;

  & a {
    position: relative;
    display: block;
    width: 100%;
    height: 100px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    display: block;
  }

  /*Smaller image in drawer*/
  @nest .drawer & {
    flex: 0 0 100px;
  }
}

.cart__item-details {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
}

.cart__item-title {
  flex: 1 1 100%;

  @nest .drawer & {
    @mixin baseSmallFontStack;
  }
}

.cart__item-sub {
  flex: 1 1 100%;
  display: flex;
  justify-content: space-between;
  line-height: 1;

  & > div:first-child {
    margin-right: 10px;
  }
}

.cart__item-row {
  margin-bottom: 20px;
}

.cart__remove {
  & a {
    display: inline-block;
    margin-top: 10px;
  }

  /*Hide in drawer*/
  @nest .drawer & {
    display: none;
  }
}

.cart__checkout {
  width: 100%;
}

.cart__item-name {
  display: block;
  font-size: calc(var(--typeBaseSize) + 1px);
  margin-bottom: 8px;
}

.cart__item--variants {
  margin-bottom: 10px;

  & span {
    font-weight: bold;
  }
}

.cart__price {
  display: block;
}

.cart__price--strikethrough {
  text-decoration: line-through;
}

.cart__discount {
  color: var(--colorTextSavings);
}

.cart__terms {
  display: flex;
  align-items: center;
  justify-content: center;

  & label {
    margin-bottom: 0;
    text-transform: none;
    letter-spacing: 0;
  }
}

.cart__checkout-wrapper {
  margin-top: 20px;

  & .additional-checkout-buttons {
    margin-top: 12px;
  }
}

.drawer .additional-checkout-buttons {
  margin: 10px 0;

  & [data-shopify-buttoncontainer] {
    justify-content: center;

    & > * {
      height: auto !important;
    }
  }
}

/*PayPal button has unhelpful z-index*/
iframe.zoid-component-frame {
  z-index: 1 !important;
}

.drawer__cart-empty {
  display: none;
}

.drawer.is-empty {
  & .drawer__inner {
    display: none;
  }

  & .drawer__cart-empty {
    display: block;
  }
}

/* Cart properties */
.cart__item--properties {
  font-size: calc(var(--typeBaseSize) - 2px);
  margin-bottom: 10px;
  line-height: 1.3;

  & span {
    font-weight: 700;
  }
}
