.placeholder-svg {
  fill: #999;
  background-color: #e1e1e1;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: block;
  padding: 30px 0;
}

.placeholder-noblocks {
  padding: 40px;
  text-align: center;
}

/*Placeholder animation for loading product form*/
.placeholder-content {
  overflow: hidden;
  animation: placeholder-shimmer 1.3s linear infinite;
  background-size: 400% 100%;
  margin-bottom: 20px;
  border-radius: 4px;

  @media (--small) {
    margin-left: auto;
    margin-right: auto;
  }
}
