.feature-row__item--overlap-images {
  & .feature-row__first-image {
    width: 55%;
  }

  & .feature-row__second-image {
    width: 60%;
  }
}

.feature-row__item--placeholder-images {
  & .placeholder-image-wrap {
    width: 50%;
  }
}

.feature-row-wrapper .feature-row__images {
  width: 100%;
  min-width: 0;

  @media (--medium-down) {
    padding-top: 30px;
  }
}

.feature-row-wrapper .feature-row__text {
  width: 100%;
  min-width: 0;

  @media (--medium-up) {
    min-width: 43%;
    flex: 0 1 43%;
  }
}

.feature-row--33 {
  & .feature-row__images {
    max-width: 50%;

    @media (--medium-up) {
      max-width: none;
      flex: 0 1 33.333%;
    }
  }
}

.feature-row--50 {
  & .feature-row__images {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;

    @media (--medium-up) {
      max-width: none;
      flex: 0 1 50%;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.feature-row--66 {
  & .feature-row__images {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;

    @media (--medium-up) {
      max-width: none;
      flex: 0 1 66.666%;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

