.js-qty__wrapper {
  display: inline-block;
  position: relative;
  max-width: 80px;
  min-width: 60px;
  overflow: visible;
  background-color: $colorBody;
  color: $colorTextBody;

  &.is-loading {
    opacity: 0.5;
    pointer-events: none;
  }
}

.js-qty__num {
  display: block;
  background: none;
  text-align: center;
  width: 100%;
  padding: 5px 20px;
  margin: 0;
  z-index: 1;
}

.js-qty__adjust {
  cursor: pointer;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  border: 0 none;
  background: none;
  text-align: center;
  overflow: hidden;
  padding: 0 10px;
  line-height: 1;
  user-select: none;
  backface-visibility: hidden;
  transition: background-color 0.1s ease-out;
  z-index: 2;
  fill: $colorTextBody;

  & .icon {
    display: block;
    font-size: 8px;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    fill: inherit;
  }

  &:hover {
    background-color: $colorBodyDim;
    color: $colorTextBody;
  }

  @nest .js-qty__num:active ~ &,
  .js-qty__num:focus ~ & {
    border-color: $colorTextBody;
  }
}

.js-qty__adjust--plus {
  right: 0;
}

.js-qty__adjust--minus {
  left: 0;
}

.drawer {
  & .js-qty__wrapper {
    background-color: transparent;
    border-color: $colorDrawerBorder;
  }

  & .js-qty__num {
    color: $colorDrawerText;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  & .js-qty__adjust {
    color: $colorDrawerText;
    fill: $colorDrawerText;

    &:hover {
      background-color: $colorDrawerText;
      color: $colorDrawers;
      fill: $colorDrawers;
    }
  }
}
