.template-giftcard,
.template-giftcard body {
  background: $colorBody;

  & a {
    text-decoration: none;
  }
}

.template-giftcard .page-width {
  max-width: 588px;
}

.giftcard-header {
  padding: calc($gutter * 2) 0;
  font-size: 1em;
  text-align: center;

  & a {
    display: block;
    margin: 0 auto;
  }
}

.template-giftcard .shop-url {
  display: none;
}

.giftcard__border {
  padding: 1.5em;
  box-shadow: 0 10px 30px rgba(0,0,0,0.3);
}

.giftcard__content {
  @mixin clearfix;
  background-color: $colorBody;
  color: $colorTextBody;
}

.giftcard__header {
  @mixin clearfix;
  padding: calc($gutter / 2);
}

.giftcard__title {
  float: left;
  margin-bottom: 0;
}

.giftcard__tag {
  display: block;
  float: right;
  background-color: $colorTextBody;
  border: 1px solid transparent;
  color: $colorBody;
  padding: calc($gutter / 3);
  border-radius: 4px;
  font-size: 0.75em;
  text-transform: uppercase;
  letter-spacing: .05em;
  line-height: 1;
}

.giftcard__tag--active {
  background: transparent;
  color: $colorTextBody;
  border: 1px solid;
  border-color: $colorBorder;
}

.giftcard__wrap {
  position: relative;
  margin: calc($gutter / 2) calc($gutter / 2) $gutter;

  & img {
    position: relative;
    display: block;
    border-radius: 10px;
    z-index: 2;
  }
}

.giftcard__code {
  position: absolute;
  bottom: $gutter;
  text-align: center;
  width: 100%;
  z-index: 50;
}

.giftcard__code--medium {
  font-size: .875em;
}

.giftcard__code--small {
  font-size: .75em;
}

.giftcard__code__inner {
  display: inline-block;
  vertical-align: baseline;
  background-color: #fff;
  padding: .5em;
  border-radius: 4px;
  max-width: 450px;
  -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1);

  @nest .giftcard__code--small & {
    overflow: auto;
  }
}

.giftcard__code__text {
  font-weight: 400;
  font-size: 1.875em;
  text-transform: uppercase;
  border-radius: 2px;
  border: 1px dashed;
  border-color: $colorBorder;
  padding: .4em .5em;
  display: inline-block;
  vertical-align: baseline;
  color: $colorTextBody;
  line-height: 1;

  @nest .disabled & {
    color: #999;
    text-decoration: line-through;
  }
}

.giftcard__amount {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 2.75em;
  line-height: 1.2;
  padding: calc($gutter / 2);
  z-index: 50;

  & strong {
    display: block;
    text-shadow: 3px 3px 0 rgba(0,0,0,0.1);
  }
}

.giftcard__amount--medium {
  font-size: 2em;
}

.tooltip {
  display: block;
  position: absolute;
  top: -50%;
  right: 50%;
  margin-top: 16px;
  z-index: 3;
  color: #fff;
  text-align: center;
  white-space: nowrap;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 100%;
    bottom: 0;
    width: 0;
    height: 0;
    margin-left: -5px;
    margin-bottom: -5px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 5px solid #333;
    border-top: 5px solid rgba(51,51,51,0.9);
  }
}

.tooltip__label {
  display: block;
  position: relative;
  right: -50%;
  border: none;
  border-radius: 4px;
  background: #333;
  background: rgba(51,51,51,0.9);
  min-height: 14px;
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
  line-height: 16px;
  text-shadow: none;
  padding: .5em .75em;
  margin-left: .25em;

  & small {
    text-transform: uppercase;
    letter-spacing: .1em;
    color: #b3b3b3;
    font-size: .875em;
  }
}

.giftcard__instructions {
  text-align: center;
  margin: 0 calc($gutter / 2) $gutter;
}

.giftcard__actions {
  position: relative;
  text-align: center;
  overflow: hidden;
  padding-bottom: 1em;
}

.template-giftcard .action-link {
  position: absolute;
  left: calc($gutter / 2);
  top: 50%;
  font-size: 0.875em;
  font-weight: bold;
  display: block;
  padding-top: 4px;
  text-transform: uppercase;
  letter-spacing: .2em;
  margin-top: -10px;

  &:hover,
  &:focus {
    color: $colorTextBody;
  }
}

.template-giftcard .action-link__print {
  display: inline-block;
  vertical-align: baseline;
  width: 17px;
  height: 17px;
  vertical-align: middle;
  margin-right: 10px;
  opacity: 1;
  background-image: url('//cdn.shopify.com/s/assets/gift-card/icon-print-164daa1ae32d10d1f9b83ac21b6f2c70.png');
  background-repeat: no-repeat;
  background-position: 0 0;
}

.giftcard__footer {
  text-align: center;
  padding: calc($gutter * 2) 0;
}

#QrCode {
  & img {
    padding: $gutter;
    border: 1px solid;
    border-color: $colorBorder;
    border-radius: 4px;
    margin: 0 auto $gutter;
  }
}

@media (--small) {
  .giftcard {
    font-size: 12px;
  }

  .giftcard-header {
    padding: $gutter 0;
  }

  .header-logo {
    font-size: 2em;
  }

  .giftcard__border {
    padding: calc($gutter / 2);
  }

  .giftcard__actions {
    padding: calc($gutter / 2);
  }

  .giftcard__actions .btn {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .template-giftcard .action-link {
    display: none;
  }
}

/*================ Small width ================*/
@media screen and (max-width: 400px) {
  .giftcard__amount strong {
    text-shadow: 2px 2px 0 rgba(0,0,0,0.1);
  }

  .giftcard__wrap:before,
  .giftcard__wrap:after {
    display: none;
  }

  .giftcard__code {
    font-size: .75em;
  }

  .giftcard__code--medium {
    font-size: .65em;
  }

  .giftcard__code--small {
    font-size: .55em;
  }
}


/*================ Small height ================*/
@media screen and (max-height: 800px) {
  .header-logo img {
    max-height: 90px;
  }
}

@media print {
  @page {
    margin: 0.5cm;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  html, body {
    background-color: #fff;
  }

  .giftcard-header {
    padding: 10px 0;
  }

  .giftcard__content,
  .giftcard__border {
    border: 0 none;
  }

  .site-header__logo-link img:nth-child(2),
  .giftcard__actions,
  .giftcard__wrap:before,
  .giftcard__wrap:after,
  .tooltip,
  .add-to-apple-wallet {
    display: none;
  }

  .giftcard__title {
    float: none;
    text-align: center;
  }

  .giftcard__code__text {
    color: #555;
  }

  .template-giftcard .shop-url {
    display: block;
  }

  .template-giftcard .logo {
    color: #58686F;
  }
}
