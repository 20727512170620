.recipient-form {
  display: block;
  position: relative;
  margin: 2em auto;

  & .field {
    position: relative;
  }

  & .field__label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    color: inherit;
    padding: 8px 10px;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    margin: 0;

    @media (--medium-down) {
      padding: 10px;
    }
  }

  & .field__label:not(.variant__button-label):not(.text-label),
  & label:not(.variant__button-label):not(.text-label) {
    font-weight: normal;
  }

  & .field__input {
    margin-bottom: 1.5em;

    @media (--medium-down) {
      margin-bottom: 0.6em;
    }

    &.text-area {
      margin-bottom: 0;
      height: 150px;
    }

    &::-webkit-input-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      opacity: 0.65;
      transform: scale(0.85) translateY(-0.4em) translateX(0.15em);
      font-size: 0.8em;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding: 16px 10px 0;
    }
  }

  & .field__input--error {
    border-color: $errorRed;
    background-color: $errorRedBg;
    color: $errorRed;

    & ~ label {
      color: $errorRed;
    }
  }

  .recipient-form__checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2em;

    @media (--medium-down) {
      margin-bottom: 1.5em;
    }
  }

  & input[type="checkbox"] {
    height: 16px;
    width: 16px;
    background-color: inherit;
    border: 1px solid;
    border-color: var(--colorBorder);
    border-radius: $input-radius;
  }

  & .checkbox-label {
    display: block;
    cursor: pointer;
    margin-bottom: 0;
  }

  & .recipient-form-field-label--space-between > span {
    font-size: 0.8em;
  }

  & .recipient-fields {
    display: none;
  }
}
