.site-nav {
  margin: 0;
}

.site-navigation {
  @nest .text-center & {
    margin: 0 auto;
  }

  @nest .header-layout--left & {
    padding-left: calc($gutter / 3);
  }
}

.site-nav__icons {
  white-space: nowrap;
}

.site-nav__item {
  position: relative;
  display: inline-block;
  margin: 0;

  & li {
    display: block;
  }

  & .icon-chevron-down {
    width: $desktopMenuChevronSize;
    height: $desktopMenuChevronSize;
  }
}

.site-nav__link {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: calc($siteNavItemPadding / 2) $siteNavItemPadding;
  white-space: nowrap;
  color: $colorNavText;

  @nest .site-header--heading-style & {
    @mixin headingFontStack;
  }

  &:hover {
    color: $colorNavText;
  }

  @nest .is-light & {
    color: $colorStickyNavLinks;

    &:hover {
      color: $colorStickyNavLinks;
    }
  }

  @nest .site-nav--has-dropdown > & {
    position: relative;
    z-index: calc($zindexNavDropdowns + 1);
  }

  & .icon-chevron-down {
    margin-left: 5px;
  }

  @media (--medium-down) {
    padding: calc($siteNavItemPadding / 2);

    @nest .header-layout--center & {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

.site-nav__link--underline {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 100%;
    margin: 0 $siteNavItemPadding;
    border-bottom: 2px solid;
    border-bottom-color: $colorNavText;
    transition: right 0.5s;

    @nest [data-disable-animations="true"] & {
      transition: none;
    }
  }

  /*White underline when no dropdown*/
  @nest .is-light .site-nav__item:not(.site-nav--has-dropdown) &:after {
    border-bottom-color: #fff;
  }

  /*Dropdown text color underline when in dropdown link*/
  @nest .site-nav--has-dropdown &:after {
    border-bottom-color: $colorTextBody;
  }

  @nest .site-nav__item:hover &:after {
    right: 0;
  }
}

.site-nav__details {
  cursor: pointer;
}

/*Keep active state on parent dropdown link*/
.site-nav--has-dropdown {
  z-index: calc($zindexNavDropdowns + 1);

  &.is-focused,
  &:hover {
    z-index: calc($zindexNavDropdowns + 2);
  }
}

.site-nav--has-dropdown.is-focused > a,
.site-nav--has-dropdown:hover > a {
  color: $colorTextBody !important;
  background-color: $colorBody;
  opacity: 1;
  transition: none;
}

.site-nav__link--icon {
  padding-left: $siteNavIconPadding;
  padding-right: $siteNavIconPadding;

  @media (--small) {
    padding-left: calc($siteNavItemPadding / 2);
    padding-right: calc($siteNavItemPadding / 2);

    & + .site-nav__link--icon {
      margin-left: -4px; /*~amount of inline-block space*/
    }
  }

  & .icon {
    width: $desktopMenuIconSize;
    height: $desktopMenuIconSize;
  }
}

.site-nav__dropdown {
  position: absolute;
  left: 0;
  margin: 0;
  z-index: $zindexNavDropdowns;
  display: block;
  visibility: hidden;
  background-color: $colorBody;
  min-width: 100%;
  padding: calc($gutter / 3) 0 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
  transform: translate3d(0, -12px, 0);

  @nest details[open] > & {
    display: block;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 300ms cubic-bezier(0.2, 0.06, 0.05, 0.95);
  }

  & li {
    margin: 0;
  }

  & > li {
    position: relative;

    & > a {
      position: relative;
      z-index: calc($zindexNavDropdowns + 1);
    }
  }

  & a {
    background-color: $colorBody;
  }
}

.site-nav__deep-dropdown {
  background-color: $colorBody;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 0;
  left: 100%;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  z-index: $zindexNavDropdowns;
  transform: translate3d(-12px, 0, 0);

  @nest .site-nav__deep-dropdown-trigger:hover &,
  .is-focused + & {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all 300ms cubic-bezier(0.2, 0.06, 0.05, 0.95);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 10px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.09), transparent);
    pointer-events: none;
  }
}

.site-nav__dropdown-link--has-children {
  @nest .site-nav__deep-dropdown-trigger:hover & {
    background-color: $colorBodyDim;
  }

  &:hover,
  &:focus {
    background-color: $colorBodyDim;
  }
}

/*Rotate to face right*/
.site-nav__deep-dropdown-trigger .icon-chevron-down {
  position: absolute;
  top: 50%;
  right: 10px;
  width: $desktopMenuChevronSize;
  height: $desktopMenuChevronSize;
  transform: rotate(-90deg) translateX(50%);
}

.mobile-nav {
  margin: calc(var(--drawer-gutter) * -1) calc(var(--drawer-gutter) * -1) 0;

  & li {
    margin-bottom: 0;
    list-style: none;
  }
}

.mobile-nav__search {
  padding: calc($gutter / 2);
}

.mobile-nav__item {
  position: relative;
  display: block;

  /*Background color on top level items so there is no
  element overlap on subnav's CSS toggle animation*/
  @nest .mobile-nav > & {
    background-color: $colorDrawers;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: var(--drawer-gutter);
    right: var(--drawer-gutter);
    border-bottom: 1px solid;
    border-bottom-color: $colorDrawerBorder;
  }
}

/*Login/logout links can't have a class on them, so style <a> element*/
.mobile-nav__link,
.mobile-nav__faux-link {
  display: block;
}

.mobile-nav__link--top-level {
  font-size: 1.4em;

  @nest .mobile-nav--heading-style & {
    @mixin headingFontStack;
  }
}

.mobile-nav__link,
.mobile-nav__faux-link,
.mobile-nav__toggle button,
.mobile-nav__toggle .faux-button {
  color: $colorDrawerText;
  padding: calc($gutter / 2) var(--drawer-gutter);
  text-decoration: none;

  &:active {
    color: $colorDrawerTextDark;
  }

  &:active {
    background-color: $colorDrawersDim;
  }
}

.mobile-nav__child-item {
  display: flex;

  & a,
  & .mobile-nav__link {
    flex: 1 1 auto;
  }

  & .collapsible-trigger:not(.mobile-nav__link--button) {
    flex: 0 0 calc($collapsible-trigger-circle-width + calc($gutter / 2));
  }

  & .collapsible-trigger__icon {
    padding: 0;
    margin-right: calc($gutter / 2);
  }

  @nest [dir="rtl"] & {
    & .collapsible-trigger__icon {
      margin-right: 0;
      margin-left: calc($gutter / 2);
    }
  }
}

.mobile-nav__item--secondary {
  & a {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  &:after {
    display: none; /*no trailing border*/
  }
}

/*Spacing above secondary links*/
.mobile-nav__item:not(.mobile-nav__item--secondary) + .mobile-nav__item--secondary {
  margin-top: 10px;
}

.mobile-nav__has-sublist,
.mobile-nav__link--button {
  display: flex;

  & > * {
    flex: 1 1 auto;
    word-break: break-word; /*Breaks long problematic words*/
  }
}

.mobile-nav__link--button {
  width: 100%;
  text-align: left;
  padding: 0;
}

.mobile-nav__toggle {
  flex: 0 1 auto;

  & .icon {
    width: 16px;
    height: 16px;
  }

  & button,
  & .faux-button {
    height: 60%;
    padding: 0 30px;
    margin: 20% 0;
  }

  & button {
    border-left: 1px solid;
    border-left-color: $colorDrawerBorder;
  }

  @nest [dir="rtl"] & {
    & button {
      border-left: none;
      border-right: 1px solid;
      border-right-color: $colorDrawerBorder;
    }
  }
}

.mobile-nav__sublist {
  margin: 0;

  & .mobile-nav__item:after {
    top: 0;
    bottom: auto;
    border-bottom: none;
  }

  & .mobile-nav__item:last-child {
    padding-bottom: calc($gutter / 2);
  }

  & .mobile-nav__link,
  & .mobile-nav__faux-link {
    font-weight: normal;
    padding: calc($gutter / 4) 25px calc($gutter / 4) var(--drawer-gutter);
  }
}

.mobile-nav__grandchildlist {
  margin: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    background: #000;
    left: 17px;
    top: 10px;
    bottom: 10px;
  }

  @nest [dir="rtl"] & {
    &:before {
      left: auto;
      right: 17px;
    }
  }

  & .mobile-nav__item:last-child {
    padding-bottom: 0;
  }

  & .mobile-nav__link {
    padding-left: 35px;
  }
}

.mobile-nav__social {
  list-style: none outside;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  margin: 15px 0 20px 0;

  @media (--medium-up) {
    margin-left: calc(var(--drawer-gutter) * -1);
    margin-right: calc(var(--drawer-gutter) * -1);
  }

  & a {
    display: block;
    padding: 12px 30px;

    & .icon {
      position: relative;
      top: -1px;
    }
  }
}

.mobile-nav__social-item {
  flex: 0 1 33.33%;
  text-align: center;
  border: 1px solid;
  border-color: $colorDrawerBorder;
  margin: 0 0 -1px;

  &:nth-child(3n-1) {
    margin-right: -1px;
    margin-left: -1px;
  }
}

@media (--medium-up) {
  .site-nav__link--icon .icon {
    width: 28px;
    height: 28px;

    &.icon-user {
      position: relative;
      top: 1px;
    }
  }
}

.cart-link {
  position: relative;
  display: block;
}

.cart-link__bubble {
  display: none;
}

.cart-link__bubble--visible {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 15px;
  height: 15px;
  background-color: $colorCartDot;
  border: 2px solid $colorBody;
  border-radius: 50%;

  /*Slight position change with cart icon*/
  @nest [data-icon="cart"] & {
    top: 0;
    right: -4px;
  }

  @nest [data-icon="bag-minimal"] & {
    top: 50%;
    right: 0;
  }
}

.breadcrumb {
  @mixin headingTextCenter;
  @mixin baseSmallFontStack;
  margin: -25px 0 10px;

  @nest .template-product & {
    margin-top: 10px;

    @media (--medium-up) {
      text-align: left;
    }
  }

  @media (--small) {
    margin-bottom: calc($gutter / 2);
  }
}

.breadcrumb__divider {
  color: currentColor;
}
