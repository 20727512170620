.site-header__logo .logo--has-inverted {
  @nest .is-light & {
    display: none;
  }
}

.site-header__logo .logo--inverted {
  display: none;

  @nest .is-light & {
    display: block;
  }
}

.site-header__logo {
  @media (--medium-up) {
    text-align: left;
  }
}

.site-header__logo a,
.header-logo a {
  color: $colorNavText;

  @nest .is-light & {
    color: $colorStickyNavLinks;

    &:hover {
      color: $colorStickyNavLinks;
    }
  }
}

.site-header {
  @nest .is-light & {
    box-shadow: none;
  }
}

.site-header--password {
  color: var(--colorNavText);

  & a,
  & a:hover {
    color: var(--colorNavText);
  }

  @nest .is-light & {
    color: $colorStickyNavLinks;

    & a,
    & a:hover {
      color: $colorStickyNavLinks;
    }
  }
}

.site-nav__dropdown-link {
  display: block;
  padding: 8px 15px;
  white-space: nowrap;

  @nest .megamenu & {
    padding: 4px 0;
    white-space: normal;
  }
}
