.custom-content {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: auto;
  margin-bottom: -$grid-gutter;
  margin-left: -$grid-gutter;

  @media (--small) {
    margin-bottom: -$grid-gutter-small;
    margin-left: -$grid-gutter-small;
  }
}

.custom__item {
  flex: 0 0 auto;
  margin-bottom: $grid-gutter;
  padding-left: $grid-gutter;
  max-width: 100%;

  @media (--small) {
    flex: 0 0 auto;
    padding-left: $grid-gutter-small;
    margin-bottom: $grid-gutter-small;

    &.small--one-half {
      flex: 1 0 50%;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  & img {
    display: block;
  }
}

.custom__item-inner {
  position: relative;
  display: inline-block;
  text-align: left;
  max-width: 100%;
  width: 100%;
}

.custom__item-inner--video,
.custom__item-inner--html {
  display: block;
}

.custom__item-inner--image {
  width: 100%;
}

.custom__item-inner--html img {
  display: block;
  margin: 0 auto;
}

.custom__item-inner--placeholder-image {
  width: 100%;
}

.align--top-middle {
  text-align: center;
}

.align--top-right {
  text-align: right;
}

.align--middle-left {
  align-self: center;
}

.align--center {
  align-self: center;
  text-align: center;
}

.align--middle-right {
  align-self: center;
  text-align: right;
}

.align--bottom-left {
  align-self: flex-end;
}

.align--bottom-middle {
  align-self: flex-end;
  text-align: center;
}

.align--bottom-right {
  align-self: flex-end;
  text-align: right;
}
