.slideshow-wrapper {
  position: relative;
}

/*Pause button (focusable by keyboard only)*/
.slideshow__pause:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  color: $colorBtnPrimaryText;
  background-color: $colorBtnPrimary;
  padding: 10px;
  z-index: $zindexSkipToContent;
  transition: none;

  @nest .video-is-playing & {
    display: none;
  }
}

.slideshow__pause-stop {
  display: block;

  @nest .is-paused & {
    display: none;
  }
}

.slideshow__pause-play {
  display: none;

  @nest .is-paused & {
    display: block;
  }
}

.slideshow__slide {
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &:first-child {
    display: block;
  }

  @nest .flickity-slider & {
    display: block;
  }
}
