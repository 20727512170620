.megamenu {
  padding: calc($gutter * 1.3) 0;
  line-height: 1.8;
  transform: none;
  opacity: 0;
  transition: all 300ms cubic-bezier(0.2, 0.06, 0.05, 0.95);
  transition-delay: 0.3s;

  @nest details[open] > & {
    opacity: 1;
    transition-delay: 0s;

    & .appear-animation {
      opacity: 1;
      transform: none;
    }
  }
}

.site-nav--is-megamenu.site-nav__item {
  position: static;
}

.megamenu__collection-image {
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
  margin-bottom: calc($gutter / 1.5);
}

/*Center grid items in megamenu when logo centered*/
.text-center .megamenu .grid {
  text-align: center;

  & .grid__item {
    float: none;
    display: inline-block;
    vertical-align: top;
    text-align: left;
  }
}

.megamenu .site-nav__dropdown-link:not(.site-nav__dropdown-link--top-level) {
    font-size: calc(var(--typeBaseSize) - 1px);
    line-height: 1.5;
}

.megamenu h5,
.megamenu .h5 {
  margin-bottom: 5px;
  font-weight: bold;
}
