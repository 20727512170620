$z-index-hero-image: 1;
$z-index-hero-video: 2;
$z-index-hero-image-overlay: 3;
$z-index-hero-text: 4;
$slideshowImageAnimationAmount: 200px;
$slideshowImageAnimationSpeed: 0.5s;

.hero {
  position: relative;
  overflow: hidden;
  background: $colorLargeImageBg;
}

.hero__media-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.hero__image-wrapper,
.hero__media {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &:before {
    @mixin overlay $z-index-hero-image-overlay;
    @mixin heroScrim;

    @nest .video-interactable & {
      pointer-events: none;
    }
  }
}

.hero__image {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: $z-index-hero-image;
  object-fit: cover;
}

.hero__media {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  z-index: $z-index-hero-image;
}

.hero__image-wrapper--no-overlay:before {
  content: none;
}

.hero__media iframe,
.hero__media video {
  width: 100%;
  height: 100%;
  pointer-events: none;

  @nest .video-interactable & {
    pointer-events: auto;
  }

  @nest .video-parent-section.loading & {
    opacity: 0.01; /*sneaky way to avoid talking to an invisible YT iframe*/
  }

  @nest .video-parent-section.loaded & {
    opacity: 1;
    animation: zoom-fade 2.5s cubic-bezier(0.26, 0.54, 0.32, 1) 0s forwards;
    transition: none; /*fixes safari animation conflict*/
  }
}

/*MP4 sizing*/
.hero__media video {
  position: relative;
  object-fit: cover;
  font-family: "object-fit: cover";
}

/*YouTube iframes need more specific sizing*/
.hero__media iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  left: -100%;
  max-width: none;

  /*This min-width may need to change slightly depending on
  embedded video dimensions. Can do on a per-shop basis*/
  @media screen and (min-width: 1140px) {
    width: 100%;
    height: 300%;
    left: auto;
    top: -100%;
  }
}

/*Mobile only vimeo play button*/
.vimeo-mobile-trigger {
  display: block;
  position: absolute;
  width: 100%;
  z-index: 2;
  margin-top: 90px;

  @nest .hero__text-content & {
    bottom: 120%;
  }

  & .icon {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    padding: 10px;
  }
}

.hero__slide-link {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: calc($z-index-hero-text + 1);

  &:hover {
    & ~ .hero__text-wrap .btn:not(.btn--secondary):not(.btn--tertiary):not(.btn--inverse) {
      background: $colorBtnPrimaryLight;
      transition-delay: 0.25s;

      &:after {
        animation: shine 0.75s cubic-bezier(0.01, 0.56, 1, 1);
      }

      @nest [data-button_style='angled'] & {
        &:after {
          animation: none;
        }
      }
    }
  }
}

.hero__text-wrap {
  position: relative;
  height: 100%;
  color: $colorHeroText;

  @nest .video-interactable & {
    pointer-events: none;
  }

  & .page-width {
    display: table;
    width: 100%;
    height: 100%;
  }
}

.hero__text-content {
  position: relative;
  padding: calc($gutter / 2) 0;
  z-index: $z-index-hero-text;
  @media (--medium-up) {
    padding: calc($gutter * 1.5) 0;
  }

  @nest [data-dots="true"] & {
    padding-bottom: 40px;
  }
}

.hero__text-shadow {
  position: relative;
  display: inline-block;

  &:before {
    @mixin overlay;
    @mixin heroRadial;
    z-index: -1;
  }
}

.hero__top-subtitle {
  @mixin accentFontStack;
  font-size: 1.1em;
  margin-bottom: 5px;
}

.hero__title {
  display: block;
  margin-bottom: 0;
}

.hero__subtext {
  margin-top: 20px;
}

.hero__subtitle {
  font-size: 1.3em;
  display: block;
  vertical-align: middle;
  margin-top: 5px;
  margin-bottom: 5px;
}

.hero__link {
  position: relative; /*IE clickability fix*/
  display: block;

  @nest .video-interactable & {
    pointer-events: auto;
  }

  & .btn {
    margin: 4px 3px 2px 0;

    @media (--medium-up) {
      margin-right: 5px;
      margin-top: 0;
    }

    @nest [data-button_style='angled'] & {
      margin-left: $buttonSkew;
    }
  }

  & .btn + .btn {
    margin-left: 2px;

    @media (--medium-up) {
      margin-left: 10px;
    }

    @nest [data-button_style='angled'] & {
      margin-left: $buttonSkew;
    }
  }

  /*Play icon in button*/
  & .btn .icon-play {
    position: relative;
    top: -2px;
    margin-right: 5px;
  }

  /*Extra padding in case inside animation cropper*/
  @nest [data-button_style='angled'] & {
    & .animation-cropper {
      padding-right: 10px;
    }
  }
}

/*Text alignment*/
.hero__text-content {
  display: table-cell;

  & .hero__link {
    margin-top: calc($gutter / 4);

    @media (--medium-up) {
      margin-top: calc($gutter / 2);
    }
  }

  &.horizontal-left {
    text-align: left;
  }

  &.horizontal-center {
    text-align: center;
  }

  &.horizontal-right {
    text-align: right;
  }

  &.vertical-center {
    vertical-align: middle;
  }

  &.vertical-bottom {
    vertical-align: bottom;
  }
}

/*Top padding adjustment to account for overlaid header*/
.overlaid-header .index-section--hero:first-child .hero__text-content.vertical-center {
  padding-top: 50px;
  @media (--medium-up) {
    padding-top: 90px;
  }
}

.hero--450px {
  height: calc(450px * 0.65);
}
.hero--550px {
  height: calc(550px * 0.65);
}
.hero--650px {
  height: calc(650px * 0.65);
}
.hero--750px {
  height: calc(750px * 0.65);
}
.hero--100vh {
  height: 100vh;
}

@media (--medium-up) {
  .hero--natural[data-natural] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .hero--450px {
    height: 450px;
  }
  .hero--550px {
    height: 550px;
  }
  .hero--650px {
    height: 650px;
  }
  .hero--750px {
    height: 750px;
  }
}

@media (--small) {
  .hero--mobile--auto[data-mobile-natural="true"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
  }
  .hero--natural[data-mobile-natural="false"] {
    height: 500px; /*force it just in this case*/
  }
  .hero--mobile--250px[data-mobile-natural="false"] {
    height: 250px;
  }
  .hero--mobile--300px[data-mobile-natural="false"] {
    height: 300px;
  }
  .hero--mobile--400px[data-mobile-natural="false"] {
    height: 400px;
  }
  .hero--mobile--500px[data-mobile-natural="false"] {
    height: 500px;
  }
  .hero--mobile--100vh[data-mobile-natural="false"] {
    height: 90vh;
  }
}

/*Hero and slideshow shared starting animation*/
.slideshow__slide,
[data-aos="hero__animation"] {
  /*Default positioning pre-animation*/
  & .animation-cropper {
    opacity: 0;
  }

  & .animation-contents {
    opacity: 0;
    transform: translateY(15px);
    transition: none;
  }

  /*Override lazyload style*/
  &:not(.animate-out) .hero__image {
    opacity: 0;
    transition: none;
  }

  & .hero__link .btn {
    opacity: 0;
    transition: none;
  }

  /*Disable animations when not active or fading out*/
  & .hero__image-wrapper {
    transform: translateX($slideshowImageAnimationAmount);
    transition: none;
    opacity: 0;
  }
}

/*Hero and slideshow shared active animation */
.loaded .slideshow__slide.is-selected,
[data-aos="hero__animation"].loaded {
  & .animation-cropper {
    opacity: 1;
  }

  & .animation-contents {
    transform: translateY(0px);
  }

  & .hero__title .animation-contents {
    animation: 0.8s cubic-bezier(0.26, 0.54, 0.32, 1) 0.3s forwards;
    animation-name: rise-up;
  }

  & .hero__top-subtitle .animation-contents,
  & .hero__subtitle .animation-contents {
    animation: 1s cubic-bezier(0.26, 0.54, 0.32, 1) 0.7s forwards;
    animation-name: rise-up;
  }

  & .hero__link .animation-contents {
    opacity: 1;
  }

  & .hero__link .btn {
    animation: fade-in 2s ease 1.3s forwards;
  }

  & .hero__link .btn:nth-child(2) {
    animation: fade-in 2s ease 1.6s forwards;
  }

  & .hero__media,
  & .hero__image,
  & .hero__image--svg {
    opacity: 1;
    transition: none;
  }

  & .hero__image-wrapper {
    opacity: 1;
    transform: translateX(0px);
    transition: transform calc($slideshowImageAnimationSpeed + 0.2s) ease, opacity calc($slideshowImageAnimationSpeed + 0.2s) ease;
  }
}

/*When not in a slideshow, simple fade animation*/
[data-aos="hero__animation"].loaded {
  & .hero__image-wrapper {
    transition: opacity calc($slideshowImageAnimationSpeed + 0.2s) ease !important;
  }
}

.no-js {
  & .hero__image,
  & .hero__image-wrapper {
    opacity: 1 !important;
    transform: none;
  }
}

/*Slideshow fading out animation*/
.slideshow__slide.animate-out {
  & .animation-cropper {
    opacity: 0;
    transition: none;
  }

  & .hero__image-wrapper {
    opacity: 0;
    transform: translateX(-$slideshowImageAnimationAmount);
    transition: transform $slideshowImageAnimationSpeed ease-in 0.05s, opacity $slideshowImageAnimationSpeed ease-in 0.05s;
  }

  & .hero__link {
    opacity: 0;
    transition: none;
  }
}
