.site-header__search-container {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 200%;
  height: 100%;
  z-index: $z-index-header-search;
  overflow: hidden;

  &.is-active {
    display: block;
    overflow: visible;
    bottom: 0;
  }
}

.site-header__search {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $z-index-header-search;
  display: flex;
  transform: translate3d(0, -110%, 0);
  background-color: $colorBody;
  color: $colorTextBody;
  box-shadow: 0 15px 45px rgb(0, 0, 0, 0.1);

  & .page-width {
    flex: 1 1 100%;
    display: flex;
    align-items: stretch;

    @media (--small) {
      padding: 0;
    }
  }

  @nest .is-active & {
    transform: translate3d(0, 0, 0);
  }

  & .icon {
    width: 30px;
    height: 30px;
  }
}

.site-header__search-input {
  border: 0;
  width: 100px; /*flexbox overrides, but fixes browser issues*/
  flex: 1 1 auto;

  &:focus {
    border: 0;
    outline: 0; /*only active element on the page, so this is acceptable*/
  }
}

/*Submit and cancel buttons*/
.site-header__search-btn {
  padding: 0 calc($gutter / 2);
}

@media (--medium-up) {
  .site-header__search-btn--submit {
    padding: 0 calc($gutter / 2) 0 0;

    & .icon {
      position: relative;
      top: -1px;
      width: 28px;
      height: 28px;
    }
  }
}
