.text-with-icons__blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -30px;
  margin-right: -30px;

  @media (--small) {
    flex-direction: column;
    margin: 0;
  }
}

.has-1-per-row .text-with-icons__block {
  width: 100%;
}

.has-2-per-row .text-with-icons__block {
  width: 50%;
}

.has-3-per-row .text-with-icons__block {
  width: 33.333%;
}

.has-4-per-row .text-with-icons__block {
  width: 25%;
}

.has-5-per-row .text-with-icons__block {
  width: 20%;
}

.text-with-icons__blocks .text-with-icons__block {
  display: flex;
  flex-direction: column;
  flex: none;
  padding: 30px;

  @media (--small) {
    width: 100%;
    padding: 0 0 60px;
  }
}

.text-with-icons__block-icon {
  display: block;
  margin-bottom: 10px;

  & .icon {
    width: 70px;
    height: 70px;

    @media (--small) {
      width: 60px;
      height: 60px;
    }
  }
}

.text-with-icons__button {
  display: flex;
  justify-content: center;
}
