.age-verification-popup {
  top: 0;
  bottom: 0;

  &.age-verification-popup--image-false {
    background-color: var(--colorBody);

    &.modal:before {
      background-color: var(--colorBody);
      animation: none;
    }

    & .modal__inner {
      box-shadow: none;
    }
  }

  &.modal--mobile-friendly.modal--square .modal__centered-content {
    background-color: var(--colorDrawers);
  }
}
