@media (--medium-up) {
  .product-single__sticky {
    position: sticky;
    top: 20px;

    @nest .sticky-header & {
      top: 140px;
    }
  }

  .modal--quick-shop .product-single__sticky {
    top: 0;
  }
}

.page-content--product {
  padding-top: 55px;
}

@media (--small) {
  .page-content--product {
    padding-top: 0;
  }

  .grid--product-images-right {
    display: flex;
    flex-wrap: wrap;

    & .grid__item:first-child {
      order: 2;
    }
  }
}

/*Prevent quick view horizontal jank when image loads*/
.modal .page-content--product {
  width: $page-width;
  max-width: 100%;
}

.product-single__meta {
  padding-left: 45px;

  @media (--small) {
    text-align: center;
    padding-left: 0;
    margin-top: calc($gutter / 2);
  }

  @nest .grid--product-images-right & {
    padding-left: 0;
    padding-right: 45px;

    @media (--small) {
      padding-right: 0;
    }
  }

  & .social-sharing {
    margin-top: $gutter;
  }

  & .rte {
    text-align: left;

    @nest html[dir="rtl"] & {
      text-align: right;
    }
  }
}

.product-single__vendor {
  @mixin accentFontStack;
  @mixin accentFontSmallSize;
  margin-bottom: calc($gutter / 4);
}

.product-single__title {
  margin-bottom: 10px;
  word-wrap: break-word;
}

.product-single__sku {
  margin-bottom: calc($gutter / 6);
}

.product-single__description-full {
  margin: $gutter 0;

  & + .collapsibles-wrapper {
    margin: 0 0;
  }

  @media (--medium-up) {
    margin: $gutter 95px;

    & + .collapsibles-wrapper {
      margin: 0 95px;
    }
  }
}

.product-single__variants {
  display: none;

  @nest .no-js & {
    display: block;
    margin-bottom: $gutter;
  }
}

.product-image-main {
  position: relative;
}

[data-button_style="angled"] .product-image-main .btn:not(.product__photo-zoom) {
  display: inline-block;
  top: 50%;
  position: absolute;
}

.product__video-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 100%; /*apsect ratio overwritten inline*/
  height: auto;
  background-color: $colorSmallImageBg;

  & iframe {
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in;
  }

  /*Put overlay on muted videos because they cannot be interacted with*/
  &[data-video-style="muted"].loaded:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  /*Unless low power mode requires them to be touched to start*/
  &.video-interactable:before {
    display: none;
  }

  /*Make sure states don't overlap*/
  &.loaded:after {
    display: none;
  }

  &.loading iframe {
    opacity: 0.01; /*sneaky way to avoid talking to an invisible YT iframe*/
  }

  &.loaded iframe {
    opacity: 1;
  }
}

.product__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.product-video-trigger {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*Images*/
.product__photos--beside {
  display: flex;
  width: 100%;
}

.product__photos {
  direction: ltr;

  & a {
    display: block;
    max-width: 100%;
  }

  & img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
  }
}

.product__main-photos {
  position: relative;
  flex: 1 1 auto;

  & .flickity-page-dots {
    display: none;
  }

  @media (--small) {
    margin-bottom: 30px;
    margin-left: -$page-width-gutter-small;
    margin-right: -$page-width-gutter-small;

    & .flickity-page-dots {
      display: block;
    }
  }
}

/*Hide selectable elements when slide is not active*/
.product-main-slide:not(.is-selected) {
  & button,
  & video,
  & iframe,
  & model-viewer {
    display: none;
  }
}

.product-main-slide {
  display: none;
  width: 100%;
  overflow: hidden;

  /*Hide all but first slide to prevent page reflows prior to init*/
  &:first-child {
    display: block;
  }

  @nest .flickity-slider & {
    display: block;

    /*show a bit of the next image*/
    @media (--small) {
      @nest .grid--product-images--partial & {
        width: 75%;
        margin-right: 4px;
      }
    }
  }
}

.product__thumbs {
  position: relative;
}

.product__thumbs--below {
  margin-top: calc($grid-gutter-small / 2);

  @media (--medium-up) {
    margin-top: calc($gutter / 2);
  }
}

.product__thumbs--beside {
  flex: 0 0 60px;
  max-width: 60px;
  margin-left: calc($grid-gutter-small / 2);

  @media (--medium-up) {
    flex: 0 0 80px;
    max-width: 80px;
    margin-left: calc($gutter / 2);

    &.product__thumbs-placement--left {
      order: -1;
      margin-left: 0;
      margin-right: calc($gutter / 2);
    }
  }
}

.product__thumbs--scroller {
  scrollbar-width: none;
  scroll-behavior: smooth;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  @nest .product__thumbs--below & {
    overflow-x: scroll;
    white-space: nowrap;
  }

  @nest .product__thumbs--beside & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow-y: scroll;
  }
}

.product__thumb-item {
  border: 2px solid transparent;

  & a:focus,
  & a.is-active {
    outline: none;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 0 0 2px $colorTextBody;
      z-index: 1;
    }
  }

  /*Don't pre-focus thumbnails*/
  & a:active:before {
    content: none;
  }

  @nest .product__thumbs--beside & {
    margin-bottom: calc($grid-gutter-small / 2);

    @media (--medium-up) {
      margin-bottom: calc($gutter / 2);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @nest .product__thumbs--below & {
    display: inline-block;
    vertical-align: middle;
    margin-right: calc($grid-gutter-small / 2);
    max-width: 80px;

    @media (--medium-up) {
      margin-right: calc($gutter / 2);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.product__price {
  color: $colorPrice;
  margin-right: 5px;
  font-size: calc(var(--typeBaseSize) + 2px);

  &.on-sale {
    padding-right: 5px;
  }
}

/*Used anywhere unit price is visible*/
.product__unit-price {
  font-size: 0.8em;
  opacity: 0.8;
}

.product__unit-price--spacing {
  margin-top: 10px;
}

.product__price--compare {
  padding-right: 5px;
  display: inline-block;
  text-decoration: line-through;
}

.product__price-savings {
  color: $colorTextSavings;
  white-space: nowrap;
}

/*Quantity selector and label*/
.product__quantity {
  & label {
    display: block;
    margin-bottom: 10px;
  }
}

.product-form-holder--loaded {
  animation: fade-in 1s ease;
}

/*Hide Shopify Payment Buttons if no variant*/
.add-to-cart[disabled] + .shopify-payment-button {
  display: none;
}

/* When image sets are enabled, hide slides that aren't in the slider */
.product-slideshow.flickity-enabled {
  & .product-main-slide {
    display: none;
  }

  & .flickity-viewport .product-main-slide {
    display: block;
  }
}

/*Zoom button*/
.product__photo-zoom {
  position: absolute !important;
  bottom: 0;
  right: 0;
  cursor: zoom-in;

  /*Only show active slide's zoom button (mobile)*/
  @media (--small) {
    padding: 6px;

    @nest .product__main-photos & {
      margin-bottom: 10px;
      margin-right: 10px;
    }

    /*Hide zoom button on all slides*/
    @nest .product-slideshow & {
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }

    @nest .product-slideshow .is-selected & {
      opacity: 1;
    }
  }

  /*Visually hidden, covers full image on desktop*/
  @media (--medium-up) {
    opacity: 0;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    border-radius: 0;

    & svg,
    & span {
      display: none;
    }
  }
}

/*Thumb slider arrows*/
.product__thumb-arrow {
  position: absolute;
  background: $colorBody;
  color: $colorTextBody;
  transform: none;
  border-radius: 0;
  padding: 0;
  z-index: 2;

  & .icon {
    display: inline-block;
    width: 6px;
    height: 10px;
  }
}

.product__thumbs[data-position="below"] .product__thumb-arrow {
  top: 0;
  height: 100%;
  width: 25px;

  &.product__thumb-arrow--prev {
    left: 0;
    text-align: left;
  }

  &.product__thumb-arrow--next {
    right: 0;
    text-align: right;
  }
}

.product__thumbs[data-position="beside"] .product__thumb-arrow {
  width: 100%;

  & .icon {
    margin: 0 auto;
    transform: rotate(90deg);
  }

  &.product__thumb-arrow--prev {
    top: 0;
    left: auto;
    padding-bottom: 10px;
  }

  &.product__thumb-arrow--next {
    top: auto;
    bottom: 0;
    right: auto;
    padding-top: 10px;
  }
}

.product__thumb {
  position: relative;
  display: block;
  cursor: pointer;

  & > .image-wrap img {
    position: static;
    height: auto;
  }
}

.product__thumb-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: $colorTextBody;
  border-radius: 100px;
  padding: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease;
  font-size: 0;

  @nest .aos-animate & {
    opacity: 1;
  }

  & .icon {
    fill: $colorBody;
    width: 10px;
    height: 10px;

    @media (--medium-up) {
      width: 13px;
      height: 13px;
    }
  }
}

.product__policies {
  font-size: 0.85em;
}

.shopify-payment-terms {
  margin: 12px 0;

  &:empty {
    display: none;
  }

  @nest .modal & {
    display: none;
  }
}
