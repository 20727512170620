tool-tip {
  display: none;
}

tool-tip[data-tool-tip-open="true"] {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e0e0e0;
    background-color: var(--colorModalBg);
    animation: overlay-on 0.3s forwards;
    cursor: pointer;
  }
}

.tool-tip__inner {
  animation: modal-close 0.3s forwards;
  opacity: 0;

  @nest tool-tip[data-tool-tip-open="true"] & {
    animation: modal-open 0.3s forwards;
    display: block;
    position: fixed;
    background: var(--colorBody);
    box-shadow: 0 10px 20px #00000017;
    padding: 30px;
    max-width: 720px;
    min-width: 250px;
    min-height: 250px;

    @media (--small) {
      width: 100%;
      max-width: 93%;
    }
  }
}

.tool-tip__content {
  overflow: auto;
  max-height: 80vh;

  @media (--small) {
    font-size: 0.85em;
  }
}

.tool-tip__close {
  position: absolute;
  top: 0;
  right: 0;
}

.tool-tip__close .icon {
  width: 28px;
  height: 28px;
}
