.multi-selectors {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.multi-selectors__item {
  margin: 0 20px 10px;

  @media (--small) {
    margin: 10px 20px;
  }

  @nest .toolbar & {
    margin-right: 0;
    margin-bottom: 0;
  }
}
