form {
  margin: 0;
}

.form-vertical {
  @mixin baseTextCenter;
  margin-bottom: calc($gutter / 2);

  & label {
    text-align: left;
  }
}

.inline {
  display: inline;
}

@media (--medium-down) {
  input,
  textarea,
  select {
    font-size: 16px !important;
  }
}

button,
input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}

button {
  background: none;
  border: none;
  display: inline-block;
  cursor: pointer;
}

fieldset {
  border: 1px solid;
  border-color: $colorBorder;
  padding: calc($gutter / 2);
}

legend {
  border: 0;
  padding: 0;
}

button,
input[type="submit"] {
  cursor: pointer;
}

input,
textarea,
select {
  border: 1px solid;
  border-color: $colorBorder;
  max-width: 100%;
  padding: $formElementPadding;
  border-radius: $input-radius;

  &[disabled],
  &.disabled {
    cursor: default;
    background-color: $disabledGrey;
    border-color: $disabledBorder;
  }

  &.input-full {
    width: 100%;
  }
}

textarea {
  min-height: 100px;
}

input[type="checkbox"],
input[type="radio"] {
  margin: 0 10px 0 0;
  padding: 0;
  width: auto;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
}

input[type="radio"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
}

input[type="image"] {
  padding-left: 0;
  padding-right: 0;
}

select,
.faux-select {
  appearance: none;
  background-color: transparent;
  padding-right: 28px;
  text-indent: 0.01px;
  text-overflow: '';
  cursor: pointer;
  color: inherit;
}

select {
  background-position: right center;
  background-image: var(--urlIcoSelect);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 11px;

  @nest .is-light & {
    background-image: var(--urlIcoSelectWhite);
  }
}

.faux-select .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 11px;
}

optgroup {
  font-weight: bold;
}

/*Force option color (affects IE and some Firefox versions)*/
option {
  color: #000;
  background-color: #fff;

  &[disabled] {
    color: #ccc;
  }
}

select::-ms-expand {
  display: none;
}

.hidden-label {
  @mixin visuallyHidden;
}

label[for] {
  cursor: pointer;
}

.form-vertical {
  & input,
  & select,
  & textarea {
    display: block;
    margin-bottom: 30px;
  }

  & input[type="checkbox"],
  & input[type="radio"],
  & .btn {
    display: inline-block;
  }

  & .btn:not(:last-child) {
    margin-bottom: 30px;
  }
}

small {
  display: block;
}

input,
textarea {
  &.error {
    border-color: $errorRed;
    background-color: $errorRedBg;
    color: $errorRed;
  }
}

label.error {
  color: $errorRed;
}

.selector-wrapper {
  & label {
    margin-right: 10px;
  }

  & + .selector-wrapper {
    margin-top: calc($gutter / 2);
  }
}
