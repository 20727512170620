.js-drawer-open {
  overflow: hidden;
}

.drawer {
  display: none;
  position: fixed;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  top: 0;
  bottom: 0;
  max-width: 95%;
  z-index: $zindexDrawer;
  color: $colorDrawerText;
  background-color: $colorDrawers;
  box-shadow: 0 0 150px rgba(0,0,0,0.1);
  transition: transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);

  /* Allow whole drawer to be scrollable on short screens
     typically when Android keyboard is open*/
  @media screen and (max-height: $fixedHeightLimit) {
    overflow: scroll;

    & .drawer__contents {
      height: auto;
    }
  }

  & a:not(.btn) {
    color: $colorDrawerText;

    &:hover {
      color: $colorDrawerText;
    }
  }

  & input,
  & textarea {
    border-color: $colorDrawerBorder;
  }

  & .btn {
    background-color: $colorDrawerButton;
    color: $colorDrawerButtonText;
  }
}

.drawer--left {
  width: $drawerNavWidth;
  left: -$drawerNavWidth;

  &.drawer--is-open {
    display: block;
    transform: translateX($drawerNavWidth);
    transition-duration: 0.45s;
  }
}

.drawer--right {
  width: $drawerCartWidth;
  right: -$drawerCartWidth;

  @media (--medium-up) {
    width: $drawerCartWidthLarge;
    right: -$drawerCartWidthLarge;
  }

  &.drawer--is-open {
    display: block;
    transform: translateX(-$drawerCartWidth);
    transition-duration: 0.45s;

    @media (--medium-up) {
      transform: translateX(-$drawerCartWidthLarge);
    }
  }
}

.js-drawer-open .main-content:after,
.js-drawer-closing .main-content:after {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $colorModalBg;
  opacity: 0;
  z-index: $zindexDrawerOverlay;
}

.js-drawer-open .main-content:after {
  animation: overlay-on 0.35s forwards;
}

.js-drawer-closing .main-content:after {
  animation: overlay-off 0.25s forwards;
}

.drawer__header,
.drawer__fixed-header,
.drawer__scrollable,
.drawer__footer {
  padding-left: var(--drawer-gutter);
  padding-right: var(--drawer-gutter);
}

.drawer__header {
  display: table;
  min-height: $drawerHeaderHeight;
  width: 100%;
  padding: calc($gutter / 2.6) 0;
  margin-bottom: 0;
  border-bottom: 1px solid;
  border-bottom-color: $colorDrawerBorder;
}

.drawer__fixed-header {
  min-height: $drawerHeaderHeight;
  overflow: visible; /*for close button hit area*/
}

.drawer__header,
.drawer__fixed-header {
  height: auto;
}

@media (--medium-up) {
  .drawer__header,
  .drawer__fixed-header {
    min-height: $drawerHeaderHeightLarge;
  }
}

.drawer__title,
.drawer__close {
  display: table-cell;
  vertical-align: middle;
}

.drawer__title {
  width: 100%;
}

.drawer__close {
  width: 1%;
  text-align: center;
}

/*Button sits on right by default*/
.drawer__close-button {
  position: relative;
  height: 100%;
  right: calc(var(--drawer-gutter) * -1);
  padding: 0 var(--drawer-gutter);
  color: inherit;

  &:active {
    background-color: $colorDrawersDim;
  }

  & .icon {
    height: 28px;
    width: 28px;
  }
}

/*Rare use case left close button*/
.drawer__close--left {
  text-align: left;

  & .drawer__close-button {
    right: auto;
    left: calc(var(--drawer-gutter) * -1);
  }
}

.drawer__contents {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.drawer__inner,
.drawer__scrollable {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.drawer__scrollable {
  padding-top: var(--drawer-gutter);
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.drawer__footer {
  border-top: 1px solid;
  border-top-color: $colorDrawerBorder;
  padding-top: calc($gutter / 2);
  padding-bottom: var(--drawer-gutter);

  @media (--medium-up) {
    padding-top: calc($gutter / 1.35);
  }
}

.drawer__inner.is-loading {
  & .drawer__scrollable {
    transition: opacity 0.3s ease 0.7s;
    opacity: 0.4;
  }
}

.cart-notes {
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 60px;
  height: 60px;

  @media (--medium-up) {
    min-height: 80px;
    height: 80px;
  }
}
