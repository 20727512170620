.shopify-challenge__container {
  padding: 30px 22px;
  @media (--medium-up) {
    padding: 120px 0;
  }
}

.newsletter {
  margin: 0 auto;
  max-width: 520px;
}

.newsletter-section .errors {
  margin-left: auto;
  margin-right: auto;
  max-width: 520px;
}

.modal .newsletter .h1 {
  margin-bottom: calc($gutter / 2);
  @media (--medium-up) {
    margin-bottom: $gutter;
  }
}

.modal .newsletter .image-wrap {
  margin-bottom: calc($gutter / 4);
  @media (--medium-up) {
    margin-bottom: calc($gutter / 2);
  }
}

.modal .newsletter .text-close {
  text-decoration: underline;
}

.newsletter__input-group {
  margin: 0 auto 20px;
  max-width: 400px;

  &:last-child {
    margin-bottom: 0;
  }
}

.newsletter__input {
  &::-webkit-input-placeholder {
    color: $colorTextBody;
    opacity: 1;
  }

  &:-moz-placeholder {
    color: $colorTextBody;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $colorTextBody;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $colorTextBody;
  }

  &::-ms-input-placeholder {
    color: $colorTextBody;
    opacity: 1;
  }
}

@media (--small) {
  .form__submit--large {
    display: none;
  }

  .form__submit--small {
    display: block;
  }
}

@media (--medium-up) {
  .form__submit--large {
    display: block;
  }

  .form__submit--small {
    display: none;
  }
}

[data-has-image] .modal__close {
  background-color: var(--colorBody);
  border-radius: 50%;
  padding: 5px;
  top: 10px;
  right:10px;

  @media (--small) {
    background-color: transparent;
    top: 0;
    right: 0;
  }
}

[data-section-type="newsletter-popup"] {
  @media (--small) {
    bottom: 20px;
    text-align: center;

    & .modal__inner {
      max-width: calc(100% - 40px);
    }

    &.modal--square .modal__close {
      padding: 5px;
    }
  }

  & .h2 {
    margin-bottom: 0;

    @media (--medium-up) {
      margin-bottom: 0;
    }
  }

  & .rte {
    margin-top: 20px;
    margin-bottom: 0;

    @media (--medium-up) {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  & .popup-cta {
    margin-bottom: 0;
  }

  & .note--success {
    margin-top: 15px;
    text-align: center;
  }

  & .newsletter__input-group {
    margin-top: 30px;
    margin-bottom: 0;

    @media (--small) {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  & .newsletter-button {
    margin-top: 30px;
  }

  & .social-icons a {
    display: inline-block;
    margin-top: 30px;
    padding: 5px;

  }
}

.modal__centered-content.newsletter--has-image {
  padding: 0;

  @media (--small) {
    padding: 0;
  }

  & .newsletter-popup {
    display: flex;
    max-width: 800px;
    min-width: 650px;

    @media (--small) {
      max-width: none;
      min-width: 0;
    }

    &.newsletter-popup--image-reversed {
      flex-direction: row-reverse;
    }
  }

  & .form__submit--large {
    display: none;
  }

  & .form__submit--small {
    display: block;
  }

  & .newsletter-popup__image-wrapper {
    position: relative;
    width: 50%;

    @media (--small) {
      display: none;
    }
  }

  & .newsletter-popup__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  & .newsletter-popup__content {
    padding: 40px;
    width: 50%;

    @media (--small) {
      padding: 0 0 20px;
      width: 100%;
    }
  }
}

newsletter-reminder {
  color: var(--colorBtnPrimaryText);
  background-color: var(--colorBtnPrimary);

  &.modal--square .modal__close {
    color: var(--colorBtnPrimaryText);

    &:hover {
      color: var(--colorBtnPrimaryText);
      opacity: 50%;
    }
  }

  & .newsletter-reminder__message {
    font-size: calc(var(--typeHeaderSize)*0.5);
  }
}

.form__submit--small {
  line-height: 0;
}
