.tool-tip-trigger {
  background: none;
  border: 0;
  cursor: pointer;
}

.tool-tip-trigger .icon {
  width: 28px;
  height: 28px;
}

.tool-tip-trigger__title {
  display: inline-block;
}

.tool-tip-trigger__title:hover,
.tool-tip-trigger__title:focus {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.tool-tip-trigger__content {
  display: none !important; /* Ensure our content is always hidden */
}

/* Size chart trigger */

.size-chart__standalone {
  margin: var(--sizeChartMargin);

  & svg {
    margin-left: var(--sizeChartIconMargin);
  }
}
