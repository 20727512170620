.index-section {
  margin: $indexSectionMarginSmall 0;
}

.index-section--small {
  margin: calc($gutter * 0.6) 0;
}

.index-section + .index-section,
.index-section + .index-section--hidden,
.main-content + .index-section {
  margin-top: 0;
}

.index-section--flush {
  margin: 0;
}

.section--divider {
  border-top: 1px solid;
  border-top-color: $colorBorder;
  padding-top: $indexSectionMarginSmall;
}

.index-section--faq {
  margin-bottom: $indexSectionMarginSmall;
}

.index-section--faq {
  margin-bottom: $indexSectionMarginSmall;
}

.newsletter-section {
  padding: $indexSectionMarginSmall 0;
}

.newsletter-section--with-divider {
  border-top: 1px solid;
  border-top-color: $colorBorder;
}

/*Hide footer sections on /challenge page*/
.template-challange .index-section--footer {
  display: none;
}

.testimonials-section {
  padding: calc($gutter * 0.85) 0;
}

.testimonials-section--with-divider {
  border-top: 1px solid;
  border-top-color: $colorBorder;
}

/*Large screen section spacing*/
@media (--medium-up) {
  .index-section {
    margin: $indexSectionMarginLarge 0;
  }

  .index-section--small {
    margin: $gutter 0;
  }

  .section--divider {
    padding-top: $indexSectionMarginLarge;
  }

  .index-section--faq {
    margin: $indexSectionMarginLarge 0 calc($indexSectionMarginLarge / 2);
  }

  .newsletter-section {
    padding: $indexSectionMarginLarge 0;
  }

  .testimonials-section {
    padding: calc($gutter * 2.5) 0;
  }
}

.page-blocks--flush .page-width {
  padding: 0;
}

.page-blocks > div:first-child {
  & .index-section {
    margin-top: 0;
  }
}

/*================ Featured row section ================*/
.feature-row-wrapper {
  direction: ltr;
  overflow: hidden;
}

.feature-row {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (--widescreen) {
    margin: 0 6%;
  }

  @media (--small) {
    flex-direction: column;
    margin: 0;
  }
}

@media (--medium-down) {
  .feature-row--small-none {
    display: block;
  }
}

.feature-row__item {
  flex: 0 1 57%;
  margin: 0 auto;

  @media (--small) {
    flex: 1 1 auto;
    max-width: 100%;
    min-width: 100%;
  }
}

.feature-row__item--overlap-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 15px;
  margin: 0 0 0 -30px;

  @media (--medium-up) {
    padding: 50px 0;
    margin: 0 auto;
  }

  & > * {
    width: 50%;

    &:first-child {
      z-index: 1;
      transform: translate(30px, 30px);

      @media (--medium-up) {
        transform: translate(50px, 50px);
      }
    }
  }

  & svg {
    border: 2px solid;
    border-color: $colorBody;
  }
}

.feature-row__image {
  display: block;
  margin: 0 auto;
  order: 1;

  @media (--medium-up) {
    order: 2;
  }
}

.feature-row__text {
  min-width: 43%;
  flex: 0 1 43%;
  padding: 0;

  @media (--small) {
    order: 2;
    margin-top: 0;
    padding: 30px 20px 0;
    padding-bottom: 0; /*always last element on mobile*/
  }

  & .rte {
    margin: 0;
  }

  & .btn {
    margin-top: calc($gutter / 2);
  }
}

@media (--medium-up) {
  .feature-row__text--left {
    padding-left: calc($gutter * 2);
  }

  .feature-row__text--right {
    padding-right: calc($gutter * 2);
  }
}
