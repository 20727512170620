tool-tip[data-tool-tip-open="true"] .tool-tip__inner {
  padding: 45px;

  @media (--small) {
    padding: 45px 20px 20px;
  }
}

.tool-tip__close {
  padding: 10px;
}
