.price-range__slider-wrapper {
  padding: 0 8px 8px;

  @media (--small) {
    padding: 0 10px 8px;
  }
}

.price-range__input {
  display: none;
}

.price-range__display-wrapper {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  padding: 0;
  padding-bottom: 8px;
  width: 100%;

  @media (--small) {
    padding: 0;
  }
}

.noUi-horizontal .noUi-handle {
  border: 0;
  border-radius: 50%;
  background: var(--colorTextBody);
  box-shadow: 0 0 1px 2px rgb(255 255 255);
  width: 12px;
  height: 12px;
  cursor: pointer;
  right: -6px;
  top: -3px;

  &:hover, &:focus {
    width: 14px;
    height: 14px;
    right: -7px;
    top: -4px;
  }

  &:before, &:after {
    content: none;
  }
}

.noUi-target {
  background: #f4f4f4;
  border: 0;
  box-shadow: none;
}

.noUi-connect {
  background: var(--colorTextBody);
}

.noUi-horizontal {
  height: 6px;
}
