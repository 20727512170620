.parallax-image img {
  width:  100%;
  height: 100%;
  object-fit: cover;
}

.parallax-image {
  position: absolute;
  width:  100%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.05s linear;
}
