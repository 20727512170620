.grid {
  @mixin clearfix;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -$grid-gutter;

  @media (--small) {
    margin-left: -$grid-gutter-small;

    @nest html[dir="rtl"] & {
      margin-left: 0;
      margin-right: -$grid-gutter-small;
    }
  }

  @nest html[dir="rtl"] & {
    margin-left: 0;
    margin-right: -$grid-gutter;
  }
}

.grid--small {
  margin-left: -10px;

  & .grid__item {
    padding-left: 10px;
  }
}

.grid__item {
  float: left;
  padding-left: $grid-gutter;
  width: 100%;
  min-height: 1px;

  @media (--small) {
    padding-left: $grid-gutter-small;

    @nest html[dir="rtl"] & {
      padding-left: 0;
      padding-right: $grid-gutter-small;
    }
  }

  @nest html[dir="rtl"] & {
    float: right;
    padding-left: 0;
    padding-right: $grid-gutter;
  }
}

.grid--no-gutters {
  margin-left: 0;

  & .grid__item {
    padding-left: 0;
  }
}

.grid--flush-bottom {
  margin-bottom: -$grid-gutter;
  overflow: auto;

  & > .grid__item {
    margin-bottom: $grid-gutter;
  }
}

.grid--center {
  text-align: center;

  & .grid__item {
    float: none;
    display: inline-block;
    vertical-align: top;
    text-align: left;

    @nest html[dir="rtl"] & {
      text-align: right;
    }
  }
}

/*============================================================================
  Gutterless grids have all the properties of regular grids, minus any spacing.
==============================================================================*/
.grid--full {
  margin-left: 0;

  & > .grid__item {
    padding-left: 0;
  }
}

@media (--medium-up) {
  .grid--table-large {
    display: table;
    width: 100%;
    table-layout: fixed;

    & > .grid__item {
      display: table-cell;
      vertical-align: middle;
      float: none;
    }
  }
}

@media (--small) {
  .small--grid--flush {
    margin-left: -2px;

    @nest .page-width & {
      margin-left: -$page-width-gutter-small;
      margin-right: calc(-$page-width-gutter-small + 2px);
    }

    & > .grid__item {
      padding-left: 2px;
    }
  }
}
