*, input, :before, :after {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
