.pagination {
  margin: 0;
  padding: calc($gutter * 2) 0;
  text-align: center;

  & > span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }

  & a {
    display: inline-block;
  }

  & a,
  & .page.current {
    padding: 8px 12px;
  }

  & .page.current {
    opacity: 0.3;
  }

  & .next,
  & .prev {
    color: $colorBtnPrimaryText;
    background: $colorBtnPrimary;
    width: 43px;
    margin: 0 10px;

    @media (--small) {
      width: 35px;
    }

    & a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
    }

    & .icon {
      color: $colorBtnPrimaryText;
      width: 13px;
      height: 14px;

      @media (--small) {
        width: 12px;
        height: 12px;
      }
    }
  }
}
