.rte {
  @mixin clearfix;
  margin-bottom: calc($gutter / 4);

  @media (--medium-up) {
    margin-bottom: calc($gutter / 2);
  }

  &:last-child {
    margin-bottom: 0;
  }

  & + .rte {
    margin-top: $gutter;
  }

  & p,
  & ul,
  & ol,
  & table {
    margin-bottom: 15px;

    @media (--medium-up) {
      margin-bottom: 25px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ul {
    & ul {
      margin-bottom: 0;
    }
  }

  /*Add some top margin to headers from the rich text editor*/

  & h1, & h2, & h3, & h4, & h5, & h6 {
    margin-top: 60px;
    margin-bottom: 25px;
  }

  & h1, & h2, & h3, & h4, & h5, & h6 {
    &:first-child {
      margin-top: 0;
    }

    & a {
      text-decoration: none;
    }
  }

  /*In case merchants paste meta tags into their content by accident*/

  & meta:first-child {
    & + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
      margin-top: 0;
    }
  }

  & > div {
    margin-bottom: calc($gutter / 2);

    &:last-child {
      margin-bottom: 0;
    }
  }

  & li {
    margin-bottom: 0;
  }

  & table {
    table-layout: fixed;
  }
}

.rte--block {
  margin-bottom: 8px;
  @media (--medium-up) {
    margin-bottom: 12px;
  }
}

.rte-setting {
  & > p:last-child {
    margin-bottom: 0;
  }
}

.rte,
.rte-setting {
  & a {
    text-decoration: none;
  }

  & img {
    height: auto;
  }

  & a:not(.rte__image):not(.btn) {
    text-decoration: none;
    border-bottom: 1px solid;
    border-bottom-color: var(--colorTextBodyAlpha015);
    position: relative;
    display: inline-block; /*so underline hover effect works*/
  }

  & a:not(.btn):after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    border-bottom: 2px solid currentColor;
    transition: width 0.5s ease;
  }

  & a:not(.btn) {
    &:hover:after,
    &:focus:after {
      width: 100%;
    }
  }

  & a.rte__image:after {
    content: none;
  }
}

.text-center .rte,
.text-center.rte {
  & ul,
  & ol {
    list-style-position: inside;
    margin-left: 0;
  }
}

.rte--nomargin {
  margin-bottom: 0;
}
