.image-row {
  @mixin clearfix;
}

.image-row__placeholder {
  float: left;
  width: 33.33%;

  @nest .image-row--gutters & {
    width: 32%;
    margin: 0 1% 2%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.image-row__image {
  position: relative;
  min-height: 1px;
  float: left;

  &:after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
  }

  & img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

/*Zoom cursor unless inside a link*/
[data-zoom="true"] {
  & .image-row__image .js-photoswipe__zoom {
    cursor: zoom-in;
  }

  & .image-row__image a .js-photoswipe__zoom {
    cursor: pointer;
  }
}
