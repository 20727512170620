.predictive-overflow-hidden {
  overflow: hidden;

  @media (--small) {
    overflow: auto;
  }
}

predictive-search {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;

  &.is-active {
    flex: 99;
  }

  &.is-active .predictive__screen {
    display: block;
    z-index: 3;
  }

  &.is-active .btn--close-search {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

[data-dark="true"] {
  & input {
    color: #fff !important;
  }

  & .btn--search path {
    stroke: #fff !important;
  }
}

[data-dark="false"] {
  & input {
    color: #000 !important;
  }

  & .btn--search path {
    stroke: #000 !important;
  }
}

.predictive__screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--colorModalBg);
  display: none;
}

predictive-search form {
  display: flex;
  flex-wrap: wrap;
  z-index: 4;
  position: relative;
  width: 100%;
}

.search__input-wrap {
  position: relative;
  flex: 5;
  transition: flex 0.3s ease-in-out;
}

.search__input {
  background: var(--colorNavSearch, #fff);
  color: var(--color-body-text);
  flex: 1;
  width: 100%;
  border-radius: calc(var(--roundness, 0) *2);
  padding-left: 16px;
  padding-right: 16px;
}

.btn--search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;

  & .icon {
    height: 25px;
    width: 25px;
  }

  @media (--medium-up) {
    right: 10px;
  }

  @nest [dir="rtl"] & {
    right: auto;
    left: 15px;

    @media (--medium-up) {
      left: 10px;
    }
  }
}

.btn--close-search {
  display: none;
  flex: 30px 0 0;
  margin-left: 10px;

  & .icon {
    height: 25px;
    width: 25px;
  }

  & .icon path {
    stroke: var(--colorNavText) !important;
  }
}

.search__results {
  background: var(--color-body);
  color: var(--color-body-text);
  position: absolute;
  width: 100%;
  top: 100%;
  padding: 0;
  display: none;
  margin-top: 10px;
  container-type: inline-size;
  max-height: 80vh;
  overflow-y: auto;

  @media (--small) {
    width: calc(100% + var(--pageWidthPadding, 17px) * 2);
    margin-left: calc(var(--pageWidthPadding, 17px) * -1);
    margin-right: calc(var(--pageWidthPadding, 17px) * -1);
    max-height: 75vh;

    @nest .template-search .page-content & {
      max-height: 50vh;
    }
  }
}

.results {
  padding: 0 20px 20px;
  display: block;
  width: 100%;

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & li {
    transition: background 0.2s ease;
    padding: 10px;
    margin-bottom: 0;

    &:hover {
      background: rgba(0,0,0,0.05);
    }

    & a {
      display: flex;
      align-items: center;
    }
  }
}

.results--queries {
  & span {
    font-weight: bolder;
  }

  & mark {
    background-color: transparent;
    font-weight: normal;
  }
}

.results--products span {
  margin-left: 10px;
}

.results--products #predictive-search-products {
  margin-bottom: 20px;
}

.results-products__image {
  width: 100%;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
}

.results-products__info {
  display: flex;
  flex-direction: column;
}

.predictive-search-results {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow-y: auto;
  padding: 40px 0 0;

  & h3 {
    padding-bottom: 10px;
    border-bottom: 1px solid;
    border-color: var(--colorBorder);
    margin-bottom: 10px;
  }
}

.predictive-search-results--none {
  padding-top: 20px;
}

.predictive-search__no-results {
  display: block;
  width: 100%;

  &:hover {
    opacity: 0.5;
  }
}

.results__group-1 {
  flex: 100% 0 0;

  & > div:not(.results--queries) {
    display: none;
  }
}

.results__group-2 {
  flex: 100% 0 0;
}

.results__search-btn {
  width: 100%;
  padding: 10px;
  transition: background-color 0.2s ease;
  border: 1px solid;
  border-color: var(--colorBorder);

  &:hover {
    background-color: rgba(0,0,0,0.05);
  }
}

/* Containers
============================== */

@container (min-width: 800px) {
  .predictive-search-results {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .results {
    padding: 0 40px 20px;
  }

  .results__group-1 {
    flex: 1 0 0;

    & div:not(.results--queries) {
      display: block;
    }
  }

  .results__group-2 {
    flex: 2 0 0;

    & > div:not(.results--products) {
      display: none;
    }
  }

  .results__search-btn {
    text-align: left;
    padding: 10px 0 10px 40px;
  }
}

/*
.workaround {
  border-bottom: 1px solid var(--colorBorder);
}
*/
