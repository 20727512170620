.search-bar {
  max-width: 100%;
}

.search-bar--page {
  max-width: 300px;
  margin-top: calc(-$gutter / 2);

  @nest [data-type_headers_align_text="true"] & {
    margin: calc(-$gutter / 2) auto 0;
  }
}

.search-bar--drawer {
  margin-bottom: calc($gutter / 2);
  padding-bottom: calc($gutter / 2);
  border-bottom: 1px solid;
  border-bottom-color: $colorDrawerBorder;

  & input {
    border: 0;
  }
}

.search-bar .icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
