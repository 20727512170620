@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes preloading {
  0% {   transform-origin: 0% 50%; transform: scale3d(0, 1, 1); opacity: 0; }
  40% {  transform-origin: 0% 50%; transform: scale3d(1, 1, 1); opacity: 1; }
  41% {  transform-origin: 100% 50%; transform: scale3d(1, 1, 1); opacity: 1; }
  100% { transform-origin: 100% 50%; transform: scale3d(0, 1, 1); opacity: 1; }
}

@keyframes slideshowBars {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

/*Ajax load products*/
@keyframes grid-product__loading {
  0% { opacity: 1; }
  60% { opacity: 0; }
  100% { opacity: 1; }
}

/*Button animations*/
@keyframes shine {
  100% {
    left: -200%;
  }
}

/*Overlay animations (modals, panels)*/
@keyframes overlay-on {
  from { opacity: 0; }
  to { opacity: 0.6; }
}

@keyframes overlay-off {
  from { opacity: 0.6; }
  to { opacity: 0; }
}

@keyframes full-overlay-on {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes full-overlay-off {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes modal-open {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modal-closing {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

/*Text animations*/
@keyframes rise-up {
  from {
    opacity: 1;
    transform: translateY(120%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes rise-up-out {
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 1;
    transform: translateY(-120%);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/*Background image animations*/
@keyframes zoom-fade {
  from {
    opacity: 0;
    transform: scale(1.3,1.3);
  }
  to {
    opacity: 1;
    transform: scale(1,1);
  }
}

/*Product form placeholder*/
@keyframes placeholder-shimmer {
  0% {
    background-position: -150% 0;
  }
  100% {
    background-position: 150% 0;
  }
}
